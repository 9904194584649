import { Image, Text } from "@mantine/core"
import { FormattedMessage, useIntl } from "react-intl"
import "./styles.scss"

interface UelGeneralTipProps {
  message: string
  actionOnClick?: () => void
}

const UelGeneralTip = (props: UelGeneralTipProps) => {
  const { message, actionOnClick } = props;
  const intl = useIntl()

  return (
    <div className="credit-warning-container" onClick={actionOnClick}>
      <Image
        src="/icons/informationIcon.svg"
        width={13}
        height={13}
        className="information-icon"
        alt={intl.formatMessage({ id: "alt.warning.home" })}
      />
      <Text className="credit-warning-text">
        <FormattedMessage 
          id={`${message}`} 
          values={{
            b: chunks => <b><u>{chunks}</u></b>
          }}
        />
      </Text>
    </div>
  )
}

export default UelGeneralTip;
