import UelLoader from "components/Shared/UelLoader/UelLoader"
import "./style.scss"

const LoadingScreen = () => {
  return (
    <section className="screen-loading">
      <UelLoader size="lg" />
    </section>
  )
}

export default LoadingScreen
