import { ExpensesIconsI, GpsTransactionI } from "models/mygps.models"
import { FormattedMessage } from "react-intl"
// import { ExpensesCategoriesIcons } from "utils/helpers/mygps.helpers"
import GpsTransaction from "../GpsTransaction/GpsTransaction"
import "./style.scss"
export interface GpsTransactionsListProps {
  accountLabel: string
  transactionsList: GpsTransactionI[]
  activeIcon:ExpensesIconsI
}

const GpsTransactionsList = ({
  accountLabel,
  transactionsList,
  activeIcon
}:GpsTransactionsListProps) => {

  return  <div className="component-gps-transactions-list">
            <h1>
              <FormattedMessage id="screens.mygps.transactions.title" />
            </h1>
            {
              transactionsList?.map(
                (transaction, index) => 
                  transaction.isCharge ?
                  <GpsTransaction
                    // key={transaction.id}
                    key={index + 1}
                    iconSrc={activeIcon}
                    iconBgColor={transaction?.expense_category.color!}
                    amount={transaction?.amount!}
                    transactionDate={transaction?.date!}
                      account={accountLabel!}
                      description={transaction?.cleanedDescription!}
                    // addressName="Starbucks"
                    // addressLocation="Calz. de los Leones"
                    />
                    :
                    null
              )
            }
  </div>
}

export default GpsTransactionsList