import { useIntl } from "react-intl"
import UelLinkButton from "components/Shared/UelLinkButton/UelLinkButton"
import UelModal, { UelModalProps } from "components/Shared/UelModal/UelModal"
import "./style.scss"

export interface FinerioModalProps extends UelModalProps {
  onActionPrivacy?: () => void
}

interface PointsProps {
  titlePoint: string
  descriptionPoint: string
  textPrivacy?: string
  onActionPrivacy?: () => void
}

const FinerioModal = ({
  opened,
  onClose,
  onActionPrivacy,
}: FinerioModalProps) => {
  const intl = useIntl()
  const PointsFinerio = ({
    descriptionPoint,
    titlePoint,
    textPrivacy,
    onActionPrivacy,
  }: PointsProps) => {
    return (
      <div className="container-points-finerio">
        <div className="icon-check">
          <img
            src="/icons/check.svg"
            alt={intl.formatMessage({ id: "alt.finerio.icon.check" })}
          />
        </div>
        <div className="content-text">
          <div className="title-points">
            <span>{intl.formatMessage({ id: titlePoint })}</span>
          </div>
          <div className="description-points">
            <p>
              {intl.formatMessage({
                id: descriptionPoint,
              })}
              {textPrivacy?.length && onActionPrivacy && (
                <UelLinkButton
                  children={textPrivacy}
                  onClick={onActionPrivacy}
                />
              )}
            </p>
          </div>
        </div>
      </div>
    )
  }
  return (
    <UelModal className="modal-finerio" opened={opened} onClose={onClose}>
      <div className="header-title-seguros">
        <h2>{intl.formatMessage({ id: "finerio.modal.title.somos" })}</h2>
      </div>
      <div className="description-about-seguros">
        <p>{intl.formatMessage({ id: "finerio.modal.description.somos" })}</p>
      </div>
      <div className="image-logo-finerio">
        <img
          src="images/finerio-logo.svg"
          alt={intl.formatMessage({ id: "alt.finerio.logo" })}
        />
      </div>
      <div className="points-secure-privacy">
        <PointsFinerio
          titlePoint="finerio.modal.points.secure"
          descriptionPoint="finerio.modal.points.decription.secure"
        />
        <PointsFinerio
          titlePoint="finerio.modal.points.privacy"
          descriptionPoint="finerio.modal.points.decription.privacy"
          textPrivacy="finerio.modal.poli.privacy"
          onActionPrivacy={onActionPrivacy}
        />
      </div>
    </UelModal>
  )
}

export default FinerioModal
