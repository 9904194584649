import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setGlobalMyGpsTimeframe } from "store/MyGps/mygps.actions";
import { ROUTES } from "utils/helpers/app.helpers";
import { MyGpsTimeframes } from "utils/helpers/mygps.helpers";

const useSelectTimeFrame = () => {
    const dispatch = useDispatch();
  const navigate = useNavigate();

  const clickHandler = useCallback((frame:MyGpsTimeframes) => {
    dispatch(setGlobalMyGpsTimeframe(frame));
    navigate(ROUTES.mygpsTimeframeStats);
  },[dispatch,navigate])

  return clickHandler;
}

export default useSelectTimeFrame;