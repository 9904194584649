import { BottomNavMenuI, LegalMenuI, SocialMenuI } from "models/app.models"
import {
  SvgCredito,
  SvgGps,
  SvgInicio,
  SvgProfile,
  SvgSave,
} from "./svgIcons.helpers"

export enum ROUTES {
  login = "/",
  signUp = "/signup",
  mygps = "/mygps",
  mygpsSelecttimeframe = "/mygps/selecttimeframe",
  mygpsTimeframeStats = "/mygps/timeframestats",
  mygpsTransactionDetails = "/mygps/transactiondetails",
  home = "/dashboard",
  success = "/success",
  recoverPassword = "/recover-password",
  recoverPasswordSuccess = "/recover-password/success",
  credito = "/credito",
  ahorro = "/ahorro",
  menu = "/menu",
  newBankAccount = "/mygps/new-bank-account",
  syncAfterSkip = "/mygps/new-bank-account/sync",
  profile = "/profile",
}

export enum SocialIcons {
  whatsapp = "whatsapp.svg",
  instagram = "instagram.svg",
  twitter = "twitter.svg",
  linkedin = "linkedin.svg",
}

export enum AppStates {
  initialState = "initialState",
  onboardingState = "onboardingState",
  homeState = "homeState",
}

export const tabletBreakpoint = window.matchMedia("(min-width: 768px)")

export const HeroImgsClassArray = ["one", "two", "three", "four", "five"]

export const isiOSDevice = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  )
}

export const iosStyleMatch =
  window.matchMedia("(display-mode: standalone)").matches && isiOSDevice()

export const bottomNavMenu: BottomNavMenuI[] = [
  {
    label: "home",
    icon: SvgInicio,
    link: ROUTES.home,
    alt: "alt.menu.home",
  },
  {
    label: "credit",
    icon: SvgCredito,
    link: ROUTES.credito,
    alt: "alt.menu.credit",
  },
  {
    label: "save",
    icon: SvgSave,
    link: ROUTES.ahorro,
    alt: "alt.menu.save",
  },
  {
    label: "gps",
    icon: SvgGps,
    link: ROUTES.mygps,
    alt: "alt.menu.gps",
  },
  {
    label: "profile",
    icon: SvgProfile,
    link: ROUTES.profile,
    alt: "alt.menu.menu",
  },
]

export const legalMenu: LegalMenuI[] = [
  {
    href: process.env.REACT_APP_UEL_TERMS!,
    copy: "footer.terms.link",
  },
  {
    href: process.env.REACT_APP_UEL_PRIVACY!,
    copy: "footer.privacy.link",
  },
]

export const socialMenu: SocialMenuI[] = [
  {
    href: `https://wa.me/${process.env.REACT_APP_NUMBER}`,
    src: "whatsapp.svg",
    alt: "footer.social.whatsapp.link",
  },
  {
    href: process.env.REACT_APP_INSTAGRAM_URL!,
    src: "instagram.svg",
    alt: "footer.social.instagram.link",
  },
  {
    href: process.env.REACT_APP_TWITTER_URL!,
    src: "twitter.svg",
    alt: "footer.social.twitter.link",
  },
  {
    href: process.env.REACT_APP_LINKEDIN_URL!,
    src: "linkedin.svg",
    alt: "footer.social.linkedin.link",
  },
]

export const regValidRFC =
  /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))/i

export const months =
  "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
    "_"
  )

export const comafy = (digits: number) => {
  if (digits >= 0) {
    return new Intl.NumberFormat("es-MX").format(digits)
  } else {
    const negativeFormattedDigit = digits.toString().slice(1)
    return new Intl.NumberFormat("es-MX", {
      minimumFractionDigits: 2,
    }).format(Number(negativeFormattedDigit))
  }
}

export const noSpecialCharsString = (publicName: string): string => {
  if (publicName !== null && publicName !== undefined) {
    return publicName.replace(/[^a-zA-Z ]/g, "")
  } else {
    return ""
  }
}

export const justNumbersString = (publicString: string): string => {
  if (publicString !== null && publicString !== undefined) {
    return publicString.replace(/[^0-9]+/g, "")
  } else {
    return ""
  }
}

export const justCharsAndNumbersString = (publicString: string): string => {
  if (publicString !== null && publicString !== undefined) {
    return publicString.replace(/[^a-zA-Z0-9 ]/g, "")
  } else {
    return ""
  }
}

export const justCharsAndNumbersStringWithSpaces = (publicString: string): string => {
  if (publicString !== null && publicString !== undefined) {
    return publicString.replace(/[^a-zA-Z0-9 ]/g, "")
  } else {
    return ""
  }
}

export const justCharsAndNumbersStringWithHyphen = (publicString: string): string => {
  if (publicString !== null && publicString !== undefined) {
    return publicString.replace(/[^a-zA-Z0-9-]/g, "")
  } else {
    return ""
  }
}

export const noRepeatSpaces = (publicString: string): string => {
  if (
    !publicString
  ) {
  } else if (publicString.slice(0, 1) === " ") {
    return publicString.slice(1)
  } else if (publicString.length > 2
    && publicString.slice(-1) === " "
    && publicString.slice(-2)[0] === " ") {
    return publicString.slice(0, -1)
  }
  return publicString
}

export const defaultDistricts = [
  {
    label: "",
    value: "",
  },
]
