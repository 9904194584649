import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { FormattedMessage } from "react-intl"
import { Avatar } from "@mantine/core"
import { ROUTES } from "utils/helpers/app.helpers"
import { RootState } from "store/reducers"
import { setUserLogout } from "store/Auth/auth.actions"
import UelLinkButton from "components/Shared/UelLinkButton/UelLinkButton"
import "./styles.scss"

const ProfileContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((state: RootState) => state.user)

  const handleLogout = useCallback(() => {
    dispatch(setUserLogout())

    navigate(ROUTES.login, { replace: true })
  }, [dispatch, navigate])

  const EachInformation = ({ label, value }: any) => {
    return (
      <div className="each-info">
        <span>
          <FormattedMessage id={label} />
        </span>
        <span>{value}</span>
      </div>
    )
  }
  return (
    <div className="container-profile">
      <div className="container-profile-information">
        <div className="container-name-avatar">
          <Avatar color="cyan" radius="xl">
            {user.firstName?.charAt(0)}
            {user.lastName?.charAt(0)}
          </Avatar>
          <div className="name-txt">
            <span>
              {user?.firstName} {user.lastName}
            </span>
          </div>
        </div>
        <EachInformation label={"register.email.label"} value={user?.email} />
        {user.phoneNumber && (
          <EachInformation
            label={"screens.profile.telefono.label"}
            value={user.phoneNumber}
          />
        )}
      </div>
      <div className="divider" />
      <div className="container-session">
        <UelLinkButton
          children={"screens.profile.close.session"}
          onClick={handleLogout}
        />
      </div>
    </div>
  )
}

export default ProfileContainer
