import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Image, Text, UnstyledButton } from "@mantine/core"
import { AppStates, ROUTES } from "utils/helpers/app.helpers"
import { showHelpModal } from "store/Utils/utils.actions"
import UelButton from "../UelButton/UelButton"
import "./styles.scss"
import { useLocation, useNavigate } from "react-router-dom"
import { RootState } from "store/reducers"
import { setAppState } from "store/Auth/auth.actions"
export interface UelHeaderProps {
  withHelpIcon: boolean
}

const UelHeader = ({ withHelpIcon }: UelHeaderProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handlerHelpModal = useCallback(() => {
    dispatch(showHelpModal(true))
  }, [dispatch])

  const isSigned = useSelector(
    (state: RootState) => state.auth.appState === AppStates.homeState
  )
  const { formatMessage } = useIntl()
  const user = useSelector((state: RootState) => state.user)
  const location = useLocation()
  const currentPathname = location.pathname.toLowerCase()

  const onLogoClick = () => {
    isSigned ? navigate(ROUTES.home) : window.location.reload()
  }

  return (
    <Box
      className="header-container">
      <div className="box"></div>
      <div
        className="logo"
        onClick={onLogoClick}/>
      {isSigned && (
        <div
          className="header-profile tablet"
          onClick={() => {
            if (currentPathname !== ROUTES.profile) {
              navigate(ROUTES.profile)
            }
          }}>
          <img
            className="header-profile-img"
            alt={formatMessage({ id: "alt.header.profile.image" })}
            src="/images/header_profile_img.png"
          />
          <img
            className="header-profile-arrow"
            alt={formatMessage({ id: "alt.header.profile.image" })}
            src="/icons/header-profile-icon.svg"
          />
          <div className="profile-data-wrapper">
            <p className="profile-username">{user?.firstName}</p>
            <p className="profile-email">{user?.lastName}</p>
          </div>
        </div>
      )}
      <div className={`menu-right ${isSigned ? "tablet" : ""}`}>
        {!isSigned && (
          <UelButton
            className="tablet-sign-in"
            title="btn.tablet.sign.in"
            variant="quinary"
            onClick={() => {
              dispatch(setAppState(AppStates.initialState));
              navigate(ROUTES.login)
            }}
          />
        )}
        <UelButton
          title="btn.tablet.blog"
          variant="tertiary"
          onClick={() =>
            window.open(
              process.env.REACT_APP_BLOG_UEL,
              "_blank",
              "noopener,noreferrer"
            )
          }
        />
        <UnstyledButton className="help-container" onClick={handlerHelpModal}>
          <Image
            className="img-help"
            alt="Uellbee Logo"
            src="/icons/helpicon.svg"
            width={21}
            height={21}
          />
          <Text className="text-help">
            <FormattedMessage id="header.help" />
          </Text>
        </UnstyledButton>
      </div>
    </Box>
  )
}

export default UelHeader
