import { useIntl } from "react-intl"
import { IntlString, UelModalSize } from "models/app.models"
import UelButton from "../UelButton/UelButton"
import UelLinkButton from "../UelLinkButton/UelLinkButton"
import UelModal, { UelModalProps } from "../UelModal/UelModal"
import "./style.scss"
export interface UelFancyModalProps
  extends Omit<UelModalProps, "children" | "onClose" | "withCloseButton"> {
  title: IntlString
  subtitle?: IntlString
  description: string
  linkButtonTitle: string
  linkButtonOnClick: () => void
  onClose?: () => void
  withCustomBackground?: boolean
  size?: UelModalSize
  withButton?: () => void
}

const UelFancyModal = (props: UelFancyModalProps) => {
  const intl = useIntl()
  const {
    title,
    subtitle,
    description,
    linkButtonTitle,
    opened,
    onClose = () => {},
    linkButtonOnClick,
    withCustomBackground = false,
    size = "sm",
    withButton,
    className,
  } = props
  const modalStyle = `
    uel-small-modal size-${size} ${withCustomBackground ? "with-custom-bg" : ""}
    ${className}
  `
  return (
    <UelModal className={modalStyle} {...{ opened, onClose }}>
      <div className="content">
        <div className="header">
          <h2>{intl.formatMessage({ id: title })}</h2>
          {subtitle && (
            <p className="subtitle">{intl.formatMessage({ id: subtitle })}</p>
          )}
          <p className="description">
            {intl.formatMessage({ id: description })}
          </p>
        </div>
        <UelLinkButton onClick={linkButtonOnClick}>
          {intl.formatMessage({ id: `${linkButtonTitle}` })}
        </UelLinkButton>
        {withButton && (
          <div className="button-option">
            <UelButton
              title="modal.finerio.noAccount.exit"
              onClick={withButton}
            />
          </div>
        )}
      </div>
    </UelModal>
  )
}

export default UelFancyModal
