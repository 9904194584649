import UelClient from "utils/helpers/UelClient"
import PublicUelClient from "utils/helpers/PublicUelClient";
import { StrapiResponse } from "models/app.models";
import { LoginResponseModel } from "models/login.models";
import { PersonalDataFormModelServiceI, StatesResponseI } from "models/signup.models"
import { AddressInformationI, GrantScorePrivI, RegisterUserServiceI, UserFirstTimeSyncRes } from "models/signup.models";
import axios from "axios";

export const setDataPersonaService = async ({
  firstName,
  middleName,
  lastName,
  secondLastName,
  dateOfBirth,
  placeOfBirth,
  rfc,
  sex,
}: PersonalDataFormModelServiceI) => {
  return UelClient.post("/uellbee-user/onboarding/personal-information", {
    firstName,
    middleName,
    lastName,
    secondLastName,
    dateOfBirth,
    placeOfBirth,
    rfc,
    sex,
  })
}

export const registerUserService = async (params: RegisterUserServiceI) => {
  const { email, password, agreement, utm_source, utm_medium, utm_campaign, utm_term, utm_content } = params;

  const payload: any = { email, password, agreement };
  if (utm_source) payload.utm_source = utm_source;
  if (utm_medium) payload.utm_medium = utm_medium;
  if (utm_campaign) payload.utm_campaign = utm_campaign;
  if (utm_term) payload.utm_term = utm_term;
  if (utm_content) payload.utm_content = utm_content;

  const response = await UelClient.post('/uellbee-user/register', payload);
  return response
}

export const emailVerifyService = async (code: string, email: string): Promise<StrapiResponse<LoginResponseModel>> => {
  return UelClient.post("/uellbee-user/verify", {
    email,
    code,
  })
}

export const stateCodesService = async (): Promise<StatesResponseI> => {
  return PublicUelClient.get(`/states`)
}

export const resendCodeEmailService = async (source: string, channel:string) => {
  return UelClient.post("/uellbee-user/resend", { source, channel })
}

export const getAddressByZipCodeService = async (zipcode: number) => {
  return UelClient.get(`/postal-codes/${zipcode}`)
}

export const getCreditScoreService = async () => {
  return UelClient.get(`/legals/current?type=buro-terms`)
}

export const getTermsSignUp = async () =>{
  return UelClient.get(`/legals/current?type=signup-terms`)
}

export const getPrivacyTerms = async () => {
  return UelClient.get('/legals/current?type=signup-privacy')
}
export const grantScorePrivService = async (props: GrantScorePrivI) => {
  return UelClient.post(`/uellbee-user/onboarding/buro`, props)
}

export const setAddressInformationService = async (address: AddressInformationI) => {
  return UelClient.post('/uellbee-user/onboarding/address', {
    postalCode: address.postalCode,
    street: address.street,
    intNumber: address.intNumber,
    extNumber: address.extNumber,
    country: 'MX',
    district: address.district,
    city: address.city,
    state: address.state
  })
}

export const setFinerioService = async (finerioId: string) => {
  return UelClient.post(`/uellbee-user/onboarding/bank-account`, {
    finerioUUID: finerioId
  })
}

export const skipFinerioAddAccount = async () => {
  return UelClient.post(`/uellbee-user/onboarding/bank-account`, {
    skip: true
  })
}

export const sendEmailToReceiveCode = async (email: string) => {
  return UelClient.post('/uellbee-user/forgot-password/send-code', { email: email })
}

export const sendCodeToChangePassword = async (email: string, code: string) => {
  return UelClient.post('/uellbee-user/forgot-password/verify', { email: email, code: code })
}

export const resendCodeToChangePassword = async (email: string) => {
  return UelClient.post('/uellbee-user/forgot-password/resend', {email: email})
}

export const resetNewPassword = async (code: string, password: string, passwordConfirmation: string) => {
  return UelClient.post('/auth/reset-password', {code, password, passwordConfirmation})
}

export const changeEmailOnRegister =async (userId:number) => {
  return UelClient.delete(`/uellbee-user/change-email-on-register/${userId}`)
}

export const getUserFirstTimeSyncService = async (): Promise<UserFirstTimeSyncRes> => {
  return UelClient.get(`/uellbee-user/firstTimeSync`)
}

export const setRegisterTelephone = async (phone: string, channel: string) => {
  return UelClient.post(`/uellbee-user/onboarding/phone-number`, {
    phone,
    channel,
  })
}
export const setCodeTelephone = async (phone: string, code: string) => {
  return UelClient.post(`/uellbee-user/onboarding/phone-number/validate`, {
    phone,
    code,
  })
}

export const resendCodeTelephoneService = async (source: string, channel:string) => {
  return axios.post(process.env.REACT_APP_API_URL+"/uellbee-user/resend", { source, channel })
}