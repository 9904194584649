//Carousel
import { Carousel } from "@mantine/carousel";
//Components
import UelButton from "../UelButton/UelButton";
//18n
import { FormattedMessage } from "react-intl";
//Models
import { ContentTutorialToSlide } from "models/app.models";
import "./style.scss"
//Interface
export interface UelCarouselTutorialProps {
  onActionPrimaryBtn: (value:number) => void;
  contentTutorial?: ContentTutorialToSlide[];
}

const UelCarouselTutorial = ({
  onActionPrimaryBtn,
  contentTutorial,
}: UelCarouselTutorialProps) => {
  
  const sliders =
    contentTutorial &&
    contentTutorial.map((cont, index) => (
      <Carousel.Slide
        style={{ backgroundImage: `url(${cont.img})` }}
        key={index}
      >
        <div className="container-slide">
          <div className="container-img-logo">
            <img id="logo-uellbee" src={"/logos/Logotipo_blanco.svg"} alt="logo-uellbee" />
          </div>
          <div className={"container-titles"}>
            <div className={`${index === 0 ? "title" : "title-lines"} ${index === 1 ? "extra-space" : ""}`}>
              <span>
                <FormattedMessage id={cont.title} />
              </span>
            </div>
            <div className="subtitle">
              <span>
                <FormattedMessage id={cont.subtitle} />
              </span>
            </div>
          </div>
          <div className="container-buttons">
            <div className="btn-princi">
              <UelButton
                title="tutorial.carousel.btn.start"
                onClick={()=> onActionPrimaryBtn(1)}
              />
            </div>
            <div className="container-session">
              <span
                id="txt-session"
                onClick={()=> onActionPrimaryBtn(2)}
              >
                <FormattedMessage id="tutorial.carousel.btn.session" />
              </span>
            </div>
          </div>
        </div>
      </Carousel.Slide>
    ));
  // RENDER:
  return (
    <Carousel withIndicators slideSize={"100%"} withControls={false}>
      {sliders}
    </Carousel>
  );
};

export default UelCarouselTutorial;
