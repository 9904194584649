import axios from 'axios';
import UelButton from "components/Shared/UelButton/UelButton";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setCreditBuilderStep } from 'store/SignUp/signup.actions';
import { SignUpCreditBuilderSteps } from "utils/helpers/signup.helpers";
import { RootState } from "store/reducers";
import { useEffect, useState } from 'react';
import "./styles.scss"
import UelLinkButton from "components/Shared/UelLinkButton/UelLinkButton";
import UelBackButton from "components/Shared/UelBackButton/UelBackButton";
import LoadingScreen from "screens/LoadingScreen/LoadingScreen";

const CreditBuilderKYCContainer = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("Component render");
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const url = process.env.REACT_APP_CB_LAMBDA_URL;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const process_id = queryParams.get('process_id');

  console.log(`Process ID: ${process_id}`);

  const email = useSelector(
    (state: RootState) =>
      state.auth.authentication?.data?.email ||
      state.user.email
  )

  console.log(`Email: ${email}`);

  const user_id = useSelector(
    (state: RootState) =>
      state.auth.authentication.data.id
  )

  console.log(`User ID: ${user_id}`);

  const [isLoading, setIsLoading] = useState(false);

  const postToLambda = async () => {
    console.log("Calling postToLambda");
    if (!url) throw new Error('REACT_APP_CB_LAMBDA_URL is not set in .env file');
    
    const response = await axios.post(url, {
      "email": email,
      "gps_id": user_id
    }, {
      headers: {
        'Content-Type': 'application/json',
        'password': process.env.REACT_APP_LAMBDAS_PASS || ''
      },
    });

    console.log(`Response from postToLambda: ${JSON.stringify(response.data)}`);
    return response;
  };

  const apiKey = process.env.REACT_APP_TRUORA_API_KEY;

  console.log(`API Key: ${apiKey}`);

  if (!apiKey) {
    throw new Error('REACT_APP_TRUORA_API_KEY is not set in .env file');
  }

  const postToKuspitLambda = async (idExterno: string) => {
    console.log("Calling postToKuspitLambda");
    const kuspitUrl = process.env.REACT_APP_CB_LAMBDA_KUSPIT_URL;
  
    if (!kuspitUrl) {
      throw new Error('REACT_APP_CB_LAMBDA_KUSPIT_URL is not set in .env file');
    }
    
    const response = await axios.post(kuspitUrl, {
      "idExterno": idExterno,
      "truoraSuccess": true
    }, {
      headers: {
        'Content-Type': 'application/json',
        'password': process.env.REACT_APP_LAMBDAS_PASS || ''
      },
    });
  
    console.log(`Response from postToKuspitLambda: ${JSON.stringify(response.data)}`);
    return response;
  };

  type DeclineReasons = 
  | 'document_has_expired'
  | 'document_is_a_photocopy'
  | 'document_not_recognized'
  | 'image_face_validation_not_passed'
  | 'image_text_validation_not_passed'
  | 'incomplete_document'
  | 'invalid_curp'
  | 'traces_of_tampering'
  | 'no_face_detected'
  | 'photo_of_photo';

  const checkProcessId = async () => {
    setIsLoading(true);
    console.log("Calling checkProcessId");
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://api.connect.truora.com/v1/processes/${process_id}/result`,
      headers: { 
        'Truora-API-Key': apiKey
      }
    };

    const response = await axios.request(config);
    console.log(`Response from checkProcessId: ${JSON.stringify(response.data)}`);

    if (response.data.status === 'success') {
      await postToKuspitLambda(user_id.toString()); // assuming user_id is the idExterno
      dispatch(setCreditBuilderStep(SignUpCreditBuilderSteps.zapsign));
      localStorage.removeItem('token');
      navigate('/ahorro');
    } else if (response.data.status === 'failure') {
        const customMessages: Record<DeclineReasons, string>= {
          document_has_expired: "El documento ha expirado.",
          document_is_a_photocopy: "El documento es una fotocopia.",
          document_not_recognized: "El documento no es reconocido en la imagen.",
          image_face_validation_not_passed: "El análisis de la imagen facial no ha pasado.",
          image_text_validation_not_passed: "El análisis del texto en la imagen no ha pasado.",
          incomplete_document: "El documento está incompleto o no se pudo ver completamente.",
          invalid_curp: "El CURP no coincide con el generado por nosotros.",
          traces_of_tampering: "La foto muestra evidencia de manipulación.",
          no_face_detected: "No se detectó un rostro.",
          photo_of_photo: "La validación se realizó con una foto en otro dispositivo o es una imagen de documento."
        };
    
        const errorMessage = customMessages[response.data.declined_reason as DeclineReasons] || 
          'La validación ha fallado. Asegúrate de que las fotos tengan buena iluminación, no tengan reflejos ni sombras, y que los datos sean legibles. Intenta nuevamente.';
    
        alert(errorMessage);
        setIsLoading(false);
    } else if (response.data.status === 'pending') {
      setTimeout(checkProcessId, 3000); // Check again after 3 seconds
    }
  }

  const goForward = async () => {
    setIsLoading(true);
    console.log("Calling goForward");
    const response = await postToLambda();
    console.log("lambda status: ", response.status);
    
    if (response.status === 200) {
      // save account_id to local storage
      localStorage.setItem('account_id', response.data.account_id);

      // redirect user to the new url
      window.location.href = response.data.url;
    } else if (response.status === 400) {
      // Display error message
      console.log("Response status 400 from goForward");
      alert('Error en el servicio de validación de identidad.'); // Add your own error message
      navigate('/ahorro');
    }
  };

  useEffect(() => {
    console.log("UseEffect callback");
    if (process_id) {
      checkProcessId();
    }
  }, [process_id]);

  const goBackWithLinkButton = () => {
    // Dispatch action to update credit builder step
    dispatch(setCreditBuilderStep(SignUpCreditBuilderSteps.billing));

    // Navigate to /ahorro
    navigate('/ahorro');
  };

  const cancelCB = () => {
    navigate('/')
  }

  if (isLoading) {
    return <LoadingScreen />;  // You need to create or import this LoadingScreen component
  }
  
  return (
    <div className="home-container-cb">
        <div className="link-button-personal">
                <UelBackButton
                    onClick={goBackWithLinkButton}
                />
          </div>
    <div className="message-part-kyc">
      <h2 className='header-kyc'>Valida tu identidad</h2>
      <h3 className='header-description-kyc'>Ahora requerimos validar tu identidad. Antes de comenzar, por favor considera lo siguiente:</h3>
      <ul className='list-kyc-details list-container'>
        <li> Utiliza tu INE, pasaporte o FM3 para identificarte</li>
        <li> Asegura que esta identificación sea tuya y esté vigente</li>
        <li> Toma las fotos solicitadas con suficiente iluminación; evita sombras o reflejos</li>
        <li> Procura que tu selfie sea con cara descubierta</li>
        <li> Ten a la mano un comprobante de domicilio de no más de 3 meses</li>
      </ul>
    </div>
    <div className="button-container-kyc">
      <UelButton
        title={"cb.kyc.btn"}
        onClick={goForward}
        variant="primary"
        disabled={false}
      />
    </div>
    <div className="link-button-kyc">
                <UelLinkButton
                    onClick={cancelCB}
                    color="secondary"
                >
                    {"No quiero mejorar mi buró"}
                </UelLinkButton>
          </div>
  </div>
);
};

export default CreditBuilderKYCContainer;