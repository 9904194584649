import React from "react"
import { Link, useLocation } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { Menu } from "@mantine/core"
import { bottomNavMenu as menu, iosStyleMatch } from "utils/helpers/app.helpers"
import "./style.scss"
import { useSelector } from "react-redux"
import { useState, useEffect } from "react"
import axios from 'axios'
import { RootState } from "store/reducers"

const UelBottomNavigation = () => {
  const location = useLocation()

  const { email = '' } = useSelector((state: RootState) => ({
    email: state.user.email
  }));

  // const publicKey = process.env.REACT_APP_VL_PK;
  // const maxOrder = Number(process.env.REACT_APP_MAX_ORDER);
  
 
  // const [showAhorroItem, setShowAhorroItem] = useState(false);
  // const [showCreditItem, setShowCreditItem] = useState(true);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (email=='') {
  //       return;
  //     }
      
  //     if (email.endsWith('@uellbee.com')) {
  //       setShowAhorroItem(true);
  //       return;
  //     }
  //     const config = {
  //       method: 'get',
  //       maxBodyLength: Infinity,
  //       url: `https://app.viral-loops.com/api/v3/campaign/participant/order?publicToken=${publicKey}&email=${email}`,
  //       headers: { 
  //         'accept': 'application/json'
  //       },
  //     };

  //     try {
  //       const response = await axios.request(config);
  //       // Do not override showAhorroItem if it's already true
  //       if (!showAhorroItem && response.status === 200 && response.data.order && response.data.order <= maxOrder) {
  //         setShowAhorroItem(true);
  //       }
  //       if (response.status === 200 && response.data.order) {
  //         setShowCreditItem(false); 
  //       } else {
  //         setShowCreditItem(true);  
  //       }
        
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  // }, [email, publicKey, maxOrder, showAhorroItem]);

  return (
    <nav className={`container-navbar ${iosStyleMatch ? "ios" : ""}`}>
      <Menu>
        {menu.map((item, key) => {

          // if (item.label === 'save' && !showAhorroItem) {
          //   return null
          // }
          // if (item.label === 'credit' && !showCreditItem) {
          //   return null;
          // }
          
          const currentLocation = location.pathname
            .toLocaleLowerCase()
            .startsWith(item.link)
          const activeLabel = currentLocation ? "active" : ""
          return (
            <Menu.Item
              component={Link}
              to={item.link}
              key={key}
              icon={
                <div className={`iconWrapper ${activeLabel}`}>
                  {item.icon()}
                </div>
              }>
              <Menu.Label className={`title-text ${activeLabel}`}>
                <FormattedMessage id={`bottomNavigation.${item.label}`} />
              </Menu.Label>
            </Menu.Item>
          )
        })}
      </Menu>
    </nav>
  )
}

export default UelBottomNavigation
