import { SET_SHOW_FINERIO_MODAL, SET_SHOW_HELP_MODAL } from "./utils.reducer"

export const showHelpModal = (show: boolean) => {
  return {
    type: SET_SHOW_HELP_MODAL,
    payload: show
  }
}

export const setShowFinerioModal = (isOpen: boolean) => {
  return {
    type: SET_SHOW_FINERIO_MODAL,
    payload: isOpen
  }
}