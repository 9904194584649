
import { BankAccountI} from "models/mygps.models";
import { useCallback, useEffect, useState } from "react";
import { accountIdsService } from "services/mygps.service";

const useGetAccountIds = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [accountIdsData, setAccountIdsData] = useState<BankAccountI[] | null>(null)

  const getAccountIds = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const data = await accountIdsService();
      setAccountIdsData(data.data.bankAccounts)
    } catch (_error) {
      setError(true);
      setAccountIdsData([])
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getAccountIds()
  }, [getAccountIds])
  
  return {
    loading,
    error,
    accountIdsData
  };
};

export default useGetAccountIds
