import { useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { batch, useDispatch, useSelector } from "react-redux"
import { FormattedMessage } from "react-intl"
import { ApexOptions } from "apexcharts"
import { ROUTES } from "utils/helpers/app.helpers"
import { CategoriesWithIconsExample, PieChartOptionsExample, PieChartSeriesExample } from "utils/dataExamples/MyGpsExamples"
import { expensesCategoriesIcons, pieChartStaticOptions } from "utils/helpers/mygps.helpers"
import { ExpenseCategoryI, ExpensesNamesI } from "models/mygps.models"
import { RootState } from "store/reducers"
import { setAccountIndex, setActiveExpenseCategory } from "store/MyGps/mygps.actions"
import UelCard from "components/Shared/UelCard/UelCard"
import GpsCategory from "components/MyGps/GpsCategory/GpsCategory"
import ExpensesPieChart from "../ExpensesPieChart/ExpensesPieChart"
import "./style.scss"
export interface ExpensesCardProps {
  accountIndex : number
}

const ExpensesCard = ({
  accountIndex
}: ExpensesCardProps) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const expensesState = useSelector((state: RootState) => state.mygps.expenses)
  const shorthandCategories = expensesState?.expenses?.expenseCategoryDistribution;

  const expensesCategories : ExpenseCategoryI[] | undefined = useMemo(() => shorthandCategories?.map((expense) => ({
    id:expense.id!,
    name: expense.name!,
    amount: expense.amount!,
    icon: expensesCategoriesIcons.filter(
      (category) => category.name === expense.name
      )[0]?.icon!,
    color: expense.color!,
    percent:expense.percent!
  })), [shorthandCategories])

  const transactionDetailsHandler = useCallback((name:string) => {
    const activeCategory = expensesCategories?.filter(
      item => item.name === name
    )[0]
    batch(() => {
      dispatch(setAccountIndex(accountIndex))
      dispatch(setActiveExpenseCategory({
        name: activeCategory?.name!,
        id: activeCategory?.id!,
        src: activeCategory?.icon!
      }))
    })
    navigate(ROUTES.mygpsTransactionDetails)
  },[dispatch, navigate, accountIndex, expensesCategories])
  
  const pieChartOptions = useMemo<ApexOptions>(() => ({
    labels:expensesCategories?.map(category => category.name)!,
    colors: expensesCategories?.map(category => category.color)!,
    chart: {
      events: {
        dataPointSelection: (event: any, chartContext: any, config: any) => {
          transactionDetailsHandler(config.w.config.labels[config.dataPointIndex])
        }
      },
    },
    ...pieChartStaticOptions
  }),
    [expensesCategories, transactionDetailsHandler])
  
  const categoryClickHandler = useCallback(
    (name: ExpensesNamesI) => {
      transactionDetailsHandler(name)
    },
    [transactionDetailsHandler],
  )
  
  return (
    <div className="expenses-card-container">
      {shorthandCategories?.every(category => category.amount === 0) ?
        <h3>
          <FormattedMessage id="screens.mygps.chart.error" />
        </h3>
        :
        <UelCard className="component-expenses-card">
        <h1>
          <FormattedMessage id="screens.mygps.expenses.card.title" />
        </h1>
        <ExpensesPieChart
          width={224.5}
          height={235.5}
          options={shorthandCategories? pieChartOptions! : PieChartOptionsExample}
          series={shorthandCategories? expensesCategories?.map((category) => category.percent)! : PieChartSeriesExample}
        />
        <div className="categories">
          {
            shorthandCategories ?
            expensesCategories?.map((category, index) => (
              <GpsCategory
                key={index + 1}
                label={category.name}
                amount={category.amount}
                iconSrc={category.icon}
                iconBgColor={category.color}
                onClick={categoryClickHandler}
              />
            )) :
            CategoriesWithIconsExample?.map((category, index) => (
              <GpsCategory
                key={index + 1}
                label={category.name}
                amount={category.amount}
                iconSrc={category.icon}
                iconBgColor={category.color}
                onClick={()=> categoryClickHandler(category.name)}
              />
            )) 
          }
          </div>
          </UelCard>
      }
    </div>
  )  
}

export default ExpensesCard
