import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMyFinerioIdSkipAccount, setMyGpsNewBankAccount, setMyGpsNewBankAccountError, setMyGpsNewBankAccountRequest } from "store/MyGps/mygps.actions";
import { RootState } from "store/reducers";
import { setSignUpFinerioIsTimeout } from "store/SignUp/signup.actions";
import { ROUTES } from "utils/helpers/app.helpers";

const FinerioGpsAddAcountListener = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isRequested = useSelector((state: RootState) => state.mygps.newBankAccount.isRequested); 
  const isSuccess = useSelector((state: RootState) => state.mygps.newBankAccount.isSuccess);
  const isError = useSelector((state: RootState) => state.mygps.newBankAccount.isError);  
  const withFinerioUUID = useSelector((state: RootState) => state.mygps.withFinerioUUID);  

  const handleFinerioGpsCallback = useCallback((event: any) => {
    console.log("EVENT: ", event)
    if (event.detail.status === "CREDENTIAL_CREATED"){      
      setTimeout(() => {
        if(isSuccess === false && isError === false){
          dispatch(setSignUpFinerioIsTimeout(true))
        }        
      }, process.env.REACT_APP_FINERIO_TIMEOUT ? parseInt(process.env.REACT_APP_FINERIO_TIMEOUT) : 360000)
    }

    if (event.detail.status === "SUCCESS") {
      if(withFinerioUUID === true){
        dispatch(setMyGpsNewBankAccount(event.detail.credentialId))
        dispatch(setSignUpFinerioIsTimeout(false))
      }else {
        console.log("My first account")
        //dispatch(setFinerioSignUpSuccess(event.detail.customerId.toString()))        
        dispatch(setMyFinerioIdSkipAccount(
          event.detail.customerId.toString(),
          () => null
          )
        )
      }     
    }

    if (event.detail.status === "FAILURE") {
      dispatch(setSignUpFinerioIsTimeout(false))
      dispatch(setMyGpsNewBankAccountError())
    }
  },[dispatch, isSuccess, isError, withFinerioUUID])

  const handleSuccessGpsCloseButton = useCallback(() => {
    if(withFinerioUUID === true){
      navigate(ROUTES.mygps, { replace: true })
    }else {
      navigate(ROUTES.syncAfterSkip, { replace: true })
    }
    
  }, [navigate, withFinerioUUID])

  /* Found finerio listener adding new accounts from MyGPS */
  useEffect(() => {
    if (pathname === ROUTES.newBankAccount) {
      const component = document.querySelector("fc-bank-aggregation")
      if (component !== null) {
        console.log("CFF")
        component.addEventListener("onCallback", handleFinerioGpsCallback)
        dispatch(setMyGpsNewBankAccountRequest())
        dispatch(setSignUpFinerioIsTimeout(false))
        /* Remove listener */
        if (pathname !== ROUTES.newBankAccount) {
          component.removeEventListener("onCallback", handleFinerioGpsCallback)
        }
      }
    }
  }, [handleFinerioGpsCallback, pathname, dispatch])

  /* Close button success adding new accounts from MyGPS */
  useEffect(() => {
    if (isRequested === true && pathname === ROUTES.newBankAccount) {
      const closeButton = document.querySelector("fc-bank-aggregation")
      console.log("Close button", closeButton)
      if (closeButton !== null) {
        console.log("CBFGPS")
        closeButton.addEventListener("onClickExitButton", handleSuccessGpsCloseButton)

        /* Remove listener */
        if (pathname !== ROUTES.newBankAccount) {
          closeButton.removeEventListener(
            "onClickExitButton",
            handleSuccessGpsCloseButton
          )
        }
      }
    }
  }, [isRequested, navigate, handleSuccessGpsCloseButton, pathname])

  return null;
}

export default FinerioGpsAddAcountListener;