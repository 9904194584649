import { AccountIdsResponseI, ExpensesResponseI, ExpensesServiceI, GpsTransactionCollectionI, RiskLevelsResponseI, TransactionsRangeResponseI, TransactionsServiceI, UserScoreResponseI } from "models/mygps.models"
import UelClient from "utils/helpers/UelClient"

export const getUserScoreService = async () : Promise<UserScoreResponseI> => {
  return UelClient.get("uellbee-user/buro/score")
}

export const getRiskLevelsService = async () : Promise<RiskLevelsResponseI> => {
  return UelClient.get("buro/risk-levels")
}

export const transactionsTimeRangeService = async (): Promise<TransactionsRangeResponseI> => {
  return UelClient.get("uellbee-user/transactions-date-range")
}

export const accountIdsService = async() : Promise<AccountIdsResponseI>=> {
  return UelClient.get(`uellbee-user/bank-accounts`)
}

export const expensesService = async({
  startDate,
  endDate,
  accountId
}: ExpensesServiceI) : Promise<ExpensesResponseI>=> {
  return UelClient.get(`uellbee-users/gps?startDate=${startDate}&endDate=${endDate}&accountId=${accountId}`)
}

export const transactionsService = async({
  startDate,
  endDate,
  accountId,
  categoryId
}: TransactionsServiceI): Promise<GpsTransactionCollectionI> => {

  return UelClient.get(`uellbee-users/gps/transactions?startDate=${startDate}&endDate=${endDate}&accountId=${accountId}&categoryId=${categoryId}`)
}

export const addGpsBankAccount = async (crendentialId: string) => {
  return UelClient.post(`uellbee-user/sync-bank-accounts`, { credentialId: crendentialId })
}