import { useRef } from "react"
import { useIntl } from "react-intl"
import { Select } from "@mantine/core"
import { DropdownEvent, DropdownItem } from "models/app.models"
import "./style.scss"
export interface UelDropdownProps {
  data: DropdownItem[]
  label?: string
  onChange?: DropdownEvent
  onBlur?: React.ChangeEventHandler<HTMLInputElement>
  value?: string | undefined
  defaultValue?: string
  name?: string
  error?: string | undefined
  isRequired?: boolean
  placeholder?: string
  alterStyle?: boolean
}

const UelDropdown = (props: UelDropdownProps) => {
  const {
    name,
    data,
    label,
    onChange,
    onBlur,
    value,
    defaultValue,
    error,
    isRequired,
    placeholder,
    alterStyle
  } = props
  const { formatMessage } = useIntl()
  const ref = useRef<HTMLInputElement>(null)
  const onClickRightIcon = () => {
    ref.current?.click()
  }
  const containerClassname = `uel-dropdown ${alterStyle ? 'alter' : undefined}`;

  return (
    <Select
      className={containerClassname}
      name={name}
      ref={ref}
      value={value}
      defaultValue={defaultValue}
      data={data}
      error={error  && formatMessage({ id: error })}
      label={label && formatMessage({ id: label })}
      onChange={onChange}
      onBlur={onBlur}
      rightSectionProps={{onClick: onClickRightIcon}}
      rightSection={
        <div className="dropdown-icon">
          <img 
            src={alterStyle ? "/icons/dropdownArrow_large.png" : "/icons/dropdownArrow.svg"} 
            alt={formatMessage({id: "alt.dropdown.arrow"})}
          />
        </div>
      }
      withAsterisk={isRequired}
      placeholder={placeholder && formatMessage({ id: placeholder })}
    />
  )
}

export default UelDropdown
