import axios from "axios";

const PublicUelClient = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	responseType: "json",
	headers: {
		"Content-Type": "application/json",
	},
});

export default PublicUelClient;
