import { Carousel } from "@mantine/carousel"
import UelButton from "components/Shared/UelButton/UelButton"
import UelLinkButton from "components/Shared/UelLinkButton/UelLinkButton"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setCreditBuilderStep } from "store/SignUp/signup.actions"
import { SignUpCreditBuilderSteps } from "utils/helpers/signup.helpers"
import "./styles.scss"
import thumbsUp from "icons/10.svg"
import payments from "icons/12.svg"
import score from "icons/6.svg"
import { useEffect } from "react"

const CreditBuilderDescriptionContainer = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const goForward = () => {
    // Dispatch action to update credit builder step
    dispatch(setCreditBuilderStep(SignUpCreditBuilderSteps.plan))

    // Navigate to /ahorro
    navigate("/ahorro")
  }

  const redirectToWhatsApp = () => {
    window.open(
      `https://wa.me/${process.env.REACT_APP_NUMBER}`,
      "_blank",
      "noopener,noreferrer"
    )
  }

  return (
    <div className="home-container-cb">
      <header>
        <h1 className="text-center">Energiza tu buró de crédito con Uellbee</h1>
      </header>
      <Carousel
        className="cb-description-carousel"
        withIndicators
        slideSize="100%"
        draggable={true}
        speed={3}
        initialSlide={0}
        controlsOffset="xs"
        controlSize={20}
        align="center"
        withControls={false}
        styles={{
          indicator: {
            border: "1px solid #1E335C",
            "&[data-active]": {
              background: "#1E335C",
            },
          },
        }}
      >
        <Carousel.Slide className="custom-slide">
          <div className="circle-sart-container">
            <p style={{ lineHeight: "1.4em" }}>
              <strong>
                Inscríbete al programa y mejora tu score de buró en tan solo 12
                meses
                <br />
                <br />
                Es muy sencillo siguiendo 3 pasos
                <br />
                <br />
                ¡Mejorar tu calificación de crédito sí es posible!
              </strong>
            </p>
          </div>
        </Carousel.Slide>
        <Carousel.Slide className="custom-slide">
          <div>
            <img src={thumbsUp} alt="Thumbs Up" className="thumbs-up" />
            <p>
              <br />
              <strong>
                1. Te inscribes al programa para mejorar tu historial crediticio
              </strong>
              <br />
              <br />
              ¡Contratas tu plan y asumes un compromiso de pago mensual!
            </p>
          </div>
        </Carousel.Slide>
        <Carousel.Slide className="custom-slide">
          <div>
            <img src={payments} alt="Payments" className="payments" />
            <p>
              <br />
              <strong>2. Cumples con tus pagos y ahorras</strong>
              <br />
              <br />
              Abona tu mensualidad a tiempo y Uellbee reporta tu pago a buró
              cada mes
              <br />
              <br />
              Tus abonos construyen ahorro que podrás utilizar al finalizar el
              programa
            </p>
          </div>
        </Carousel.Slide>
        <Carousel.Slide className="custom-slide">
          <div>
            <img src={score} alt="Score" className="score" />
            <p>
              <strong>3. Exponencia tu perfil crediticio</strong> <br />
              <br />
              Después de <strong>12 meses...</strong>
              <br />
            </p>
            <div className="benefits-list">
              <li>Mejoras tu historial de crédito</li>
              <li>Construyes el hábito del ahorro</li>
              <li>Tienes un dinero para destinar a lo que tú quieras</li>
            </div>
          </div>
        </Carousel.Slide>
      </Carousel>
      <div className="button-container-cb">
        <UelButton
          title={"dashboard.cb.msg"}
          onClick={goForward}
          variant="primary"
          disabled={false}
          className="energize-credit"
        />
      </div>
      <div className="mt-3">
        <UelLinkButton onClick={redirectToWhatsApp}>
          Quiero más información
        </UelLinkButton>
      </div>
    </div>
  )
}
export default CreditBuilderDescriptionContainer