import { useSelector } from "react-redux"
import { RootState } from "store/reducers"
import "./style.scss"

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "fc-bank-aggregation": any
    }
  }
}
interface FinerioComponentProps {
  customerId?: number
  customerName: string
  forceToContinue?: () => void
  continueLater?: () => void
}

const FinerioComponent = (props: FinerioComponentProps) => {
  const { customerId, customerName, continueLater } = props;
  const isFinerioTimeout = useSelector((state: RootState) => state.signup.finerio.isFinerioTimeout);

  return (
    <div className="finerio-component-container">
      <div className="finerio-component">
        <fc-bank-aggregation
          widget-id={process.env.REACT_APP_FINERIO_WIDGET_ID}
          customer-name={customerName}
          environment={process.env.REACT_APP_FINERIO_ENV}
          main-color="#454FF5"
          terms-text-color="#454FF5"
          customer-id={customerId ? customerId : undefined}
          close-widget={false}
          show-bank-search={true}
          banks-title={""}
          show-welcome-screen={false}
          main-font="Work Sans"
          create-credential-title={""}
          synchronization-title={""}
          success-title={""}
          error-title={""}></fc-bank-aggregation>
      </div>
      
      {isFinerioTimeout &&
        <div className="infinite-loading">
          <span className="message">
            No estamos obteniendo respuesta de tu banco, ¿deseas realizar esto más tarde?
          </span>
          <span className="link" onClick={continueLater}>
            Realizar más tarde
          </span>
        </div>
      }
      
    </div>
  )
}

export default FinerioComponent
