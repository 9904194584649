import { useSelector } from "react-redux"
import moment from "moment"
import useTransactionDates from "hooks/useTransactionDates"
import { months } from "utils/helpers/app.helpers"
import { DropdownItem } from "models/app.models"
import { RootState } from "store/reducers"
import UelDropdown from "components/FormComponents/UelDropdown/UelDropdown"
import "./style.scss"

moment.updateLocale("es-MX", { months: months })
export interface GpsFiltersProps {
  dropdownOptionsFirst: DropdownItem[]
  dropdownOptionsSecond: DropdownItem[]
  changeActiveTimeframeFilter: (value: string) => void
  changeActiveAccountFilter: (value: string) => void
}

const GpsFilters = ({
  dropdownOptionsFirst,
  dropdownOptionsSecond,
  changeActiveTimeframeFilter,
  changeActiveAccountFilter,
}: GpsFiltersProps) => {
  const timeframe = useSelector(
    (state: RootState) => state.mygps.globalMyGpsTimeframe
  )

  const transactionRangeState = useSelector(
    (state: RootState) => state.mygps.transactionsRange
  )

  const { mainTimeRangeIndex } = useTransactionDates(
    timeframe,
    transactionRangeState.range?.recentTransactionDate!
  )

  const accountIndex = useSelector(
    (state: RootState) => state.mygps.accountIndex
  )
  const monthlyTimeFilters = useSelector(
    (state: RootState) => state.mygps.monthlyTimeFilters
  )

  return (
    <div className="component-gps-filters">
      <UelDropdown
        data={dropdownOptionsFirst!}
        label="screens.mygps.filters.timeframe.label"
        defaultValue={
          monthlyTimeFilters
            ? dropdownOptionsFirst![mainTimeRangeIndex!]?.value!
            : dropdownOptionsFirst![0].value
        }
        onChange={changeActiveTimeframeFilter}
        alterStyle
      />
      <UelDropdown
        data={dropdownOptionsSecond}
        label="screens.mygps.filters.account.label"
        defaultValue={
          accountIndex
            ? dropdownOptionsSecond[accountIndex!]?.value!
            : dropdownOptionsSecond[0].value
        }
        onChange={changeActiveAccountFilter}
        alterStyle
      />
    </div>
  )
}

export default GpsFilters
