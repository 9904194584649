import AccountAddedModal from "components/Modals/AccountAddedModal/AccountAddedModal"
import AccountCreatedModal from "components/Modals/AccountCreatedModal/AccountCreatedModal"
import FinerioModal from "components/Modals/FinerioModal/FinerioModal"
import UelFancyModal from "components/Shared/UelFancyModal/UelFancyModal"
import UelHelpModal from "components/Shared/UelHelpModal/UelHelpModal"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  setAccountAlreadyCreatedModalAction,
  setUserLogout,
  setWrongCredentialsModal,
} from "store/Auth/auth.actions"
import { RootState } from "store/reducers"
import {
  setAccountAddedModal,
  setAccountCreatedModal,
  setCreditBuroAccessErrorModal,
  setFinerioSignUpErrorModal,
  setFinerioSignUpNoAccountModal,
} from "store/SignUp/signup.actions"
import { setShowFinerioModal, showHelpModal } from "store/Utils/utils.actions"
import { ROUTES } from "utils/helpers/app.helpers"

const ModalListener = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const showAccountAdded = useSelector(
    (state: RootState) => state.signup.accountAddedModal
  )
  const showAccountCreated = useSelector(
    (state: RootState) => state.signup.accountCreatedModal
  )
  const isFinerioErrorModal = useSelector(
    (state: RootState) => state.signup.finerio.isFinerioErrorModal
  )
  const isFinerioNoAccountModal = useSelector(
    (state: RootState) => state.signup.finerio.isFinerioNoAccountModal
  )
  const show = useSelector((state: RootState) => state.utils.showHelpModal)
  const isFinerioInfoModal = useSelector(
    (state: RootState) => state.utils.showFinerioModal
  )
  const showWrongCredentialsModal = useSelector(
    (state: RootState) => state.auth.wrongCredentialsModal
  )
  const accountAlreadyCreatedModal = useSelector((state:RootState) => state.auth.accountAlreadyCreatedModal)
  const showCreditBuroAccessErrorModal = useSelector((state:RootState) => state.signup.creditBuroAccessErrorModal)

  const hideCreditBuroAccessErrorModal = useCallback(() => {
    dispatch(setCreditBuroAccessErrorModal(false))
  }, [dispatch])
  
  const hideWrongCredentialsModal = useCallback(() => {
    dispatch(setWrongCredentialsModal(false))
  }, [dispatch])

  const showAccountAddedLinkOnClick = useCallback(() => {
    navigate(ROUTES.mygps, { replace: true })
    dispatch(setAccountAddedModal(false))
  }, [dispatch, navigate])

  const showAccountCreatedLinkOnClick = useCallback(() => {
    dispatch(setAccountCreatedModal(false))
  }, [dispatch])

  const handleCloseFinerioErrorModal = useCallback(() => {
    dispatch(setFinerioSignUpErrorModal(false))
  }, [dispatch])

  const handleCloseFinerioNoAccountModal = useCallback(() => {
    dispatch(setFinerioSignUpNoAccountModal(false))
  }, [dispatch])

  const handleExitFinerioNoAccountModal = useCallback(() => {
    dispatch(setFinerioSignUpNoAccountModal(false))
    dispatch(setUserLogout())
  }, [dispatch])

  const handleHelpModal = useCallback(() => {
    //TODO: Here you can add more functionality for tryAgain
    dispatch(showHelpModal(!show))
  }, [dispatch, show])

  const handleFinerioInfoModal = useCallback(() => {
    dispatch(setShowFinerioModal(false))
  }, [dispatch])

  const handleAccountAlreadyCreated = useCallback(()=>{
    dispatch(setAccountAlreadyCreatedModalAction(!accountAlreadyCreatedModal))
  },[dispatch, accountAlreadyCreatedModal])
  return (
    <>
      <AccountAddedModal
        opened={showAccountAdded}
        onClose={showAccountAddedLinkOnClick}
        linkButtonOnClick={showAccountAddedLinkOnClick}
      />

      <AccountCreatedModal
        className="account-created-modal"
        opened={showAccountCreated}
        onClose={showAccountCreatedLinkOnClick}
        linkButtonOnClick={showAccountCreatedLinkOnClick}
      />

      <UelFancyModal
        className="cant-verify-bank-account-modal"
        title="modal.finerio.error"
        description="modal.finerio.error.description"
        linkButtonTitle="modal.finerio.error.link"
        opened={isFinerioErrorModal}
        onClose={handleCloseFinerioErrorModal}
        linkButtonOnClick={handleCloseFinerioErrorModal}
      />

      <UelFancyModal
        title="modal.finerio.noAccount"
        description="modal.finerio.noAccount.description"
        linkButtonTitle="modal.finerio.noAccount.link"
        opened={isFinerioNoAccountModal}
        onClose={handleCloseFinerioNoAccountModal}
        linkButtonOnClick={handleCloseFinerioNoAccountModal}
        withButton={handleExitFinerioNoAccountModal}
      />

      <UelHelpModal show={show} onClick={handleHelpModal} />

      <FinerioModal
        opened={isFinerioInfoModal}
        onClose={handleFinerioInfoModal}
        children={undefined}
      />

      <UelFancyModal
        className="wrong-credentials-modal"
        title="screens.login.modal.wrongcredentials.title"
        description="screens.login.modal.wrongcredentials.description"
        linkButtonTitle="screens.login.modal.wrongcredentials.link"
        opened={showWrongCredentialsModal}
        onClose={hideWrongCredentialsModal}
        linkButtonOnClick={hideWrongCredentialsModal}
      />

      <UelFancyModal
        title="screens.login.modal.already.created.title"
        description="screens.login.modal.already.created.description"
        linkButtonTitle="screens.login.modal.wrongcredentials.link"
        opened={accountAlreadyCreatedModal}
        onClose={handleAccountAlreadyCreated}
        linkButtonOnClick={handleAccountAlreadyCreated}
      />

      <UelFancyModal
        className="wrong-credentials-modal"
        title="screens.signup.creditscore.modal.accesserror.title"
        description="screens.signup.creditscore.modal.accesserror.description"
        linkButtonTitle="screens.signup.creditscore.modal.accesserror.link"
        opened={showCreditBuroAccessErrorModal}
        onClose={hideCreditBuroAccessErrorModal}
        linkButtonOnClick={hideCreditBuroAccessErrorModal}
      />
    </>
  )
}

export default ModalListener
