import React from 'react';
import { Checkbox } from '@mantine/core';
import { useForm } from 'react-hook-form';
import UelButton from "components/Shared/UelButton/UelButton";
import UelBackButton from "components/Shared/UelBackButton/UelBackButton";
import UelLinkButton from "components/Shared/UelLinkButton/UelLinkButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setCreditBuilderStep } from 'store/SignUp/signup.actions';
import { SignUpCreditBuilderSteps } from "utils/helpers/signup.helpers";
import "./styles.scss"
import UelCheckBox from 'components/FormComponents/UelCheckBox/UelCheckBox';
import withCheckboxController from "utils/hocs/withCheckboxController"
import { Carousel } from "@mantine/carousel"
import { RootState } from "store/reducers"
import axios from 'axios'

const ControllerCheckBox = withCheckboxController(UelCheckBox)

const CreditBuilderPlanContainer = () => {
  const { control, watch } = useForm();  // get control object
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { idExterno } = useSelector((state: RootState) => ({
    idExterno: state.auth.authentication?.data?.id || state.signup.registerUser.data?.id
  }));

  const goForward = async (plan: string) => {
    const url = process.env.REACT_APP_CB_LAMBDA_KUSPIT_URL || '';
  
    try {
      const response = await axios.post(
        url,
        { idExterno, plan },
        {
          headers: {
            'Content-Type': 'application/json',
            'password': process.env.REACT_APP_LAMBDAS_PASS || '',
          },
        }
      );
  
      if (response.status === 200) {
        console.log(response.data); 
        
        dispatch(setCreditBuilderStep(SignUpCreditBuilderSteps.personal));
      } else {
        console.error('Unexpected response:', response.status, response.data); 
        alert('Error en servidor. Intente nuevamente')
      }
  
    } catch (error) {
      console.error('Error sending data to Lambda:', error);
      
    }
  
    navigate('/ahorro');
  };
  
  

  const goBackWithLinkButton = () => {
    // Dispatch action to update credit builder step
    dispatch(setCreditBuilderStep(SignUpCreditBuilderSteps.description));

    // Navigate to /ahorro
    navigate('/ahorro');
  };

  const cancelCB = () => {
    navigate('/')
  }

  const checkChange = () => {

  }
  // Watch checkbox value
  const checkboxValue = watch('checkbox', false);

  return (
    <div className="home-container-pl">
        <div className="link-button-plan">
          <UelBackButton
              onClick={goBackWithLinkButton}
          />
        </div>
        <h1>Descripción de plan</h1> <br />
        <div className="message-part-pl">
        <Carousel         
            className="cb-plan-carousel"
            withIndicators
            slideSize="100%"
            draggable={true}
            speed={3}
            initialSlide={0}
            controlsOffset="xs"
            controlSize={20}
            align="center"
            withControls={false}
            slideGap={"md"}
            styles={{
              indicator: {
                border: "1px solid #1E335C",
                "&[data-active]": {
                  background: "#1E335C",
                },
              },
            }}>
          <Carousel.Slide className='custom-slide-plan'>
              <h1 className='header-pl'>Energiza Flexible</h1> <br />
              <h2 className='plan-slogan'>Mejora tu buró al menor costo</h2>
              <h1 className='plan-price-hd'>$ <span className='plan-price'>115</span> / mes</h1>
              <div className="plan-details">
                <div className="plan-terms">
                  <div className="term-item">
                    <p>Reporte positivo a buró por $1,061 pesos</p>
                  </div>
                  <div className="term-item">
                    <p>Cashback por última mensualidad</p>
                  </div>
                  <div className="term-item">
                    <p>Apertura de cuenta de inversión</p>
                  </div>
                  <div className="term-item">
                    <p>Sesión con coach de finanzas personales</p>
                  </div>
                </div>
                <div className="plan-values">
                  <div className="value-item">
                    <img src="icons/check.svg" alt="Check Icon" height="30px" width="30px" />
                  </div>
                  <div className="value-item">
                    <img src="icons/check.svg" alt="Check Icon" height="30px" width="30px" />
                  </div>
                  <div className="value-item">
                    <img src="icons/check.svg" alt="Check Icon" height="30px" width="30px" />
                  </div>
                  <div className="value-item">
                    <img src="icons/check.svg" alt="Check Icon" height="30px" width="30px" />
                  </div>
                </div>
              </div>
              <div className="button-container-pl">
                <UelButton
                    title={"btn.continue"}
                    onClick={() => goForward('5')}
                    variant="primary"
                    disabled={!checkboxValue}
                />
              </div>
          </Carousel.Slide>
          <Carousel.Slide className='custom-slide-plan'>
              <h1 className='header-pl'>Energiza Light</h1> <br />
              <h2 className='plan-slogan'>Mejora tu buró y recibe más cashback</h2>
              <h1 className='plan-price-hd'>$ <span className='plan-price'>145</span> / mes</h1>
              <div className="plan-details">
                <div className="plan-terms">
                  <div className="term-item">
                    <p>Reporte positivo a buró por $3,000 pesos</p>
                  </div>
                  <div className="term-item">
                    <p>Cashback de $220</p>
                  </div>
                  <div className="term-item">
                    <p>Apertura de cuenta de inversión</p>
                  </div>
                  <div className="term-item">
                    <p>Sesión con coach de finanzas personales</p>
                  </div>
                </div>
                <div className="plan-values">
                  <div className="value-item">
                    <img src="icons/check.svg" alt="Check Icon" height="30px" width="30px" />
                  </div>
                  <div className="value-item">
                    <img src="icons/check.svg" alt="Check Icon" height="30px" width="30px" />
                  </div>
                  <div className="value-item">
                    <img src="icons/check.svg" alt="Check Icon" height="30px" width="30px" />
                  </div>
                  <div className="value-item">
                    <img src="icons/check.svg" alt="Check Icon" height="30px" width="30px" />
                  </div>
                </div>
              </div>
              <div className="button-container-pl">
                <UelButton
                    title={"btn.continue"}
                    onClick={() => goForward('6')}
                    variant="primary"
                    disabled={!checkboxValue}
                />
              </div>
          </Carousel.Slide>
          <Carousel.Slide className='custom-slide-plan'>
              <h1 className='header-pl'>Energiza Total</h1> <br />
              <h2 className='plan-slogan'>Potencia tu buró y maximiza cashback</h2>
              <h1 className='plan-price-hd'>$ <span className='plan-price'>250</span> / mes</h1>
              <div className="plan-details">
                <div className="plan-terms">
                  <div className="term-item">
                    <p>Reporte positivo a buró por $9,789 pesos</p>
                  </div>
                  <div className="term-item">
                    <p>Cashback de $718</p>
                  </div>
                  <div className="term-item">
                    <p>Apertura de cuenta de inversión</p>
                  </div>
                  <div className="term-item">
                    <p>Sesión con coach de finanzas personales</p>
                  </div>
                </div>
                <div className="plan-values">
                  <div className="value-item">
                    <img src="icons/check.svg" alt="Check Icon" height="30px" width="30px" />
                  </div>
                  <div className="value-item">
                    <img src="icons/check.svg" alt="Check Icon" height="30px" width="30px" />
                  </div>
                  <div className="value-item">
                    <img src="icons/check.svg" alt="Check Icon" height="30px" width="30px" />
                  </div>
                  <div className="value-item">
                    <img src="icons/check.svg" alt="Check Icon" height="30px" width="30px" />
                  </div>
                </div>
              </div>

              {/* <div style={{fontFamily: "Jakarta-regular", textAlign:'left', alignItems:'left'}}>
                <ul style={{listStyleType:'disc', paddingLeft:'20px'}}>
                  <li>Sesión de asesoría financiera</li>
                  <li style={{paddingTop:'3px', paddingBottom:'4px'}}>Apertura de cuenta de inversión</li>
                </ul>
              </div> */}
              <div className="button-container-pl">
                <UelButton
                    title={"btn.continue"}
                    onClick={() => goForward('4')}
                    variant="primary"
                    disabled={!checkboxValue}
                />
              </div>
          </Carousel.Slide>
        </Carousel>
        <div className="terms-and-button-container">
            <div className="terms-container-pl">
              <ControllerCheckBox
                  control={control}
                  name="checkbox"
                  labelId="cb.plan.chbx"
                  defaultChecked={false}
                />
            </div>
        </div>
        <div className="link-button-plan-quit">
                  <UelLinkButton
                      onClick={cancelCB}
                      color="secondary"
                  >
                      {"No quiero mejorar mi buró"}
                  </UelLinkButton>
        </div>
        </div>
    </div>
  );
};

export default CreditBuilderPlanContainer;