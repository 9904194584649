import { useEffect, useState } from "react"
declare global {
  interface Window {
    deferredPrompt: any
    opera: any
    MSStream: any
  }
}
const useInstallDialogApp = () => {
  const [openDialogInstall, setOpenDialogInstall] = useState<boolean>(false)
  useEffect(() => {
    const readyToInstall = (event: any) => {
      event.preventDefault()
      window.deferredPrompt = event
      setOpenDialogInstall(true)
    }

    const appWasInstalled = () => {
      setOpenDialogInstall(false)
      window.deferredPrompt = null
    }
    let userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (
      /android/i.test(userAgent) ||
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      window.addEventListener("beforeinstallprompt", readyToInstall)
      window.addEventListener("appinstalled", appWasInstalled)
    }
    return () => {
      window.removeEventListener("beforeinstallprompt", readyToInstall)
      window.removeEventListener("appinstalled", appWasInstalled)
    }
  }, [])
  return {
    openDialogInstall,
    setOpenDialogInstall,
  }
}

export default useInstallDialogApp
