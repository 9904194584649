import UelCardBankAccount from "components/Shared/UelCardBankAccount/UelCardBankAccount"
import { BankAccountI } from "models/mygps.models"
import React from "react"
import GpsAddbutton from "../GpsAddButton/GpsAddButton"
import { Carousel } from "@mantine/carousel"
import { Text } from "@mantine/core"
import "./styles.scss"
import { FormattedMessage } from "react-intl"
import UelLoader from "components/Shared/UelLoader/UelLoader"
export interface AddNewAccountProp {
  bankAccounts: BankAccountI[] | null
  onClickAddAccount: () => void
  onClickAccount: (bankAccount: BankAccountI) => void
  loading: boolean
}
const AddNewBankAccount = ({
  bankAccounts,
  onClickAccount,
  onClickAddAccount,
  loading,
}: AddNewAccountProp) => {
  const sliders =
    bankAccounts &&
    bankAccounts.map((bankAccount, key) => {
      return (
        <Carousel.Slide key={key}>
          <div className="card-bank-container">
            <UelCardBankAccount
              bankAccount={bankAccount}
              onClick={(bankAccountSelected) =>
                onClickAccount(bankAccountSelected)
              }
            />
          </div>
        </Carousel.Slide>
      )
    })

  return (
    <div className="principal-add-new-bank-account-container">
      {loading ? (
        <UelLoader size="md" />
      ) : (
        <>
          <Text className="title-text">
            <FormattedMessage id="screens.mygps.myaccounts" />
          </Text>
          <div className="button-and-accounts-container">
            <div className="gps-add-button-container">
              <GpsAddbutton onClick={onClickAddAccount} />
            </div>
            <Carousel align={"start"} withControls={false} slideSize={0}>
              {sliders}
            </Carousel>
          </div>
        </>
      )}
    </div>
  )
}

export default AddNewBankAccount
