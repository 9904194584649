import FinerioComponent from "components/SignUp/FinerioComponent/FinerioComponent";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store/reducers";
import { AppStates, ROUTES } from "utils/helpers/app.helpers";
import withProtectedRoute from "utils/hocs/withProtectedRoute";

const AddBankAccountScreen = () => {
  const userEmail = useSelector((state: RootState) => state.auth.authentication.data.email);
  const finerioUUID = useSelector((state: RootState) => state.auth.authentication.data.finerioUUID);
  const navigate = useNavigate();

  const handleContinueLater = useCallback(() => {
    navigate(ROUTES.mygps, { replace: true })
  }, [navigate])

  return (
    <div className="add-bankaccount-screen">
      <FinerioComponent 
        customerName={userEmail}
        customerId={finerioUUID ? undefined : undefined}
        continueLater={handleContinueLater}
      />
    </div>
  )
}

export default withProtectedRoute(AddBankAccountScreen, AppStates.homeState);