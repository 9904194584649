import { MyGpsTimeframes } from "utils/helpers/mygps.helpers";
import GpsButton from "components/MyGps/GpsButton/GpsButton";
import "./style.scss";
import useSelectTimeFrame from "hooks/useSelectTimeFrame";

const SelectTimeframeContainer = () => {
  const selectTimeFrame = useSelectTimeFrame();

  return  (
    <div className="selecttimeframe-container">
      <GpsButton
        title="screens.selecttimeframe.gpsbutton.b1"
        onClick={() => selectTimeFrame(MyGpsTimeframes.monthly)}
      />
      <GpsButton
        title="screens.selecttimeframe.gpsbutton.b2"
        onClick={() => selectTimeFrame(MyGpsTimeframes.quarterly)}
      />
    </div>
  );
}

export default SelectTimeframeContainer;
