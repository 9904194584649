import { useIntl } from "react-intl"
import { UelColorsModel } from "models/app.models"
import "./style.scss"

type LoaderSize = "xs" | "sm" | "md" | "lg" | "xl"

export interface UelLoaderProps {
  color?: UelColorsModel
  size?: LoaderSize
}

const loaderSizesValues = {
  xs: 30,
  sm: 40,
  md: 60,
  lg: 120,
  xl: 200,
}

const UelLoader = (props: UelLoaderProps) => {
  const { color = "primary", size = "md" } = props
  const dimens = loaderSizesValues[size]
  const { formatMessage } = useIntl()
  return (
    <img
      className={`uel-loader ${color}`}
      src="/images/uellbee_loading.gif"
      alt={formatMessage({ id: "alt.loader" })}
      {...{ height: dimens, width: dimens }}
    />
  )
}

export default UelLoader
