import { IntlString } from "models/app.models"
import { FormattedMessage } from "react-intl"
import "./style.scss"

interface UelCategoryTipProps {
  text: IntlString
  percentage: string
  isExpense: boolean
  tip: string
}

const UelCategoryTip = (props: UelCategoryTipProps) => {
  const { text, percentage, isExpense, tip } = props

  return (
    <div className="component-category-tip">
      {percentage !== "0.00" && (
        <FormattedMessage
          id={text}
          values={{
            percentage,
            category: isExpense ? "gastos" : "ingresos",
          }}
        />
      )}
      <FormattedMessage id={tip} />
    </div>
  )
}

export default UelCategoryTip
