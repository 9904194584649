import UelBalanceCard from "components/Shared/UelBalanceCard/UelBalanceCard"
import { useSelector } from "react-redux"
import { RootState } from "store/reducers"
import { ExpensesDataExample } from "utils/dataExamples/MyGpsExamples"
import "./styles.scss"

const BalanceCards = () => {

  const expensesState = useSelector((state: RootState) => state.mygps.expenses)

  return (
    <div className="balance-cards-container">
      <div className="income-container">
        <UelBalanceCard figure={expensesState?.expenses?.totalIncome! ?? ExpensesDataExample.totalIncome} />
      </div>
      <div className="expense-container">
        <UelBalanceCard figure={expensesState?.expenses?.totalExpenses! ?? ExpensesDataExample.totalExpenses} />
      </div>
    </div>
  )
}

export default BalanceCards
