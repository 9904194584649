import { ChartRiskLevels } from 'models/mygps.models';
import { MyGpsTimeframes } from 'utils/helpers/mygps.helpers';
import {
  SET_GLOBAL_MYGPS_TIMEFRAME, 
  SET_MYGPS_ACTIVE_EXPENSE_CATEGORY, 
  SET_MYGPS_BANK_ACCOUNT_INDEX, 
  SET_MYGPS_EXPENSES_ERROR, 
  SET_MYGPS_EXPENSES_INFORMATION, 
  SET_MYGPS_EXPENSES_LOADING, 
  SET_MYGPS_MONTHLY_TIME_FILTERS, 
  SET_MYGPS_NEW_BANKACCOUNT_ERROR, 
  SET_MYGPS_NEW_BANKACCOUNT_REQUEST, 
  SET_MYGPS_NEW_BANKACCOUNT_SUCCESS, 
  SET_MYGPS_QUARTERLY_TIME_FILTERS, 
  SET_MYGPS_RISK_LEVELS_ERROR, 
  SET_MYGPS_RISK_LEVELS_INFORMATION, 
  SET_MYGPS_RISK_LEVELS_LOADING,  
  SET_MYGPS_TRANSACTIONS_ERROR, 
  SET_MYGPS_TRANSACTIONS_INFORMATION, 
  SET_MYGPS_TRANSACTIONS_LOADING, 
  SET_MYGPS_TRANSACTIONS_RANGE_ERROR, 
  SET_MYGPS_TRANSACTIONS_RANGE_INFORMATION, 
  SET_MYGPS_TRANSACTIONS_RANGE_LOADING, 
  SET_MYGPS_USER_SCORE_ERROR, 
  SET_MYGPS_USER_SCORE_INFORMATION, 
  SET_MYGPS_USER_SCORE_LOADING,
  SET_MYGPS_WITH_FINERIOID
} from "./mygps.reducer";
import { Dispatch } from 'redux';
import { addGpsBankAccount, expensesService, getRiskLevelsService, getUserScoreService, transactionsService, transactionsTimeRangeService } from 'services/mygps.service';
import { ActiveExpenseCategory, ExpensesI, ExpensesServiceI, GpsTransactionCollectionI, RiskLevelsI, TimeFiltersI, TransactionsRangeI, TransactionsServiceI, UserScoreI } from 'models/mygps.models';
import { AxiosError } from 'axios';
import { setAccountAddedModal } from 'store/SignUp/signup.actions';
import { setFinerioService } from 'services/signup.service';

export const setGlobalMyGpsTimeframe = (timeframe: MyGpsTimeframes) => {
  return {
    type: SET_GLOBAL_MYGPS_TIMEFRAME,
    payload: timeframe
  }
}

export const setUserScoreInformation = (data: UserScoreI) => {
  return {
    type: SET_MYGPS_USER_SCORE_INFORMATION,
    payload: data,
  }
}

export const setUserScoreLoading = (data: boolean) => {
  return {
    type: SET_MYGPS_USER_SCORE_LOADING,
    payload: data,
  }
}

export const setUserScoreError = (data: boolean) => {
  return {
    type: SET_MYGPS_USER_SCORE_ERROR,
    payload: data,
  }
}

export const setRiskLevelsInformation = (data: RiskLevelsI) => {
  return {
    type: SET_MYGPS_RISK_LEVELS_INFORMATION,
    payload: data,
  }
}

export const setRiskLevelsLoading = (data: boolean) => {
  return {
    type: SET_MYGPS_RISK_LEVELS_LOADING,
    payload: data,
  }
}

export const setRiskLevelsError = (data: boolean) => {
  return {
    type: SET_MYGPS_RISK_LEVELS_ERROR,
    payload: data,
  }
}

export const setExpensesInformation = (data: ExpensesI | null) => {
  return {
    type: SET_MYGPS_EXPENSES_INFORMATION,
    payload: data,
  }
}

export const setExpensesLoading = (data: boolean) => {
  return {
    type: SET_MYGPS_EXPENSES_LOADING,
    payload: data,
  }
}

export const setExpensesError = (data: boolean) => {
  return {
    type: SET_MYGPS_EXPENSES_ERROR,
    payload: data,
  }
}

export const setActiveExpenseCategory = (category: ActiveExpenseCategory) => {
  return {
    type: SET_MYGPS_ACTIVE_EXPENSE_CATEGORY,
    payload: category
  }
}

export const setTransactionsInformation = (data: GpsTransactionCollectionI | null) => {
  return {
    type: SET_MYGPS_TRANSACTIONS_INFORMATION,
    payload: data,
  }
}

export const setTransactionsLoading = (data: boolean) => {
  return {
    type: SET_MYGPS_TRANSACTIONS_LOADING,
    payload: data,
  }
}

export const setTransactionsError = (data: boolean) => {
  return {
    type: SET_MYGPS_TRANSACTIONS_ERROR,
    payload: data,
  }
}

export const setAccountIndex = (data: number) => {
  return {
    type: SET_MYGPS_BANK_ACCOUNT_INDEX,
    payload: data
  }
}

export const setMonthlyTimeFilters = (data: TimeFiltersI) => {
  return {
    type: SET_MYGPS_MONTHLY_TIME_FILTERS,
    payload: data
  }
}

export const setQuarterlyTimeFilters = (data: TimeFiltersI) => {
  return {
    type: SET_MYGPS_QUARTERLY_TIME_FILTERS,
    payload: data
  }
}

export const setTransactionsRangeInformation = (data: TransactionsRangeI) => {
  return {
    type: SET_MYGPS_TRANSACTIONS_RANGE_INFORMATION,
    payload: data,
  }
}

export const setTransactionsRangeLoading = (data: boolean) => {
  return {
    type: SET_MYGPS_TRANSACTIONS_RANGE_LOADING,
    payload: data,
  }
}

export const setTransactionsRangeError = (data: boolean) => {
  return {
    type: SET_MYGPS_TRANSACTIONS_RANGE_ERROR,
    payload: data,
  }
}


export const setMyGpsNewBankAccountRequest = () => {
  return {
    type: SET_MYGPS_NEW_BANKACCOUNT_REQUEST
  }
}

export const setMyGpsNewBankAccountError = () => {
  return {
    type: SET_MYGPS_NEW_BANKACCOUNT_ERROR
  }
}

export const setMyGpsNewBankAccountSuccess = () => {
  return {
    type: SET_MYGPS_NEW_BANKACCOUNT_SUCCESS
  }
}

export const setMyGpsWithFInerioId = (withFinerioId: boolean) => {
  return {
    type: SET_MYGPS_WITH_FINERIOID,
    payload: withFinerioId
  }
}

export const getMyGpsUserScore = () => async (dispatch: Dispatch) => {
  dispatch(setUserScoreLoading(true))
  try {
    const { data } = await getUserScoreService()
    dispatch(setUserScoreInformation(data))
  } catch (error: any) {
    dispatch(setUserScoreError(true))
    if (error instanceof AxiosError
      && error?.response?.data?.error?.status === 400
      && error?.response?.data?.error?.message
      && error?.response?.data?.error?.message === "User does not have credit history") {
      const riskLevel: ChartRiskLevels = -1;
      dispatch(setUserScoreInformation({
        userId: -1,
        creditScore: 0,
        riskLevel,
      }))
    }
  } finally {
    dispatch(setUserScoreLoading(false))
  }
}

export const getMyGpsRiskLevels = () => async (dispatch: Dispatch) => {
  dispatch(setRiskLevelsLoading(true))
  try {
    const { data } = await getRiskLevelsService()
    dispatch(setRiskLevelsInformation(data))
  } catch (error: any) {
    dispatch(setRiskLevelsError(true))
  } finally {
    dispatch(setRiskLevelsLoading(false))
  }
}

export const getTransactionsRange = () => async (dispatch: Dispatch) => {
  dispatch(setTransactionsRangeLoading(true))

  try {
    const { data } = await transactionsTimeRangeService()
    dispatch(setTransactionsRangeInformation(data))
  } catch (error: any) {
    dispatch(setTransactionsRangeError(true))
  } finally {
    dispatch(setTransactionsRangeError(false))
  }
}

export const getMyGpsExpenses = ({
  startDate,
  endDate,
  accountId
}: ExpensesServiceI) => async (dispatch: Dispatch) => {
  dispatch(setExpensesLoading(true))
  try {
    const { data } = await expensesService({
      startDate,
      endDate,
      accountId
    })
    dispatch(setExpensesInformation(data))
  } catch (error: any) {
    dispatch(setExpensesError(true))
  } finally {
    dispatch(setExpensesLoading(false))
  }
}

export const getMyGpsTransactions = ({
  startDate,
  endDate,
  accountId,
  categoryId,
}:TransactionsServiceI) => async (dispatch: Dispatch) => {
  dispatch(setTransactionsLoading(true))
  try {
    const data  = await transactionsService({
      startDate,
      endDate,
      accountId,
      categoryId
    })
    dispatch(setTransactionsInformation(data))
  } catch (error: any) {
    dispatch(setTransactionsError(true))
    dispatch(setTransactionsInformation({
      data: {
        totalExpenses: 0,
        totalTransactions: 0,
        transactions:[]
      }
    }))
  } finally {
    dispatch(setTransactionsLoading(false))
  }
}

export const setMyGpsNewBankAccount = (
  credentialId: string,
  ) => async (dispatch: Dispatch) => {
  dispatch(setMyGpsNewBankAccountRequest())
  try {
    await addGpsBankAccount(credentialId)
    dispatch(setMyGpsNewBankAccountSuccess())
    dispatch(setAccountAddedModal(true))
  } catch {
    dispatch(setMyGpsNewBankAccountError())
  }
}

export const setMyFinerioIdSkipAccount = (
  customerId: string,
  handleError: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch(setMyGpsNewBankAccountSuccess())
    try {
      await setFinerioService(customerId)
    } catch {
      handleError()
    }
}