import moment from "moment";
import { months } from "./app.helpers";

moment.updateLocale('es-MX', { months : months});

export enum SignUpSteps {
  initialForm = 0,
  verifyEmail = 1,
  sendTelephone = 2,
  verifyTelephone = 3,
  userData = 4,
  emailCodeDeprecated = 5,
}

export enum SignUpPersonalDataSteps {
  personalData = 0,
  addressData = 1,
  scoreCreditData = 2,
  bankData = 3,
  done = 4
}

export enum SignUpCreditBuilderSteps {
  initial = 0,
  description = 1,
  plan = 2,
  info = 3,
  personal = 4,
  billing =5,
  kuspit = 6,
  kyc = 7,
  zapsign = 8,
  completed = 9
}

export enum BackendOnboardingSteps {
  registerPhone = "S0",
  personalInformation = "S1",
  addresInformation = "S2",
  creditScore = "S3",
  finerio = "S4"
}

export enum RecoverPasswordSteps {
  recoverInitial = 0,
  recoverCode = 1,
  recoverConfirmPassword = 2
}

export interface FirstTimeSyncI {
  firstTimeSincronization: boolean;
}

export interface UserFirstTimeSyncModel {
  isDone: boolean;
  isLoading: boolean;
  error: boolean;
}

export const contentTutorial = [
  {
    title: "tutorial.carousel.txt.title.ahorr",
    subtitle: "tutorial.carousel.txt.subtitle.ahorr",
    img: "/images/onboarding3.png",
  },
  {
    title: "tutorial.carousel.txt.title.reto",
    subtitle: "tutorial.carousel.txt.subtitle.reto",
    img: "/images/onboarding.png",
  },
  {
    title: "tutorial.carousel.txt.title.gps",
    subtitle: "tutorial.carousel.txt.subtitle.gps",
    img: "/images/onboarding2.png",
  },
  
];

export const getyYearsSel = () => {
  const currentYear = moment().year();
  let years = [];
  let startYear = 1920;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};

export const getyDaysSel = () => {
  const daysMonth = moment().year(1990).month(0).daysInMonth();
  let days = [];
  let day = 1;
  while (day <= daysMonth) {
    days.push(day++);
  }
  return days;
};