import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import useSelectTimeFrame from "hooks/useSelectTimeFrame"
import useGetAccountIds from "hooks/useGetAccountIds"
import { iosStyleMatch, ROUTES } from "utils/helpers/app.helpers"
import { MyGpsTimeframes } from "utils/helpers/mygps.helpers"
import { BankAccountI } from "models/mygps.models"
import { RootState } from "store/reducers"
import { getMyGpsUserScore, setAccountIndex } from "store/MyGps/mygps.actions"
import UelLoader from "components/Shared/UelLoader/UelLoader"
import UelGeneralTip from "components/Shared/UelGeneralTip/UelGeneralTip"
import AddNewBankAccount from "components/MyGps/AddNewBankAccount/AddNewBankAccount"
import CreditScoreCard from "components/MyGps/CreditScoreCard/CreditScoreCard"
import GpsButton from "components/MyGps/GpsButton/GpsButton"
import "./style.scss"

const MyGpsContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { accountIdsData, loading } = useGetAccountIds()

  const userScore = useSelector((state: RootState) => state.mygps.userScore)
  const withFinerioUUID = useSelector(
    (state: RootState) => state.mygps.withFinerioUUID
  )

  const [accountsLoaded, setAccountsLoaded] = useState(false)

  const addNewAccount = useCallback(() => {
    navigate(ROUTES.newBankAccount, { replace: true })
  }, [navigate])

  const onClickAccount = useCallback(
    (account: BankAccountI) => {
      const accountIndex = accountIdsData
        ?.map((item) => item.number)
        .indexOf(account.number)
      dispatch(setAccountIndex(accountIndex!))
      navigate(ROUTES.mygpsTimeframeStats)
    },
    [accountIdsData, dispatch, navigate]
  )

  useEffect(() => {
    userScore?.score === null && dispatch(getMyGpsUserScore())
  }, [userScore?.score, dispatch])

  useEffect(() => {
    loading === false && accountIdsData !== null && setAccountsLoaded(true)
  }, [loading, accountIdsData])

  const selectTimeFrame = useSelectTimeFrame()

  return (
    <div className={`mygps-container ${iosStyleMatch ? "ios" : ""}`}>
      {withFinerioUUID === false && (
        <UelGeneralTip
          message="uel.addAccount.tip"
          actionOnClick={addNewAccount}
        />
      )}
      <GpsButton
        disabled={withFinerioUUID ? false : true}
        title="screens.mygps.header.subtitle.phone"
        height={115}
        label="screens.mygps.button.mymoney.label"
        onClick={() => navigate(ROUTES.mygpsSelecttimeframe)}
        dropdownItems={[
          {
            label: "screens.selecttimeframe.gpsbutton.b1",
            onClick: () => selectTimeFrame(MyGpsTimeframes.monthly),
          },
          {
            label: "screens.selecttimeframe.gpsbutton.b2",
            onClick: () => selectTimeFrame(MyGpsTimeframes.quarterly),
          },
        ]}
      />
      {userScore?.score === null ? (
        <UelLoader size="lg" />
      ) : (
        <>
          <CreditScoreCard />
          <AddNewBankAccount
            onClickAddAccount={addNewAccount}
            onClickAccount={onClickAccount}
            bankAccounts={accountIdsData}
            loading={!accountsLoaded}
          />
        </>
      )}
    </div>
  )
}

export default MyGpsContainer
