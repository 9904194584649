import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers';
import thunk from "redux-thunk";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

const persistConfig = {
	key: "uel-pwa",
	storage,
	autoMergeLevel2,
	version: 1
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

//export const Store = createStore(reducers, {}, applyMiddleware(thunk));
export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
export const persistor = persistStore(store);
