import { combineReducers, Action } from "redux";
import AuthReducer from "./Auth/auth.reducer";
import MyGpsReducer from "./MyGps/mygps.reducer";
import SignUpReducer from "./SignUp/signup.reducer";
import UserReducer from "./User/user.reducer";
import UtilsReducer from "./Utils/utils.reducer";

export const reducers = combineReducers({
  auth: AuthReducer,
  signup: SignUpReducer,
  mygps: MyGpsReducer,
  utils: UtilsReducer,
  user: UserReducer
});

export type RootState = ReturnType<typeof reducers>;

const rootReducer = (state: RootState | undefined, action: Action) => {
  // TODO: ADD action or process to manage logout and clean state
  if (action.type === "SET_USER_LOGOUT") {
    state = {} as RootState;
    localStorage.removeItem("renderCount")
  }
  return reducers(state, action);
}

export default rootReducer;
