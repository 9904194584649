import { Dialog } from "@mantine/core"
import { FormattedMessage } from "react-intl"
import UelButton from "../UelButton/UelButton"
import "./style.scss"

interface UelInstallButtonProps {
  onCloseInstall: () => void;
  open: boolean;
}
const UelInstallButton = ({ onCloseInstall, open }: UelInstallButtonProps) => {
  const installPWA = async()=>{
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      return;
    }
  promptEvent.prompt();
  await promptEvent.userChoice;
  window.deferredPrompt = null;
}
  return (
    <Dialog
      opened={open}
      withCloseButton
      onClose={onCloseInstall}
      size="md"
      radius="md"
      transition={"slide-up"}
    >
      <div className="container-install">
        <div className="description-install">
          <span><FormattedMessage id="btn.component.install.app.title"/></span>
          <span>
            <FormattedMessage id="btn.component.decription.install.app" />
          </span>
        </div>
        <div className="btn-install">
          <UelButton
            title={"btn.component.install.app"}
            onClick={installPWA}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default UelInstallButton
