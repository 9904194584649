import React from 'react';
import ReactDOM from 'react-dom/client';
import './theme/index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { IntlProvider } from 'react-intl';
import messages from './i18n/reactIntlMessages-es-MX.json';
import { Provider } from 'react-redux';
import  { persistor, store } from './store/store';
import moment from 'moment';
import 'moment/locale/es';
import { months } from './utils/helpers/app.helpers';
import { PersistGate } from 'redux-persist/integration/react';

moment.updateLocale('es-MX', { months : months});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <IntlProvider messages={messages} onError={()=>{}} locale="es-MX">
      <Provider store={store}>        
        <PersistGate persistor={persistor} loading={null}>
          <App />          
        </PersistGate>        
      </Provider>      
    </IntlProvider>    
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
