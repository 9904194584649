import { Carousel } from '@mantine/carousel';
import UelButton from "components/Shared/UelButton/UelButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setCreditBuilderStep } from 'store/SignUp/signup.actions';
import { SignUpCreditBuilderSteps } from "utils/helpers/signup.helpers";
import "./styles.scss"

const CreditBuilderKuspitContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch(); 

  const goForward = () => {

  };


  return (
    <div className="home-container-cb">
      <Carousel className="cb-description-carousel"
        withControls
        withIndicators
        slideSize="100%"
        draggable={true}
        speed={3}
        initialSlide={0}
        controlsOffset="xl"
        controlSize={20}
        align='center'

      >
        <Carousel.Slide className="custom-slide">
          <div className="message-part-cb">
            <h2>Kuspit Description</h2>
          </div>
        </Carousel.Slide>
        <Carousel.Slide className="custom-slide">
          <div className="message-part-cb">
            <h2>Your Message Part 2 Here</h2>
          </div>
        </Carousel.Slide>
        <Carousel.Slide className="custom-slide">
          <div className="message-part-cb">
            <h2>Your Message Part 3 Here</h2>
          </div>
        </Carousel.Slide>
      </Carousel>
      <div className="button-container-cb">
        <UelButton
          title={"dashboard.cb.msg"}
          onClick={goForward}
          variant="primary"
          disabled={false}
        />
      </div>
    </div>
  );
};
export default CreditBuilderKuspitContainer;