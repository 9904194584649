import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import UelLinkButton from "components/Shared/UelLinkButton/UelLinkButton";
import UelBackButton from "components/Shared/UelBackButton/UelBackButton";
import UelButton from "components/Shared/UelButton/UelButton"
import UelTextInput from "components/FormComponents/UelTextInput/UelTextInput"
import UelDropdown from "components/FormComponents/UelDropdown/UelDropdown"
import UelCheckBox from "components/FormComponents/UelCheckBox/UelCheckBox"
import withTextInputController from "utils/hocs/withControllerInput"
import withDropdownController from "utils/hocs/withDropdownController"
import withCheckboxController from "utils/hocs/withCheckboxController"
import "./styles.scss"
import { FormattedMessage } from 'react-intl';
import { SignUpCreditBuilderSteps } from "utils/helpers/signup.helpers";
import { setCreditBuilderStep } from 'store/SignUp/signup.actions';
import { RootState } from "store/reducers";
import * as yup from 'yup';
import LoadingScreen from "screens/LoadingScreen/LoadingScreen"


const ControllerTextInput = withTextInputController(UelTextInput)
const ControllerDropdown = withDropdownController(UelDropdown)
const ControllerCheckBox = withCheckboxController(UelCheckBox)

interface PersonalInfoFormModel {
    nombre: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
    telCelular: string;
    fechaNacimiento: string;
    rfc: string;
    idPaisDom: string;
    idEstadoDom: string;
    idDelegacion: string;
    idColonia: string;
    cp: string;
    calle: string;
    noExt: string;
    // idPaisNacimiento: string;
    // idEstadoNacimiento: string;
    sexo: string;
  }

const CreditBuilderPersonalInfoContainer = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isLoaded, setIsLoaded] = useState(false);
  const [formData, setFormData] = useState<PersonalInfoFormModel | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const url = process.env.REACT_APP_CB_LAMBDA_KUSPIT_URL as string;
  const location = useLocation();

  // Fetch data
  useEffect(() => {
      const fetchData = async () => {
          const data = await axios.post(url, { idExterno, fetchPersonal: true }, {
              headers: {
                  'Content-Type': 'application/json',
                  'password': process.env.REACT_APP_LAMBDAS_PASS || ''
              },
          });
      
          console.log(`Response from fetchPersonalInfo: ${JSON.stringify(data.data)}`);
          
          Object.keys(data.data).forEach((key) => {
              if (data.data[key]) {
                  setValue(key as keyof PersonalInfoFormModel, data.data[key]) // Set field values using setValue
              }
          });
  
          setIsLoaded(true);
      };
      
      fetchData();
  }, []);

  const methods = useForm<PersonalInfoFormModel>({
  });

  const { handleSubmit, formState, control, resetField, watch, setValue } =
    methods
  const { errors } = formState



  const { idExterno } = useSelector((state: RootState) => ({
    idExterno: state.auth.authentication?.data?.id || state.signup.registerUser.data?.id
  }));

  const submitInfo = (data: PersonalInfoFormModel) => {
    console.log(data,errors);
    const updatedData = {
        ...data,
        idExterno: idExterno
      };
    postToLambda(updatedData);
    }

  const postToLambda = async (data: PersonalInfoFormModel) => {
    console.log("Calling postToLambda");
    if (!url) {
        throw new Error('REACT_APP_CB_LAMBDA_KUSPIT_URL is not set in .env file');
    }
    
    const response = await axios.post(url, {...data}, {
      headers: {
        'Content-Type': 'application/json',
        'password': process.env.REACT_APP_LAMBDAS_PASS || ''
      },
    });
    dispatch(setCreditBuilderStep(SignUpCreditBuilderSteps.info)); 

    console.log(`Response from postToLambda: ${JSON.stringify(response.data)}`);
    return response;
  };

  if (!isLoaded) {
    // If data has not been loaded yet, render the LoadingScreen
    return <LoadingScreen />;
  }

  const goBackWithLinkButton = () => {
    // Dispatch action to update credit builder step
    dispatch(setCreditBuilderStep(SignUpCreditBuilderSteps.plan));

    // Navigate to /ahorro
    navigate('/ahorro');
  };
  
  const cancelCB = () => {
    navigate('/')
  }

  return (
    <div className="form-personal-info">
      <div className="link-button-personal">
                <UelBackButton
                    onClick={goBackWithLinkButton}
                />
        </div>
      <div>
        <h2 className="datos-personales-cb-head">Información personal</h2>
        <h3 className="datos-personales-cb-head3">Por favor confirma que los siguientes datos sean correctos</h3>
      </div>
      <FormProvider {...methods}>
        <form className="form-container" onSubmit={handleSubmit(submitInfo)}>
            <ControllerTextInput
            control={control}
            name="nombre"
            label="Nombres"
            placeholder="Nombre(s)"
            isDisabled={true}
            errorText={errors.nombre?.message && errors.nombre?.message}
          />
            <ControllerTextInput
            control={control}
            name="apellidoPaterno"
            label="Apellido paterno"
            placeholder="Apellido paterno"
            isDisabled={true}
            errorText={errors.apellidoPaterno?.message && errors.apellidoPaterno?.message}
            />
            <ControllerTextInput
            control={control}
            name="apellidoMaterno"
            label="Apellido materno"
            placeholder="Apellido materno"
            isDisabled={true}
            errorText={errors.apellidoMaterno?.message && errors.apellidoMaterno?.message}
            />
            <ControllerTextInput
            control={control}
            name="telCelular"
            label="Teléfono celular"
            placeholder="Teléfono celular"
            isDisabled={true}
            errorText={errors.telCelular?.message && errors.telCelular?.message}
            />
            <ControllerTextInput
            control={control}
            name="fechaNacimiento"
            label="Fecha de nacimiento"
            placeholder="Fecha de nacimiento"
            isDisabled={true}
            errorText={errors.fechaNacimiento?.message && errors.fechaNacimiento?.message}
            />
            <ControllerTextInput
            control={control}
            name="sexo"
            label="Sexo"
            placeholder="Sexo"
            isDisabled={true}
            errorText={errors.sexo?.message && errors.sexo?.message}
            />
            <ControllerTextInput
            control={control}
            name="rfc"
            isRequired
            label="RFC"
            placeholder="RFC"
            isDisabled={true}
            errorText={errors.rfc?.message && errors.rfc?.message}
            />
            <ControllerTextInput
            control={control}
            name="calle"
            label="Calle"
            placeholder="Calle"
            isDisabled={true}
            errorText={errors.calle?.message && errors.calle?.message}
            />
            <ControllerTextInput
            control={control}
            name="noExt"
            label="Número exterior"
            placeholder="Número exterior"
            isDisabled={true}
            errorText={errors.noExt?.message && errors.noExt?.message}
            />
            <ControllerTextInput
            control={control}
            name="idColonia"
            label="Colonia"
            placeholder="Colonia"
            isDisabled={true}
            errorText={errors.idColonia?.message && errors.idColonia?.message}
            />
            <ControllerTextInput
            control={control}
            name="idDelegacion"
            label="Alcaldía"
            placeholder="Alcaldía"
            isDisabled={true}
            errorText={errors.idDelegacion?.message && errors.idDelegacion?.message}
            />
            <ControllerTextInput
            control={control}
            name="idEstadoDom"
            label="Estado de domicilio"
            placeholder="Estado de domicilio"
            isDisabled={true}
            errorText={errors.idEstadoDom?.message && errors.idEstadoDom?.message}
            />
            <ControllerTextInput
            control={control}
            name="cp"
            label="Código postal"
            placeholder="Código postal"
            isDisabled={true}
            errorText={errors.cp?.message && errors.cp?.message}
            />
            <ControllerTextInput
            control={control}
            name="idPaisDom"
            label="País de domicilio"
            placeholder="País de domicilio"
            isDisabled={true}
            errorText={errors.idPaisDom?.message && errors.idPaisDom?.message}
            />  
          <div className="btn-container">
            <UelButton 
            submit title="btn.continue"
            />
          </div>
        </form>
      </FormProvider>
        <div className="link-button-personal-quit">
                <UelLinkButton
                    onClick={cancelCB}
                    color="secondary"
                >
                    {"No quiero mejorar mi buró"}
                </UelLinkButton>
          </div>
    </div>
  );
  
};

export default CreditBuilderPersonalInfoContainer;