import { useIntl } from "react-intl";
import { comafy } from "utils/helpers/app.helpers";
import { ExpensesColorsI, ExpensesIconsI, ExpensesNamesI } from "models/mygps.models";
import GpsCategoryLabel from "../GpsCategoryLabel/GpsCategoryLabel";
import "./style.scss";

export interface GpsCategoryProps {
  label: ExpensesNamesI;
  amount: number;
  iconSrc: ExpensesIconsI;
  iconBgColor: ExpensesColorsI;
  onClick: (name:ExpensesNamesI) => void;
}

const GpsCategory = (props: GpsCategoryProps) => {
  const {label, amount, iconSrc, iconBgColor, onClick} = props;
  const { formatMessage } = useIntl();
  return (
    <div className="component-gps-category">
      <GpsCategoryLabel categoryName={label} iconSrc={iconSrc} iconBgColor={iconBgColor} onClick={onClick} />
      <div className="content-wrapper">
        <p className="label">{`${formatMessage({id:`screens.mygps.categories.label.${label}`})}`}</p>
        <p className="amount">{`$${comafy(amount)}`}</p>
      </div>
    </div>
  );
}

export default GpsCategory;