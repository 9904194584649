import { ApexOptions } from "apexcharts";
import _ from "lodash"
import { ChartRiskLevels } from 'models/mygps.models';
import { ExpensesI, GpsTransactionCollectionI, GpsTransactionI } from "models/mygps.models"
import { ExpensesCategoriesColors, ExpensesCategoriesColorsI, ExpensesCategoriesDescriptions, expensesCategoriesIcons, ExpensesCategoriesNames } from "utils/helpers/mygps.helpers";

const riskLevel: ChartRiskLevels = 1;

export const UserScoreExample = {
  "userId": 1,
  "creditScore": 0,
  "riskLevel": riskLevel
}

export const RiskLevelsExample = {
  "min": 450,
  "max": 760,
  "ranges": [
      {
          "min": 456,
          "max": 550,
          "riskLevel": 1
      },
      {
          "min": 551,
          "max": 630,
          "riskLevel": 2
      },
      {
          "min": 631,
          "max": 690,
          "riskLevel": 3
      },
      {
          "min": 691,
          "max": 760,
          "riskLevel": 4
      }
  ]
}

export const SemidonutChartSeriesExample = [20, 20, 20, 40];

export const AccountIdsExample = [
  {
    accountId: "bc8ae48b-2fe5-42c8-a0f4-f313b008cc91",
    number: "1537907435",
    name: "Cuenta en Pesos",
    bankName: "BBVA Bancomer",
    bankImageUrl: "/uploads/bbva_bc5cbddd7b.png"
  },
  {
    accountId: "46d3cc92-ab43-4c4c-b4b3-b438d4b9382b",
    number: "1459",
    name: "Primary Account Number",
    bankName: "BBVA Bancomer",
    bankImageUrl: "/uploads/bbva_bc5cbddd7b.png"
  },
  {
    accountId: "50262a6b-21c0-40f7-ae3f-85eaddbcb5d6",
    number: "Apartado",
    name: "Apartado",
    bankName: "BBVA Bancomer",
    bankImageUrl: "/uploads/bbva_bc5cbddd7b.png"
  },
  {
    accountId: "3ad0ee36-dacd-486d-9397-cafdab626fce",
    number: "CUENTA BBVA",
    name: "Cuenta en Pesos",
    bankName: "BBVA Bancomer",
    bankImageUrl: "/uploads/bbva_bc5cbddd7b.png"
  }
]

export const AccountIdsOptionsExample = [
  { value: "bc8ae48b-2fe5-42c8-a0f4-f313b008cc91", label: "BBVA *1537907435" },
  { value: "2", label: "BBVA *1459" },
  { value: "3", label: "BBVA *Apartado" },
  { value: "4", label: "BBVA *CUENTA BBVA" },
]
  
export const ExpensesDataExample: ExpensesI = {
    totalIncome: 80000,
    totalExpenses: -59090,
    expenseCategoryDistribution: [
      {
        id: 22,
        name:ExpensesCategoriesNames.hogar,
        description: ExpensesCategoriesDescriptions.hogar,
        color: ExpensesCategoriesColorsI.hogar,
        amount: 8500,
        percent: 14
      },
      {
        id: 23,
        name:ExpensesCategoriesNames.transporte,
        description: ExpensesCategoriesDescriptions.transporte,
        color: ExpensesCategoriesColorsI.transporte,
        amount: 7000,
        percent: 12
      },
      {
        id: 24,
        name:ExpensesCategoriesNames.alimentos,
        description: ExpensesCategoriesDescriptions.alimentos,
        color: ExpensesCategoriesColorsI.alimentos,
        amount: 3290,
        percent: 6
      },
      {
        id: 25,
        name:ExpensesCategoriesNames.educacion,
        description: ExpensesCategoriesDescriptions.educacion,
        color: ExpensesCategoriesColorsI.educacion,
        amount: 1200,
        percent: 2
      },
      {
        id: 26,
        name:ExpensesCategoriesNames.entretenimiento,
        description: ExpensesCategoriesDescriptions.entretenimiento,
        color: ExpensesCategoriesColorsI.entretenimiento,
        amount: 7000,
        percent: 12
      },
      {
        id: 27,
        name:ExpensesCategoriesNames.viajes,
        description: ExpensesCategoriesDescriptions.viajes,
        color: ExpensesCategoriesColorsI.viajes,
        amount: 7000,
        percent: 12
      },
      {
        id: 28,
        name:ExpensesCategoriesNames.ropa,
        description: ExpensesCategoriesDescriptions.ropa,
        color: ExpensesCategoriesColorsI.ropa,
        amount: 7000,
        percent: 12
      },
      {
        id: 29,
        name:ExpensesCategoriesNames.finanzas,
        description: ExpensesCategoriesDescriptions.finanzas,
        color: ExpensesCategoriesColorsI.finanzas,
        amount: 6100,
        percent: 10
      },
      {
        id: 30,
        name:ExpensesCategoriesNames.salud,
        description: ExpensesCategoriesDescriptions.salud,
        color: ExpensesCategoriesColorsI.salud,
        amount: 5000,
        percent: 8
      },
      {
        id: 31,
        name:ExpensesCategoriesNames.otros,
        description: ExpensesCategoriesDescriptions.otros,
        color: ExpensesCategoriesColorsI.otros,
        amount: 7000,
        percent: 12
    },
    ]
}

export const CategoriesWithIconsExample = [
  {
    name: ExpensesCategoriesNames.hogar,
    amount: 8500,
    icon:expensesCategoriesIcons[0].icon,
    color: ExpensesCategoriesColors[0],
  },
  {
    name: ExpensesCategoriesNames.transporte,
    amount: 7000,
    icon:expensesCategoriesIcons[1].icon,
    color: ExpensesCategoriesColors[1],
  },
  {
    name: ExpensesCategoriesNames.alimentos,
    amount: 3290,
    icon:expensesCategoriesIcons[2].icon,
    color: ExpensesCategoriesColors[2],
  },
  {
    name: ExpensesCategoriesNames.educacion,
    amount: 1200,
    icon:expensesCategoriesIcons[3].icon,
    color: ExpensesCategoriesColors[3],
  },
  {
    name: ExpensesCategoriesNames.entretenimiento,
    amount: 7000,
    icon:expensesCategoriesIcons[4].icon,
    color: ExpensesCategoriesColors[4],
  },
  {
    name: ExpensesCategoriesNames.viajes,
    amount: 7000,
    icon:expensesCategoriesIcons[5].icon,
    color: ExpensesCategoriesColors[5],
  },
  {
    name: ExpensesCategoriesNames.ropa,
    amount: 7000,
    icon:expensesCategoriesIcons[6].icon,
    color: ExpensesCategoriesColors[6],
  },
  {
    name: ExpensesCategoriesNames.finanzas,
    amount: 6100,
    icon:expensesCategoriesIcons[7].icon,
    color: ExpensesCategoriesColors[7],
  },
  {
    name: ExpensesCategoriesNames.salud,
    amount: 5000,
    icon:expensesCategoriesIcons[8].icon,
    color: ExpensesCategoriesColors[8],
  },
  {
    name: ExpensesCategoriesNames.otros,
    amount: 7000,
    icon:expensesCategoriesIcons[9].icon,
    color: ExpensesCategoriesColors[9],
  },
]

export const PieChartSeriesExample:ApexNonAxisChartSeries = [14, 12, 6, 2, 12, 12, 12, 10, 8, 12]

export const PieChartOptionsExample: /*PieChartOptionsI*/ ApexOptions = {
  labels: ExpensesDataExample.expenseCategoryDistribution.map(item => item.description),
  colors: ExpensesCategoriesColors,
  chart: {
    events: {
      dataPointSelection: (event, chartContext, config) => {
        console.log(config.w.config.labels[config.dataPointIndex]);
      }
    },
  },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: 0,
        },
      },
    },
    dataLabels: {
      style: {
        fontSize: "10px",
        fontFamily: "Work-regular, sans-serif",
      },
      formatter: (val: number) => {
        return Math.trunc(val).toString() + "%"
      },
  },
    legend: {
      show: false,
    },
}
  
export const GpsTransactionExample: GpsTransactionI = {
  id: 1,
  transactionId: "41194429",
  description: "",
  cleanedDescription: "CARGO",
  amount: "-16.26",
  isCharge: true,
  date: "2022-08-05T05:00:00.000Z",
  duplicated: false,
  currency: "MXN",
  balance: null,
  accountId: "46d3cc92-ab43-4c4c-b4b3-b438d4b9382b",
  createdAt: "2022-08-05T05:00:00.000Z",
  updatedAt: "2022-08-05T05:00:00.000Z",
  expense_category: {
      id: 26,
      name: ExpensesCategoriesNames.entretenimiento,
      description: ExpensesCategoriesNames.entretenimiento,
      color: ExpensesCategoriesColorsI.entretenimiento
  }
}

export const GpsTransactionsResponseExample: GpsTransactionCollectionI = {
  data: {
    totalExpenses: -487.80,
    totalTransactions: 30,
    transactions: _.range(30).map(item => GpsTransactionExample),
  }
 }
