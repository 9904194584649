import { IntlString } from "models/app.models"
import { FormattedMessage } from "react-intl"
import { comafy } from "utils/helpers/app.helpers"
import "./styles.scss"

export interface UelExpensesCardProps {
  figure: number
  alternateLabel?: IntlString
}

const UelBalanceCard = ({ figure, alternateLabel }: UelExpensesCardProps) => {

  return (
    <div className="component-uel-balance-card">
        <span className="label-card">
          <FormattedMessage
            id={
              alternateLabel ? alternateLabel : figure >= 0 ? "uel.incomes.card.title" : "uel.expenses.cart.title"
            }
          />
        </span>
        <div className="figures-container">
          <span
            className={figure >= 0 ? "figures-text" : "figures-text negative"}>
            {figure === 0 ? "$" : figure >= 0 ? "+ $" : "- $"}
            {comafy(figure)}
          </span>
        </div>
    </div>
  )
}

export default UelBalanceCard
