import LoadingContainer from "containers/LoadingContainer/LoadingContainer";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMyGpsWithFInerioId } from "store/MyGps/mygps.actions";
import { RootState } from "store/reducers";
import { getUserFirstTimeSyncAction, setAccountCreatedModal } from "store/SignUp/signup.actions";
import { ROUTES } from "utils/helpers/app.helpers";
import "./style.scss";


const AddBankAccountAfterSkipContainer = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isDone } = useSelector((state: RootState) => state.signup.userFirstTimeSync);

  useEffect(() => {
    const doFirstTimeService = setInterval(() => {
      dispatch(getUserFirstTimeSyncAction());
    }, 3000);
    return () => {
      clearInterval(doFirstTimeService);
    }
  }, [dispatch]);
  
  useEffect(() => {
    if (isDone) {
      dispatch(setAccountCreatedModal(true));
      dispatch(setMyGpsWithFInerioId(true))
      navigate(ROUTES.mygps, { replace: true });
    }
  }, [dispatch, isDone, navigate]);

  return(
    <div className="add-bank-account-after-skip">
      {!isDone && (
        <LoadingContainer>
          <p>{formatMessage({id: "screens.signup.done.title"})}</p>
        </LoadingContainer>
      )}
    </div>
  )
}

export default AddBankAccountAfterSkipContainer;