import axios from "axios";
import { AxiosRequestConfig } from "axios";
import { store } from "../../store/store";

const UelClient = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	responseType: "json",
	headers: {
		"Content-Type": "application/json",
	},
});

// Add a request interceptor
UelClient.interceptors.request.use(
	function (config: AxiosRequestConfig) {
		// Do something before request is sent
		const jwt = store.getState().auth.authentication.data.jwt;
		

		if (jwt && jwt !== '' && config.headers) {
			config.headers["Authorization"] = `Bearer ${jwt}`;
		}

		return config;
	},
	function (error: any) {
		// Do something with request error
		return Promise.reject(error);
	}
);

// Add a response interceptor
UelClient.interceptors.response.use(undefined, function (error: any) {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	return Promise.reject(error);
});

export default UelClient;
