import { getUserFirstTimeSyncService, grantScorePrivService, resendCodeTelephoneService, resendCodeToChangePassword, resetNewPassword, sendCodeToChangePassword, sendEmailToReceiveCode, setCodeTelephone, setFinerioService, setRegisterTelephone, skipFinerioAddAccount } from "./../../services/signup.service"
import { GrantScorePrivI } from "models/signup.models"
import { Dispatch } from "redux"
import {
  AddressInformationI,
  CitiesByZipCodeI,
  CreditScoreAgreementsI,
  RegisterUserServiceI,
} from "models/signup.models"
import { PersonalDataFormModelServiceI } from "models/signup.models"
import { UserInformationI } from "models/user.model"
import {
  getAddressByZipCodeService,
  emailVerifyService,
  resendCodeEmailService,
  registerUserService,
  setAddressInformationService,
  setDataPersonaService,
  getCreditScoreService,
} from "services/signup.service"
import {
  SET_EMAIL_VERIFY_ERROR,
  SET_RESEND_CODE_EMAIL_ERROR,
  SET_RESEND_CODE_EMAIL_LOADING,
  SET_EMAIL_VERIFY_LOADING,
  SET_ADDRESS_ERROR,
  SET_ADDRESS_LOADING,
  SET_ADDRESS_SUCCESS,
  SET_CITIES_ERROR,
  SET_CITIES_INFORMATION,
  SET_CITIES_LOADING,
  SET_GLOBAL_SIGNUP_STEP,
  SET_PERSONAL_DATA_ERROR,
  SET_PERSONAL_DATA_LOADING,
  SET_PERSONAL_DATA_STEP,
  SET_REGISTER_ERROR,
  SET_REGISTER_LOADING,
  SET_SIGNUP_SUCCESS,
  SET_USER_INFORMATION,
  SET_PERSONAL_DATA_SUCCESS,
  SET_CREDITSCORE_AGREEMENTS_INFORMATION,
  SET_CREDITSCORE_AGREEMENTS_LOADING,
  SET_CREDITSCORE_AGREEMENTS_ERROR,
  SET_RECOVER_PASSWORD_STEP,
  SET_GRANT_SCORE_PRIV_INFORMATION,
  SET_GRANT_SCORE_PRIV_LOADING,
  SET_GRANT_SCORE_PRIV_ERROR,
  SET_RECOVER_PASSWORD_LOADING,
  SET_RECOVER_PASSWORD_ERROR,
  SET_RECOVER_PASSWORD_SUCCESS,
  SET_RECOVER_PASSWORD_CODE,
  SET_RECOVER_PASSWORD_EMAIL,
  SET_ACCOUNT_CREATED_MODAL,
  SET_CLEAN_GRANT_SCORE_PRIV_INFORMATION,
  SET_SIGNUP_FINERIO_ERROR,
  SET_SIGNUP_FINERIO_ERROR_MODAL,
  SET_SIGNUP_FINERIO_NO_ACCOUNT_MODAL,
  SET_SIGNUP_FINERIO_IS_REQUESTED,
  SET_SIGNUP_FINERIO_IS_TIMEOUT,
  SET_ACCOUNT_ADDED_MODAL,
  SET_USER_FIRST_TIME_SYNC_LOADING,
  SET_USER_FIRST_TIME_SYNC_ERROR,
  SET_USER_FIRST_TIME_SYNC_INFORMATION,
  SET_SIGNUP_SET_FINERIO_REQUEST,
  SET_SIGNUP_SET_FINERIO_SUCCESS,
  SET_SIGNUP_SET_FINERIO_ERROR,
  SET_REGISTER_TELEPHONE_LOADING,
  SET_REGISTER_TELEPHONE_SAVE,
  SET_REGISTER_TELEPHONE_SAVE_CHANNEL,
  SET_SIGNUP_SKIP_BANK_ACCOUNT_LOADING,
  SET_SIGNUP_SET_FINERIO_WELCOME,
  SET_CREDIT_BURO_ACCESS_ERROR_MODAL,
  SET_CREDIT_BUILDER_STEP
} from "./signup.reducer"
import {
  SignUpPersonalDataSteps,
  SignUpSteps,
  RecoverPasswordSteps,
  SignUpCreditBuilderSteps
} from "utils/helpers/signup.helpers"
import { setAccountAlreadyCreatedModalAction, setLoginSuccess } from "store/Auth/auth.actions"
import { AxiosError } from "axios"

export const setCreditBuroAccessErrorModal = (isVisible: boolean) => {
  return {
    type: SET_CREDIT_BURO_ACCESS_ERROR_MODAL,
    payload: isVisible,
  }
}

export const setAccountCreatedModal = (isVisible: boolean) => {
  return {
    type: SET_ACCOUNT_CREATED_MODAL,
    payload: isVisible,
  }
}

export const setUserFirstTimeSyncInformation = (isDone: boolean) => {
  return {
    type: SET_USER_FIRST_TIME_SYNC_INFORMATION,
    payload: isDone,
  }
}

export const setUserFirstTimeSyncLoading = (isLoading: boolean) => {
  return {
    type: SET_USER_FIRST_TIME_SYNC_LOADING,
    payload: isLoading,
  }
}

export const setUserFirstTimeSyncError = (error: boolean) => {
  return {
    type: SET_USER_FIRST_TIME_SYNC_ERROR,
    payload: error,
  }
}

export const setAccountAddedModal = (isVisible: boolean) => {
  return {
    type: SET_ACCOUNT_ADDED_MODAL,
    payload: isVisible,
  }
}

export const setGlobalSignUpStep = (step: SignUpSteps) => {
  return {
    type: SET_GLOBAL_SIGNUP_STEP,
    payload: step,
  }
}

export const setPersonalDataStep = (step: SignUpPersonalDataSteps) => {
  return {
    type: SET_PERSONAL_DATA_STEP,
    payload: step,
  }
}

export const setCreditBuilderStep = (step: SignUpCreditBuilderSteps) => ({
  type: SET_CREDIT_BUILDER_STEP,
  payload: step
});

export const setFinerioSignUpSuccess = (isSuccess: string) => {
  return {
    type: SET_SIGNUP_SUCCESS,
    payload: isSuccess,
  }
}

export const setFinerioSignUpError = () => {
  return {
    type: SET_SIGNUP_FINERIO_ERROR,
  }
}

export const setFinerioSignUpErrorModal = (isOpen: boolean) => {
  return {
    type: SET_SIGNUP_FINERIO_ERROR_MODAL,
    payload: isOpen
  }
}

export const setFinerioSignUpNoAccountModal = (isOpen: boolean) => {
  return {
    type: SET_SIGNUP_FINERIO_NO_ACCOUNT_MODAL,
    payload: isOpen
  }
}

export const setFinerioRequestested = (isRequested: boolean) => {
  return {
    type: SET_SIGNUP_FINERIO_IS_REQUESTED,
    payload: isRequested
  }
}

export const setSignUpFinerioIsTimeout = (isTimeout: boolean) => {
  return {
    type: SET_SIGNUP_FINERIO_IS_TIMEOUT,
    payload: isTimeout
  }
}

export const setSignUpSetFinerioRequest = () => {
  return {
    type: typeof SET_SIGNUP_SET_FINERIO_REQUEST
  }
}

export const setSignUpSetFinerioSuccess = () => {
  return {
    type: typeof SET_SIGNUP_SET_FINERIO_SUCCESS
  }
}

export const setSignUpSetFinerioError = () => {
  return {
    type: typeof SET_SIGNUP_SET_FINERIO_ERROR
  }
}


export const personalDataLoading = (loading: boolean) => {
  return {
    type: SET_PERSONAL_DATA_LOADING,
    payload: loading,
  }
}
export const personalDataError = (error: boolean) => {
  return {
    type: SET_PERSONAL_DATA_ERROR,
    payload: error,
  }
}

export const setPersonalDataName = (name: string) => {
  return {
    type: SET_PERSONAL_DATA_SUCCESS,
    payload: name,
  }
}

export const setSkipFinerioBankAccountLoading = (isLoading: boolean) => {
  return {
    type: SET_SIGNUP_SKIP_BANK_ACCOUNT_LOADING,
    payload: isLoading
  }
}

export const setPersonalData =
  (
    {
      firstName,
      middleName,
      lastName,
      secondLastName,
      dateOfBirth,
      placeOfBirth,
      rfc,
      sex,
    }: PersonalDataFormModelServiceI,
    handleSuccess: () => void
  ) =>
    async (dispatch: Dispatch) => {
      dispatch(personalDataLoading(true))
      try {
        await setDataPersonaService({
          firstName,
          middleName,
          lastName,
          secondLastName,
          dateOfBirth,
          placeOfBirth,
          rfc,
          sex,
        })
        handleSuccess()
        dispatch(personalDataLoading(false))
        dispatch(setPersonalDataName(firstName))
      } catch (error) {
        dispatch(personalDataLoading(false))
        dispatch(personalDataError(true))
      }
    }

export const setUserInformation = (data: UserInformationI) => {
  return {
    type: SET_USER_INFORMATION,
    payload: data,
  }
}

export const registerLoading = () => {
  return {
    type: SET_REGISTER_LOADING,
  }
}

export const registerError = () => {
  return {
    type: SET_REGISTER_ERROR,
  }
}

export const setCitiesByZipCode = (data: CitiesByZipCodeI) => {
  return {
    type: SET_CITIES_INFORMATION,
    payload: data,
  }
}

export const setCitiesLoading = () => {
  return {
    type: SET_CITIES_LOADING,
  }
}

export const setCitiesError = () => {
  return {
    type: SET_CITIES_ERROR,
  }
}

export const setCreditScoreAgreements = (data: CreditScoreAgreementsI) => {
  return {
    type: SET_CREDITSCORE_AGREEMENTS_INFORMATION,
    payload: data,
  }
}

export const setCreditScoreAgreementsLoading = () => {
  return {
    type: SET_CREDITSCORE_AGREEMENTS_LOADING,
  }
}

export const setCreditScoreAgreementsError = () => {
  return {
    type: SET_CREDITSCORE_AGREEMENTS_ERROR,
  }
}

export const setGrantScorePriv = (data: GrantScorePrivI) => {
  return {
    type: SET_GRANT_SCORE_PRIV_INFORMATION,
    payload: data,
  }
}

export const setCleanGrantScorePriv = () => {
  return {
    type: SET_CLEAN_GRANT_SCORE_PRIV_INFORMATION,
  }
}

export const setGrantScorePrivLoading = () => {
  return {
    type: SET_GRANT_SCORE_PRIV_LOADING,
  }
}

export const setGrantScorePrivError = () => {
  return {
    type: SET_GRANT_SCORE_PRIV_ERROR,
  }
}

export const setAddressSuccess = () => {
  return {
    type: SET_ADDRESS_SUCCESS,
  }
}

export const setAddressError = () => {
  return {
    type: SET_ADDRESS_ERROR,
  }
}

export const setAddressLoading = () => {
  return {
    type: SET_ADDRESS_LOADING,
  }
}

export const resendCodeLoading = (loading: boolean) => {
  return {
    type: SET_RESEND_CODE_EMAIL_LOADING,
    payload: loading,
  }
}
export const resendCodeError = (error: boolean) => {
  return {
    type: SET_RESEND_CODE_EMAIL_ERROR,
    payload: error,
  }
}

export const codeVerifyLoading = (loading: boolean) => {
  return {
    type: SET_EMAIL_VERIFY_LOADING,
    payload: loading,
  }
}
export const codeVerifyError = (error: string) => {
  return {
    type: SET_EMAIL_VERIFY_ERROR,
    payload: error,
  }
}

export const setRecoverPasswordStep = (step: RecoverPasswordSteps) => {
  return {
    type: SET_RECOVER_PASSWORD_STEP,
    payload: step
  }
}

export const setRecoverPasswordLoading = () => {
  return {
    type: SET_RECOVER_PASSWORD_LOADING
  }
}

export const setRecoverPasswordError = (error: string) => {
  return {
    type: SET_RECOVER_PASSWORD_ERROR,
    payload: error
  }
}

export const setRecoverPasswordSuccess = () => {
  return {
    type: SET_RECOVER_PASSWORD_SUCCESS,
  }
}

export const setRecoverPasswordEmail = (email: string) => {
  return {
    type: SET_RECOVER_PASSWORD_EMAIL,
    payload: email
  }
}

export const setRecoverPasswordCode = (code: string) => {
  return {
    type: SET_RECOVER_PASSWORD_CODE,
    payload: code
  }
}

export const registerTelephoneLoading = (loading: boolean) => {
  return {
    type: SET_REGISTER_TELEPHONE_LOADING,
    payload: loading,
  }
}
export const registerSaveTelephone = (telephone: string) => {
  return {
    type: SET_REGISTER_TELEPHONE_SAVE,
    payload: telephone,
  }
}
export const registerSaveChannelTelephone = (channel: "sms" | "whatsapp") => {
  return {
    type: SET_REGISTER_TELEPHONE_SAVE_CHANNEL,
    payload: channel,
  }
}
export const setFinerioWelcomeScreen = (isWelcome: boolean) => {
  return {
    type: SET_SIGNUP_SET_FINERIO_WELCOME,
    payload: isWelcome
  }
}

export const registerUser = (
  { email, password, agreement, utm_source, utm_medium, utm_campaign, utm_term, utm_content }: RegisterUserServiceI,
  handleSuccess: () => void) => async (dispatch: Dispatch) => {
    dispatch(registerLoading())
    try {
      const { data } = await registerUserService({ email, password, agreement, utm_source, utm_medium, utm_campaign, utm_term, utm_content })
      dispatch(setUserInformation(data))
      handleSuccess()
    } catch (error: any) {
      if (error instanceof AxiosError && error.response?.status === 400){
        dispatch(setAccountAlreadyCreatedModalAction(true))
      }
      dispatch(registerError())
    }
  }


export const setAddressInformation =
  (address: AddressInformationI, handleSuccess: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch(setAddressLoading())
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { data } = await setAddressInformationService(address)
        dispatch(setAddressSuccess())
        handleSuccess()
      } catch (error: any) {
        dispatch(setAddressError())
      }
    }

export const getCitiesByZipCode =
  (zipCode: number) => async (dispatch: Dispatch) => {
    dispatch(setCitiesLoading())
    try {
      const { data } = await getAddressByZipCodeService(zipCode)
      const address: CitiesByZipCodeI = {
        city: data.city || "",
        state: data.state,
        districts: data.districts.map((item: string) => ({
          label: item,
          value: item,
        })),
        stadeCode: data.stateCode
      }
      dispatch(setCitiesByZipCode(address))
    } catch (error: any) {
      dispatch(setCitiesError())
    }
  }

export const getCreditScoreAgreements = () => async (dispatch: Dispatch) => {
  dispatch(setCreditScoreAgreementsLoading())
  try {
    const { data } = await getCreditScoreService()
    dispatch(setCreditScoreAgreements(data))
  } catch (error: any) {
    dispatch(setCreditScoreAgreementsError())
  }
}

export const grantScorePriv =
  (props: GrantScorePrivI) => async (dispatch: Dispatch) => {
    dispatch(setGrantScorePrivLoading())
    try {
      const { data } = await grantScorePrivService(props)
      dispatch(setGrantScorePriv(data))
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const statusCode = error.response?.status
        if (statusCode === 401) {
          dispatch(setGrantScorePrivError())
        } else if (statusCode === 503) {
          dispatch(setCreditBuroAccessErrorModal(true));
        }
      }
    }
  }

export const setCodeVerify =
  (code: string, email: string, handleSuccess: () => void) =>
    async (dispatch: Dispatch) => {
      dispatch(codeVerifyLoading(true))
      try {
        const { data } = await emailVerifyService(code, email)
        if (data.confirmation.id === 1) {
          dispatch(codeVerifyError("screens.email.verify.error.incorrect"))
        } else if (data.confirmation.id === 2) {
          dispatch(codeVerifyError("screens.email.verify.error.deprecated"))
        } else {
          dispatch(setLoginSuccess({
            jwt: data.jwt,
            id: data.user.id,
            userName: data.user.username,
            firstName: data.user.name,
            email: data.user.email
          }))
          handleSuccess()
        }
        dispatch(codeVerifyLoading(false))
      } catch (error: any) {
        dispatch(codeVerifyLoading(false))
      }
    }

export const resendCodeToVerifyEmail =
  (source: string, channel:string) => async (dispatch: Dispatch) => {
    dispatch(resendCodeLoading(true))
    try {
      await resendCodeEmailService(
        source,
        channel
      )
      dispatch(resendCodeLoading(false))
    } catch (error: any) {
      dispatch(resendCodeError(true))
      dispatch(resendCodeLoading(false))
    }
  }

export const sendEmailToReceiveCodeForNewPassword = (email: string, handleSuccess: () => void) => async (dispatch: Dispatch) => {
  dispatch(setRecoverPasswordLoading())
  try {
    await sendEmailToReceiveCode(email)
    dispatch(setRecoverPasswordEmail(email))
    dispatch(setRecoverPasswordSuccess())
    handleSuccess()
  } catch (error: any) {
    dispatch(setRecoverPasswordError(error))
  }
}

export const sendCodeForNewPassword = (email: string, code: string, handleSuccess: () => void) => async (dispatch: Dispatch) => {
  dispatch(setRecoverPasswordLoading())
  try {
    const { data } = await sendCodeToChangePassword(email, code)
    switch (data.id) {
      case 0:
        data.valid && data.code && dispatch(setRecoverPasswordCode(data.code))
        dispatch(setRecoverPasswordSuccess())
        handleSuccess()
        break;
      case 1:
        dispatch(setRecoverPasswordError("screens.email.verify.error.incorrect"))
        break;
      default:
        break;
    }
  } catch (error: any) {
    dispatch(setRecoverPasswordError(""))
  }
}

export const resendCodeToRecoverPassword = (email: string) => async (dispatch: Dispatch) => {
  dispatch(setRecoverPasswordLoading())
  try {
    await resendCodeToChangePassword(email)
    dispatch(setRecoverPasswordSuccess())
  } catch (error: any) {
    dispatch(setRecoverPasswordError(""))
  }
}

export const setNewPassword = (code: string, password: string, handleSuccess: () => void) => async (dispatch: Dispatch) => {
  dispatch(setRecoverPasswordLoading())
  try {
    await resetNewPassword(code, password, password)
    dispatch(setRecoverPasswordSuccess())
    handleSuccess()
  } catch (error) {
    dispatch(setRecoverPasswordError(""))
  }
}

export const getUserFirstTimeSyncAction = () => async (dispatch: Dispatch) => {
  dispatch(setUserFirstTimeSyncLoading(true))
  try {
    const res = await getUserFirstTimeSyncService()
    dispatch(setUserFirstTimeSyncInformation(res.data.firstTimeSincronization))
  } catch (error) {
    dispatch(setUserFirstTimeSyncError(true))
  } finally {
    dispatch(setUserFirstTimeSyncLoading(false))
  }
}

export const setFinerioIdAction = (finerioId: string) => async (dispatch: Dispatch) => {
  dispatch(setSignUpSetFinerioRequest())
  try {
    await setFinerioService(finerioId)
    dispatch(setSignUpSetFinerioSuccess())
  } catch {
    dispatch(setSignUpSetFinerioError())
  }
}

export const setRegisterTelephoneAction =
  (_telephone: string, channel: "sms" | "whatsapp", handleSuccess: () => void) =>
  async (dispatch: Dispatch) => {
    dispatch(registerTelephoneLoading(true))
    try {
      await setRegisterTelephone(_telephone, channel)
      dispatch(registerTelephoneLoading(false))
      handleSuccess()
    } catch (error: any) {
      dispatch(codeVerifyError("screens.email.verify.error.incorrect"))
      dispatch(registerTelephoneLoading(false))
    }
  }

export const setCodeTelephoneAction =
  (_telephone: string, code: string, handleSuccess: () => void) =>
  async (dispatch: Dispatch) => {
    dispatch(registerTelephoneLoading(true))
    try {
      const {data} =await setCodeTelephone(_telephone, code)
      if (data?.phoneConfirmed) {
        dispatch(codeVerifyError(""))
        handleSuccess()
      }else{
        dispatch(codeVerifyError("screens.email.verify.error.incorrect"))
      }
      dispatch(registerTelephoneLoading(false))
    } catch (error: any) {
      dispatch(codeVerifyError("screens.email.verify.error.incorrect"))
      dispatch(registerTelephoneLoading(false))
    }
  }

export const setResendCodeTelephoneAction =
  (source: string, channel: string) => async (dispatch: Dispatch) => {
    dispatch(resendCodeLoading(true))
    try {
      await resendCodeTelephoneService(source, channel)
      dispatch(resendCodeLoading(false))
    } catch (error: any) {
      dispatch(resendCodeError(true))
      dispatch(resendCodeLoading(false))
    }
  }
export const skipOnboardingBankAccount = (
  handleSuccess: () =>  void,
  handleError: () => void
) => async (dispatch: Dispatch) => {
  dispatch(setSkipFinerioBankAccountLoading(true))
  try {
    await skipFinerioAddAccount()
    handleSuccess()
    dispatch(setSkipFinerioBankAccountLoading(false))
  } catch {
    handleError()
    dispatch(setSkipFinerioBankAccountLoading(false))
  }
}
