import UelFancyModal, { UelFancyModalProps } from '../../Shared/UelFancyModal/UelFancyModal';

export interface AccountCreatedModalProps extends
  Omit<UelFancyModalProps, 'title' | 'description' | 'linkButtonTitle'> {
}

const AccountCreatedModal = (props: AccountCreatedModalProps) => {
  return (
    <UelFancyModal
      {...props}
      title="global.modal.fineriosuccess.title"
      subtitle="global.modal.fineriosuccess.subtitle"
      description="global.modal.fineriosuccess.description"
      linkButtonTitle="global.modal.fineriosuccess.link"
      withCustomBackground
    />
  );
}

export default AccountCreatedModal;
