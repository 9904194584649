import UelLinkButton from "components/Shared/UelLinkButton/UelLinkButton"
import { ChartRiskLevels } from "models/mygps.models"
import Chart from "react-apexcharts"
import { useIntl } from "react-intl"
import { SemidonutChartSeriesExample } from "utils/dataExamples/MyGpsExamples"
import { riskLevels, SemiDonutChartoptions } from "utils/helpers/mygps.helpers"
import "./style.scss"
export interface SemiDonutChartProps {
  creditScore: number
  riskLevel: ChartRiskLevels
}

const SemiDonutChart = (props: SemiDonutChartProps) => {
  const { creditScore, riskLevel } = props
  const width = 300
  const customWrapperStyle = { height: width * 0.4, width }
  const { formatMessage } = useIntl()
  const riskLevelItem = riskLevels[riskLevel + 1]
  const customClass = riskLevelItem.customClass
  const pClassName = `points ${customClass}`
  const wrapperClassname = `semidonut-wrapper ${customClass}`
  const isDisabled = riskLevel === -1
  return (
    <div className="uel-semidonut-chart">
      <div className={wrapperClassname} style={customWrapperStyle}>
        <Chart
          series={SemidonutChartSeriesExample}
          options={SemiDonutChartoptions}
          type="donut"
        />
        {!isDisabled && (
          <p className={pClassName}>
            {creditScore}{" "}
            {formatMessage({ id: "screens.mygps.scorecard.points" })}
          </p>
        )}
      </div>
      {!isDisabled && (
        <p className="range">{formatMessage({ id: riskLevelItem.result })}</p>
      )}
      {isDisabled && (
        <div className="is-disabled">
          <p>{formatMessage({ id: "screens.mygps.chart.result.r-1" })}</p>
          <UelLinkButton
            onClick={() => alert("click")}
            children="screens.mygps.chart.result.r-1-add-account"
          />
        </div>
      )}
    </div>
  )
}

export default SemiDonutChart
