import React, { HTMLInputTypeAttribute } from "react";
import { useIntl } from "react-intl";
import { TextInput } from "@mantine/core";
import "./style.scss";

//Interface
export interface UelTextInputProps {
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.ChangeEventHandler<HTMLInputElement>;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isPassword?: boolean;
  error?: boolean;
  errorText?: string;
  type?: HTMLInputTypeAttribute;
  iconRight?: string;
  onActioniconRight?: () => void;
  maxLength?: number;
}

const UelTextInput = ({
  label,
  onBlur,
  onChange,
  value = "",
  placeholder,
  isRequired,
  error,
  errorText,
  isDisabled,
  type,
  iconRight,
  onActioniconRight,
  isPassword,
  maxLength,
}: UelTextInputProps) => {
  const intl = useIntl();
  // RENDER:
  return (
    <div className="text-input-component">
      <TextInput
        maxLength={maxLength ? maxLength : 50}
        placeholder={intl.formatMessage({ id: placeholder })}
        label={intl.formatMessage({ id: label })}
        withAsterisk={isRequired}
        value={value}
        onChange={onChange}
        error={errorText ? intl.formatMessage({ id: errorText }) : error}
        variant="unstyled"
        type={isPassword ? "password" : type}
        onBlur={onBlur}
        disabled={isDisabled}
        rightSection={
          iconRight && (
            <img
              onClick={onActioniconRight && onActioniconRight}
              id="icon-right"
              src={iconRight}
              alt="icon-right"
            />
          )
        }
      />
    </div>
  );
};

export default UelTextInput;
