import { FormattedMessage, useIntl } from "react-intl"
import UelButton, {
  UelButtonProps,
} from "components/Shared/UelButton/UelButton"
import "./style.scss"
import { IntlString } from "models/app.models"
import { tabletBreakpoint } from "utils/helpers/app.helpers"

export interface GpsButtonDropdownItem {
  onClick: () => void
  label: IntlString
}

export interface GpsButtonProps extends UelButtonProps {
  label?: IntlString
  dropdownItems?: GpsButtonDropdownItem[]
}

const GpsButton = (props: GpsButtonProps) => {
  const { label, onClick, dropdownItems, disabled } = props
  const { height = 92 } = props
  const { formatMessage } = useIntl()
  const hasDropdownBehavior = dropdownItems !== undefined
  const containerClassname = `
    uel-gps-button
    ${hasDropdownBehavior ? "dropdown-behavior" : ""}
    ${disabled ? "disabled" : ""}
  `
  return (
    <UelButton
      className={containerClassname}
      {...props}
      variant="tertiary"
      height={height}
      onClick={(e) => {
        if ((!hasDropdownBehavior || !tabletBreakpoint.matches) && onClick) {
          onClick(e)
        }
      }}>
      <img
        className="icon"
        src="/icons/buttonArrow.svg"
        alt={formatMessage({ id: "alt.gpsbutton.icon.right" })}
      />
      {label && <p className="label">{formatMessage({ id: label })}</p>}
      <img
        className="dropdown-arrow"
        src={"/icons/gps-button-dropdown.svg"}
        alt={formatMessage({ id: "alt.gpsbutton.icon.right" })}
      />
      <div className="hidden-wrapper">
        <div className="items-wrapper">
          {dropdownItems?.map((item: GpsButtonDropdownItem, key: number) => {
            return (
              <p key={key + 1} className="item" onClick={item.onClick}>
                <FormattedMessage id={item.label} />
              </p>
            )
          })}
        </div>
      </div>
    </UelButton>
  )
}

export default GpsButton
