import { LegalMenuI, SocialMenuI } from "models/app.models"
import { FormattedMessage, useIntl } from "react-intl"
import { legalMenu, socialMenu } from "utils/helpers/app.helpers"
import "./style.scss"

const UelWebFooter = () => {
  const intl = useIntl()

  return (
    <footer className="component-uel-web-footer">
      <article className="footer-section top">
        <div className="footer-element logo">
          <div className="logo">
            <img
              src="/logos/Logotipo_blanco.svg"
              alt="logo de uelbee en blanco"
            />
          </div>
        </div>
      </article>
      <article className="footer-section center">
        <div className="footer-element address-social">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://goo.gl/maps/FiRs2MUEqHkP2B4L8"
            className="address">
            Zamora 187, Colonia Condesa, Cuauhtémoc, 06140 Ciudad de México,
            CDMX
          </a>
          <div className="legal-social-labels">
            <div className="legal-social">
              <nav className="legal">
                <h3>
                  <FormattedMessage id="footer.legal.title" />
                </h3>
                <ul>
                  {legalMenu.map((item: LegalMenuI, key: number) => (
                    <li key={key + 1}>
                      <a className="footer-link" href={item.href} target="_blank" rel="noreferrer">
                        <FormattedMessage id={item.copy} />
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
              <div className="social">
                {socialMenu.map((item: SocialMenuI, key: number) =>
                  key <= 1 ? (
                    <a key={key + 1} className="social-link" href={item.href}>
                      <div className="back-layer">
                        <img
                          src={`/icons/${item.src}`}
                          alt={intl.formatMessage({
                            id: item.alt,
                          })}
                        />
                      </div>
                    </a>
                  ) : (
                    <a key={key + 1} className="social-link" href={item.href}>
                      <img
                        src={`/icons/${item.src}`}
                        alt={intl.formatMessage({
                          id: item.alt,
                        })}
                      />
                    </a>
                  )
                )}
              </div>
            </div>
            <div className="labels">
              <div className="fintech">
                <img
                  src="/images/fintech.svg"
                  alt={intl.formatMessage({ id: "footer.labels.fintech" })}
                />
              </div>
              <div className="trust">
                <img
                  src="/images/sello-datos.svg"
                  alt={intl.formatMessage({ id: "footer.labels.datos" })}
                />
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="footer-section bottom">
        <div className="copyright">
          <FormattedMessage
            id="footer.copyright.n.options"
            values={{
              year: new Date().getFullYear(),
            }}
          />
        </div>
      </article>
    </footer>
  )
}

export default UelWebFooter
