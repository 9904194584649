import { Controller } from "react-hook-form";
import { UelCheckBoxProps } from "components/FormComponents/UelCheckBox/UelCheckBox";
interface CheckboxComponentPropsHOC extends UelCheckBoxProps {
  name: string
  control: any
}

const withCheckboxController = (WrappedComponent: (props: UelCheckBoxProps) => JSX.Element) => {
  const WithCheckboxController = (props: CheckboxComponentPropsHOC) => {
    const { name, control } = props;
    return (
      <Controller 
        name={name}
        control={control}
        render={( { field } ) => {
          return (
            <WrappedComponent 
              {...props}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
            />
          )
        }}
      />
    )
  }
  return WithCheckboxController;
}

export default withCheckboxController;