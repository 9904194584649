import {
  RecoverPasswordSteps,
  SignUpCreditBuilderSteps,
  SignUpPersonalDataSteps,
  SignUpSteps,
} from "utils/helpers/signup.helpers"
import { SignUpActions, SignUpState } from "./signup.model"

export const SET_GLOBAL_SIGNUP_STEP = "SET_GLOBAL_SIGNUP_STEP"
export const SET_PERSONAL_DATA_STEP = "SET_PERSONAL_DATA_STEP"
export const SET_SIGNUP_SUCCESS = "SET_SIGNUP_SUCCESS"
// Defined Action Type for Credit Builder Steps
export const SET_CREDIT_BUILDER_STEP = "SET_CREDIT_BUILDER_STEP"
export const SET_SIGNUP_FINERIO_ERROR = "SET_SIGNUP_FINERIO_ERROR"
export const SET_SIGNUP_FINERIO_ERROR_MODAL = "SET_SIGNUP_FINERIO_ERROR_MODAL"
export const SET_SIGNUP_FINERIO_NO_ACCOUNT_MODAL =

  "SET_SIGNUP_FINERIO_NO_aCCOUNT_MODAL"
export const SET_SIGNUP_FINERIO_IS_REQUESTED = "SET_SIGNUP_FINERIO_IS_REQUESTED"
export const SET_SIGNUP_FINERIO_IS_TIMEOUT = "SET_SIGNUP_FINERIO_IS_TIMEOUT"
export const SET_SIGNUP_SET_FINERIO_REQUEST = "SET_SIGNUP_SET_FINERIO_REQUEST"
export const SET_SIGNUP_SET_FINERIO_SUCCESS = "SET_SIGNUP_SET_FINERIO_SUCCESS"
export const SET_SIGNUP_SET_FINERIO_ERROR = "SET_SIGNUP_SET_FINERIO_ERROR"
export const SET_SIGNUP_SET_FINERIO_WELCOME = "SET_SIGNUP_SET_FINERIO_WELCOME"
export const SET_SIGNUP_SKIP_BANK_ACCOUNT_LOADING =
  "SET_SIGNUP_SKIP_BANK_ACCOUNT_LOADING"
export const SET_USER_INFORMATION = "SET_USER_INFORMATION"
export const SET_REGISTER_LOADING = "SET_REGISTER_LOADING"
export const SET_REGISTER_ERROR = "SET_REGISTER_ERROR"
export const SET_CITIES_INFORMATION = "SET_CITIES_INFORMATION"
export const SET_CITIES_LOADING = "SET_CITIES_LOADING"
export const SET_CITIES_ERROR = "SET_CITIES_ERROR"
export const SET_ADDRESS_SUCCESS = "SET_ADDRESS_SUCCESS"
export const SET_ADDRESS_ERROR = "SET_ADDRESS_ERROR"
export const SET_ADDRESS_LOADING = "SET_ADDRESS_LOADING"
export const SET_PERSONAL_DATA_LOADING = "SET_PERSONAL_DATA_LOADING"
export const SET_PERSONAL_DATA_ERROR = "SET_PERSONAL_DATA_ERROR"
export const SET_CREDITSCORE_AGREEMENTS_INFORMATION =
  "SET_CREDIT_SCORE_AGREEMENTS_INFORMATION"
export const SET_CREDITSCORE_AGREEMENTS_LOADING =
  "SET_CREDIT_SCORE_AGREEMENTS_LOADING"
export const SET_CREDITSCORE_AGREEMENTS_ERROR =
  "SET_CREDIT_SCORE_AGREEMENTS_ERROR"
export const SET_GRANT_SCORE_PRIV_INFORMATION =
  "SET_GRANT_SCORE_PRIV_INFORMATION"
export const SET_CLEAN_GRANT_SCORE_PRIV_INFORMATION =
  "SET_CLEAN_GRANT_SCORE_PRIV_INFORMATION"
export const SET_GRANT_SCORE_PRIV_LOADING = "SET_GRANT_SCORE_PRIV_LOADING"
export const SET_GRANT_SCORE_PRIV_ERROR = "SET_GRANT_SCORE_PRIV_ERROR"
export const SET_EMAIL_VERIFY_LOADING = "SET_EMAIL_VERIFY_LOADING"
export const SET_EMAIL_VERIFY_ERROR = "SET_EMAIL_VERIFY_ERROR"
export const SET_RESEND_CODE_EMAIL_LOADING = "SET_RESEND_CODE_EMAIL_LOADING"
export const SET_RESEND_CODE_EMAIL_ERROR = "SET_RESEND_CODE_EMAIL_ERROR"
export const SET_PERSONAL_DATA_SUCCESS = "SET_PERSONAL_DATA_SUCCESS"
export const SET_RECOVER_PASSWORD_STEP = "SET_RECOVER_PASSWORD_STEP"
export const SET_RECOVER_PASSWORD_LOADING = "SET_RECOVER_PASSWORD_LOADING"
export const SET_RECOVER_PASSWORD_ERROR = "SET_RECOVER_PASSWORD_ERROR"
export const SET_RECOVER_PASSWORD_SUCCESS = "SET_RECOVER_PASSWORD_SUCCESS"
export const SET_RECOVER_PASSWORD_ERRORTEXT = "SET_RECOVER_PASSWORD_ERRORTEXT"
export const SET_RECOVER_PASSWORD_CODE = "SET_RECOVER_PASSWORD_CODE"
export const SET_RECOVER_PASSWORD_EMAIL = "SET_RECOVER_PASSWORD_EMAIL"
export const SET_ACCOUNT_CREATED_MODAL = "SET_ACCOUNT_CREATED_MODAL"
export const SET_ACCOUNT_ADDED_MODAL = "SET_ACCOUNT_ADDED_MODAL"
export const SET_USER_FIRST_TIME_SYNC_INFORMATION =
  "SET_USER_FIRST_TIME_SYNC_INFORMATION"
export const SET_USER_FIRST_TIME_SYNC_LOADING =
  "SET_USER_FIRST_TIME_SYNC_LOADING"
export const SET_USER_FIRST_TIME_SYNC_ERROR = "SET_USER_FIRST_TIME_SYNC_ERROR"
export const SET_REGISTER_TELEPHONE_LOADING = "SET_REGISTER_TELEPHONE_LOADING"
export const SET_REGISTER_TELEPHONE_SAVE = "SET_REGISTER_TELEPHONE_SAVE"
export const SET_REGISTER_TELEPHONE_SAVE_CHANNEL =
  "SET_REGISTER_TELEPHONE_SAVE_CHANNEL"
export const SET_CREDIT_BURO_ACCESS_ERROR_MODAL = "SET_CREDIT_BURO_ACCESS_ERROR_MODAL"

const initialState: SignUpState = {
  globalSignUpStep: SignUpSteps.initialForm,
  personalDataStep: SignUpPersonalDataSteps.personalData,
  creditBuilderStep: SignUpCreditBuilderSteps.initial,
  recoverPasswordStep: RecoverPasswordSteps.recoverInitial,
  accountCreatedModal: false,
  accountAddedModal: false,
  creditBuroAccessErrorModal: false,
  userFirstTimeSync: {
    isDone: false,
    isLoading: false,
    error: false,
  },
  finerio: {
    isFinerioRequested: false,
    isFinerioSuccess: false,
    isFinerioErrorModal: false,
    isFinerioNoAccountModal: false,
    isFinerioTimeout: false,
    finerioId: "",
    setFinerioId: {
      isLoading: false,
      isScuccess: false,
      isError: false,
    },
    isSkipFinerioLoading: false,
    isFinerioWelcomeScreen: true,
  },
  personalData: {
    name: "",
  },
  personalDataLoading: false,
  personalDataError: false,
  registerUser: {
    data: {
      id: 0,
      email: "",
    },
    isLoading: false,
    error: false,
  },
  recoverPassword: {
    email: "",
    code: "",
    isLoading: false,
    error: false,
    errorText: "",
  },
  CitiesByZipCode: {
    data: {
      city: "",
      state: "",
      districts: [{ label: "", value: "" }],
      stadeCode: "",
    },
    error: false,
    isLoading: false,
  },
  creditScoreAgreements: {
    data: {
      id: null,
      type: "",
      content: "",
    },
    isLoading: false,
    error: false,
  },
  grantScorePriv: {
    data: {
      ok: false,
    },
    isLoading: false,
    error: false,
  },
  emailVerifyError: "",
  emailVerifyLoading: false,
  emailCodeResendError: false,
  emailCodeResendLoading: false,
  registerTelephoneLoading: false,
  registerTelephoneSave: "",
  registerTelephoneSaveChannel: "",
  AddressInformation: {
    error: false,
    isLoading: false,
  },
}

const SignUpReducer = (
  state: SignUpState = initialState,
  action: SignUpActions
): SignUpState => {
  switch (action.type) {
    case SET_CREDIT_BURO_ACCESS_ERROR_MODAL:
      return {
        ...state,
        creditBuroAccessErrorModal: action.payload,
      }
    case SET_USER_FIRST_TIME_SYNC_INFORMATION:
      return {
        ...state,
        userFirstTimeSync: {
          ...state.userFirstTimeSync,
          isDone: action.payload,
        },
      }
    case SET_USER_FIRST_TIME_SYNC_LOADING:
      return {
        ...state,
        userFirstTimeSync: {
          ...state.userFirstTimeSync,
          isLoading: action.payload,
        },
      }
    case SET_USER_FIRST_TIME_SYNC_ERROR:
      return {
        ...state,
        userFirstTimeSync: {
          ...state.userFirstTimeSync,
          error: action.payload,
        },
      }
    case SET_GLOBAL_SIGNUP_STEP:
      return {
        ...state,
        globalSignUpStep: action.payload,
      }
    case SET_ACCOUNT_CREATED_MODAL:
      return {
        ...state,
        accountCreatedModal: action.payload,
      }
    case SET_ACCOUNT_ADDED_MODAL:
      return {
        ...state,
        accountAddedModal: action.payload,
      }
    case SET_PERSONAL_DATA_STEP:
      return {
        ...state,
        personalDataStep: action.payload,
      }
    case SET_SIGNUP_SUCCESS:
      return {
        ...state,
        finerio: {
          ...state.finerio,
          isFinerioRequested: true,
          isFinerioSuccess: true,
          finerioId: action.payload,
        },
      }
    case SET_CREDIT_BUILDER_STEP:
      return {
        ...state,
        creditBuilderStep: action.payload
        }
    case SET_SIGNUP_FINERIO_ERROR:
      return {
        ...state,
        finerio: {
          ...state.finerio,
          isFinerioRequested: true,
          isFinerioSuccess: false,
          isFinerioErrorModal: true,
        },
      }
    case SET_SIGNUP_FINERIO_ERROR_MODAL:
      return {
        ...state,
        finerio: {
          ...state.finerio,
          isFinerioErrorModal: action.payload,
        },
      }
    case SET_SIGNUP_FINERIO_NO_ACCOUNT_MODAL:
      return {
        ...state,
        finerio: {
          ...state.finerio,
          isFinerioNoAccountModal: action.payload,
        },
      }
    case SET_SIGNUP_FINERIO_IS_REQUESTED:
      return {
        ...state,
        finerio: {
          ...state.finerio,
          isFinerioRequested: action.payload,
        },
      }
    case SET_SIGNUP_FINERIO_IS_TIMEOUT:
      return {
        ...state,
        finerio: {
          ...state.finerio,
          isFinerioTimeout: action.payload,
        },
      }
    case SET_SIGNUP_SET_FINERIO_REQUEST:
      return {
        ...state,
        finerio: {
          ...state.finerio,
          setFinerioId: {
            ...state.finerio.setFinerioId,
            isLoading: true,
            isError: false,
            isScuccess: false,
          },
        },
      }
    case SET_SIGNUP_SET_FINERIO_SUCCESS:
      return {
        ...state,
        finerio: {
          ...state.finerio,
          setFinerioId: {
            ...state.finerio.setFinerioId,
            isLoading: false,
            isError: false,
            isScuccess: true,
          },
        },
      }
    case SET_SIGNUP_SET_FINERIO_ERROR:
      return {
        ...state,
        finerio: {
          ...state.finerio,
          setFinerioId: {
            ...state.finerio.setFinerioId,
            isLoading: false,
            isError: true,
            isScuccess: false,
          },
        },
      }
    case SET_SIGNUP_SKIP_BANK_ACCOUNT_LOADING:
      return {
        ...state,
        finerio: {
          ...state.finerio,
          isSkipFinerioLoading: action.payload,
        },
      }
    case SET_SIGNUP_SET_FINERIO_WELCOME:
      return {
        ...state,
        finerio: {
          ...state.finerio,
          isFinerioWelcomeScreen: action.payload,
        },
      }
    case SET_PERSONAL_DATA_SUCCESS:
      return {
        ...state,
        personalData: { name: action.payload },
      }
    case SET_PERSONAL_DATA_LOADING:
      return {
        ...state,
        personalDataLoading: action.payload,
      }
    case SET_PERSONAL_DATA_ERROR:
      return {
        ...state,
        personalDataError: action.payload,
      }
    case SET_USER_INFORMATION:
      return {
        ...state,
        registerUser: {
          data: {
            id: action.payload.id,
            email: action.payload.email,
          },
          error: false,
          isLoading: false,
        },
      }
    case SET_REGISTER_ERROR:
      return {
        ...state,
        registerUser: { data: null, error: true, isLoading: false },
      }
    case SET_REGISTER_LOADING:
      return {
        ...state,
        registerUser: { data: null, error: false, isLoading: true },
      }
    case SET_CITIES_INFORMATION:
      return {
        ...state,
        CitiesByZipCode: {
          data: { ...action.payload },
          error: false,
          isLoading: false,
        },
      }
    case SET_CITIES_LOADING:
      return {
        ...state,
        CitiesByZipCode: { data: null, error: false, isLoading: true },
      }
    case SET_CITIES_ERROR:
      return {
        ...state,
        CitiesByZipCode: { data: null, error: true, isLoading: false },
      }
    case SET_ADDRESS_SUCCESS:
      return {
        ...state,
        AddressInformation: { isLoading: false, error: false },
      }
    case SET_ADDRESS_LOADING:
      return {
        ...state,
        AddressInformation: { isLoading: true, error: false },
      }
    case SET_ADDRESS_ERROR:
      return {
        ...state,
        AddressInformation: { isLoading: false, error: true },
      }
    case SET_CREDITSCORE_AGREEMENTS_INFORMATION:
      return {
        ...state,
        creditScoreAgreements: {
          data: { ...action.payload },
          error: false,
          isLoading: false,
        },
      }
    case SET_CREDITSCORE_AGREEMENTS_LOADING:
      return {
        ...state,
        creditScoreAgreements: { data: null, error: false, isLoading: true },
      }
    case SET_CREDITSCORE_AGREEMENTS_ERROR:
      return {
        ...state,
        creditScoreAgreements: { data: null, error: true, isLoading: false },
      }
    case SET_GRANT_SCORE_PRIV_INFORMATION:
      return {
        ...state,
        grantScorePriv: {
          data: action.payload,
          error: false,
          isLoading: false,
        },
      }
    case SET_CLEAN_GRANT_SCORE_PRIV_INFORMATION:
      return {
        ...state,
        grantScorePriv: { data: { ok: false }, error: false, isLoading: false },
      }
    case SET_GRANT_SCORE_PRIV_LOADING:
      return {
        ...state,
        grantScorePriv: {
          data: state.grantScorePriv.data,
          error: false,
          isLoading: true,
        },
      }
    case SET_GRANT_SCORE_PRIV_ERROR:
      return {
        ...state,
        grantScorePriv: {
          data: state.grantScorePriv.data,
          error: true,
          isLoading: false,
        },
      }
    case SET_EMAIL_VERIFY_LOADING:
      return {
        ...state,
        emailVerifyLoading: action.payload,
      }
    case SET_EMAIL_VERIFY_ERROR:
      return {
        ...state,
        emailVerifyError: action.payload,
      }
    case SET_RESEND_CODE_EMAIL_LOADING:
      return {
        ...state,
        emailCodeResendLoading: action.payload,
      }
    case SET_RESEND_CODE_EMAIL_ERROR:
      return {
        ...state,
        emailCodeResendError: action.payload,
      }
    case SET_RECOVER_PASSWORD_STEP:
      return {
        ...state,
        recoverPasswordStep: action.payload,
      }
    case SET_RECOVER_PASSWORD_LOADING:
      return {
        ...state,
        recoverPassword: {
          error: false,
          isLoading: true,
          errorText: state.recoverPassword.errorText,
          code: state.recoverPassword.code,
          email: state.recoverPassword.email,
        },
      }
    case SET_RECOVER_PASSWORD_ERROR:
      return {
        ...state,
        recoverPassword: {
          error: true,
          isLoading: false,
          errorText: action.payload,
          code: state.recoverPassword.code,
          email: state.recoverPassword.email,
        },
      }
    case SET_RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        recoverPassword: {
          error: false,
          isLoading: false,
          errorText: "",
          code: state.recoverPassword.code,
          email: state.recoverPassword.email,
        },
      }
    case SET_RECOVER_PASSWORD_CODE:
      return {
        ...state,
        recoverPassword: {
          error: false,
          isLoading: false,
          errorText: state.recoverPassword.errorText,
          code: action.payload,
          email: state.recoverPassword.email,
        },
      }
    case SET_RECOVER_PASSWORD_EMAIL:
      return {
        ...state,
        recoverPassword: {
          error: false,
          isLoading: state.recoverPassword.isLoading,
          errorText: state.recoverPassword.errorText,
          code: state.recoverPassword.code,
          email: action.payload,
        },
      }
    case SET_REGISTER_TELEPHONE_LOADING:
      return {
        ...state,
        registerTelephoneLoading: action.payload,
      }
    case SET_REGISTER_TELEPHONE_SAVE:
      return {
        ...state,
        registerTelephoneSave: action.payload,
      }
    case SET_REGISTER_TELEPHONE_SAVE_CHANNEL:
      return {
        ...state,
        registerTelephoneSaveChannel: action.payload,
      }
    default:
      return state
  }
}

export default SignUpReducer
