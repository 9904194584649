import { MyGpsTimeframes } from "utils/helpers/mygps.helpers"
import { MyGpsState, MyGpsActions } from "./mygps.model"

export const SET_GLOBAL_MYGPS_TIMEFRAME = "SET_GLOBAL_MYGPS_TIMEFRAME_STEP"
export const SET_MYGPS_USER_SCORE_INFORMATION =
  "SET_MYGPS_USER_SCORE_INFORMATION"
export const SET_MYGPS_USER_SCORE_LOADING = "SET_MYGPS_USER_SCORE_LOADING"
export const SET_MYGPS_USER_SCORE_ERROR = "SET_MYGPS_USER_SCORE_ERROR"
export const SET_MYGPS_RISK_LEVELS_INFORMATION =
  "SET_MYGPS_RISK_LEVELS_INFORMATION"
export const SET_MYGPS_RISK_LEVELS_LOADING = "SET_MYGPS_RISK_LEVELS_LOADING"
export const SET_MYGPS_RISK_LEVELS_ERROR = "SET_MYGPS_RISK_LEVELS_ERROR"
export const SET_MYGPS_EXPENSES_INFORMATION = "SET_MYGPS_EXPENSES_INFORMATION"
export const SET_MYGPS_EXPENSES_LOADING = "SET_MYGPS_EXPENSES_LOADING"
export const SET_MYGPS_EXPENSES_ERROR = "SET_MYGPS_EXPENSES_ERROR"
export const SET_MYGPS_ACTIVE_EXPENSE_CATEGORY =
  "SET_MYGPS_ACTIVE_EXPENSE_CATEGORY"
export const SET_MYGPS_TRANSACTIONS_INFORMATION =
  "SET_MYGPS_TRANSACTIONS_INFORMATION"
export const SET_MYGPS_TRANSACTIONS_LOADING = "SET_MYGPS_TRANSACTIONS_LOADING"
export const SET_MYGPS_TRANSACTIONS_ERROR = "SET_MYGPS_TRANSACTIONS_ERROR"
export const SET_MYGPS_BANK_ACCOUNT_INDEX = "SET_MYGPS_BANK_ACCOUNT_INDEX"
export const SET_MYGPS_MONTHLY_TIME_FILTERS = "SET_MYGPS_MONTHLY_TIME_FILTERS"
export const SET_MYGPS_QUARTERLY_TIME_FILTERS =
  "SET_MYGPS_QUARTERLY_TIME_FILTERS"
export const SET_MYGPS_TRANSACTIONS_RANGE_INFORMATION =
  "SET_MYGPS_TRANSACTIONS_RANGE_INFORMATION"
export const SET_MYGPS_TRANSACTIONS_RANGE_LOADING =
  "SET_MYGPS_TRANSACTIONS_RANGE_LOADING"
export const SET_MYGPS_TRANSACTIONS_RANGE_ERROR =
  "SET_MYGPS_TRANSACTIONS_RANGE_ERROR"
export const SET_MYGPS_NEW_BANKACCOUNT_REQUEST =
  "SET_MYGPS_NEW_BANKACCOUNT_REQUEST"
export const SET_MYGPS_NEW_BANKACCOUNT_ERROR = "SET_MYGPS_NEW_BANKACCOUNT_ERROR"
export const SET_MYGPS_NEW_BANKACCOUNT_SUCCESS =
  "SET_MYGPS_NEW_BANKACCOUNT_SUCCESS"
export const SET_MYGPS_WITH_FINERIOID = "SET_MYGPS_WITH_FINERIOID"

const initialState: MyGpsState = {
  globalMyGpsTimeframe: MyGpsTimeframes.monthly,
  userScore: {
    score: null,
    error: false,
    isLoading: false,
  },
  riskLevels: {
    riskLevels: null,
    error: false,
    isLoading: false,
  },
  expenses: {
    expenses: null,
    error: false,
    isLoading: false,
  },
  activeExpenseCategory: null,
  transactionsByCategory: {
    transactions: null,
    error: false,
    isLoading: false,
  },
  accountIndex: 0,
  monthlyTimeFilters: {
    startDate: "",
    endDate: "",
  },
  quarterlyTimeFilters: {
    startDate: "",
    endDate: "",
  },
  transactionsRange: {
    range: {
      recentTransactionDate: "",
      lastTransactionDate: "",
    },
    error: false,
    isLoading: false,
  },
  newBankAccount: {
    isRequested: false,
    isLoading: false,
    isError: false,
    isSuccess: false,
    finerioIdSkipAccount: "",
  },
  withFinerioUUID: false,
}

const MyGpsReducer = (
  state: MyGpsState = initialState,
  action: MyGpsActions
): MyGpsState => {
  switch (action.type) {
    case SET_GLOBAL_MYGPS_TIMEFRAME:
      return {
        ...state,
        globalMyGpsTimeframe: action.payload,
      }
    case SET_MYGPS_USER_SCORE_INFORMATION:
      return {
        ...state,
        userScore: { ...state.userScore, score: action.payload },
      }
    case SET_MYGPS_USER_SCORE_LOADING:
      return {
        ...state,
        userScore: { ...state.userScore, isLoading: action.payload },
      }
    case SET_MYGPS_USER_SCORE_ERROR:
      return {
        ...state,
        userScore: { ...state.userScore, error: action.payload },
      }
    case SET_MYGPS_RISK_LEVELS_INFORMATION:
      return {
        ...state,
        riskLevels: { ...state.riskLevels, riskLevels: action.payload },
      }
    case SET_MYGPS_RISK_LEVELS_LOADING:
      return {
        ...state,
        riskLevels: { ...state.riskLevels, isLoading: action.payload },
      }
    case SET_MYGPS_RISK_LEVELS_ERROR:
      return {
        ...state,
        riskLevels: { ...state.riskLevels, error: action.payload },
      }
    case SET_MYGPS_EXPENSES_INFORMATION:
      return {
        ...state,
        expenses: { ...state.expenses, expenses: action.payload },
      }
    case SET_MYGPS_EXPENSES_LOADING:
      return {
        ...state,
        expenses: { ...state.expenses, isLoading: action.payload },
      }
    case SET_MYGPS_EXPENSES_ERROR:
      return {
        ...state,
        expenses: { ...state.expenses, error: action.payload },
      }
    case SET_MYGPS_ACTIVE_EXPENSE_CATEGORY:
      return {
        ...state,
        activeExpenseCategory: action.payload,
      }
    case SET_MYGPS_TRANSACTIONS_INFORMATION:
      return {
        ...state,
        transactionsByCategory: {
          ...state.transactionsByCategory,
          transactions: action.payload,
        },
      }
    case SET_MYGPS_TRANSACTIONS_LOADING:
      return {
        ...state,
        transactionsByCategory: {
          ...state.transactionsByCategory,
          isLoading: action.payload,
        },
      }
    case SET_MYGPS_TRANSACTIONS_ERROR:
      return {
        ...state,
        transactionsByCategory: {
          ...state.transactionsByCategory,
          error: action.payload,
        },
      }
    case SET_MYGPS_TRANSACTIONS_RANGE_INFORMATION:
      return {
        ...state,
        transactionsRange: {
          ...state.transactionsRange,
          range: action.payload,
        },
      }
    case SET_MYGPS_TRANSACTIONS_RANGE_LOADING:
      return {
        ...state,
        transactionsRange: {
          ...state.transactionsRange,
          isLoading: action.payload,
        },
      }
    case SET_MYGPS_TRANSACTIONS_RANGE_ERROR:
      return {
        ...state,
        transactionsByCategory: {
          ...state.transactionsByCategory,
          error: action.payload,
        },
      }
    case SET_MYGPS_BANK_ACCOUNT_INDEX:
      return {
        ...state,
        accountIndex: action.payload,
      }
    case SET_MYGPS_MONTHLY_TIME_FILTERS:
      return {
        ...state,
        monthlyTimeFilters: { ...action.payload },
      }
    case SET_MYGPS_QUARTERLY_TIME_FILTERS:
      return {
        ...state,
        quarterlyTimeFilters: { ...action.payload },
      }
    case SET_MYGPS_NEW_BANKACCOUNT_REQUEST:
      return {
        ...state,
        newBankAccount: {
          ...state.newBankAccount,
          isLoading: true,
          isError: false,
          isRequested: false,
          isSuccess: false,
        },
      }
    case SET_MYGPS_NEW_BANKACCOUNT_SUCCESS:
      return {
        ...state,
        newBankAccount: {
          ...state.newBankAccount,
          isLoading: false,
          isError: false,
          isRequested: true,
          isSuccess: true,
        },
      }
    case SET_MYGPS_NEW_BANKACCOUNT_ERROR:
      return {
        ...state,
        newBankAccount: {
          ...state.newBankAccount,
          isLoading: false,
          isError: true,
          isRequested: true,
        },
      }
    case SET_MYGPS_WITH_FINERIOID:
      return {
        ...state,
        withFinerioUUID: action.payload,
      }
    default:
      return state
  }
}

export default MyGpsReducer
