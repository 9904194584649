import { InfoUserModel, UserActions } from "./user.model";

export const GET_INFO_USER = "GET_INFO_USER";

const initialState: InfoUserModel = {
  firstName: "",
  middleName: "",
  lastName: "",
  secondLastName: "",
  email: "",
  phoneNumber: "",
  address: {
    postalCode: "",
    street: "",
    extNumber: "",
    district: "",
    city: "",
    state: "",
  }
}

const UserReducer = (state:InfoUserModel = initialState, action: UserActions ) => {
  switch(action.type){
    case GET_INFO_USER:
      return {
        ...state,
        firstName: action.payload.firstName,
        middleName: action.payload.middleName,
        lastName: action.payload.lastName,
        secondLastName: action.payload.secondLastName,
        email: action.payload.email,
        phoneNumber: action.payload.phoneNumber,
        addres: action.payload.address,
      }
    default:
      return state;
  }
}

export default UserReducer;