import { AppStates } from "../../utils/helpers/app.helpers";
import { AuthActions, AuthState } from "./auth.model";

export const SET_APP_STATE = "SET_APP_STATE";
export const SET_LOGIN_LOADING = "SET_LOGIN_LOADING";
export const SET_LOGIN_SUCCESS = "SET_LOGIN_SUCCESS";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const SET_USER_LOGOUT = "SET_USER_LOGOUT";
export const SET_WRONG_CREDENTIALS_MODAL = "SET_WRONG_CREDENTIALS_MODAL";
export const SET_ALREADY_CREATED_MODAL = "SET_ALREADY_CREATED_MODAL"
const initialState: AuthState = {
  appState: AppStates.initialState,
  authentication: {
    isLoading: false,
    data: { id: -1, jwt: '', userName: '', firstName: '', email: '', finerioUUID: undefined},
    isError: false
  },
  wrongCredentialsModal: false,
  accountAlreadyCreatedModal: false
}

const AuthReducer = (state: AuthState = initialState, action: AuthActions ): AuthState => {
  switch(action.type){
    case SET_WRONG_CREDENTIALS_MODAL:
      return {
        ...state,
        wrongCredentialsModal: action.payload
      }
    case SET_USER_LOGOUT:
      return {
        ...initialState,
        appState: AppStates.initialState,
      }
    case SET_APP_STATE:
      return {
        ...state,
        appState: action.payload
      }
    case SET_LOGIN_LOADING:
      return {
        ...state,
        authentication: {
          ...state.authentication,
          isLoading: true,
          isError: false
        }
      }
    case SET_LOGIN_SUCCESS:
      return {
        ...state,
        authentication: {
          ...state.authentication,
          isLoading: false,
          isError: false,
          data: {...action.payload}
        }
      }
    case SET_LOGIN_ERROR:
      return {
        ...state,
        authentication: {
          ...state.authentication,
          isLoading: false,
          isError: true
        }
      }
    case SET_ALREADY_CREATED_MODAL:
      return{
        ...state,
        accountAlreadyCreatedModal: action.payload
      }
    default:
      return state;
  }
}

export default AuthReducer;