import axios from "axios"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import UelButton from "components/Shared/UelButton/UelButton"
import UelLinkButton from "components/Shared/UelLinkButton/UelLinkButton"
import UelBackButton from "components/Shared/UelBackButton/UelBackButton"
import UelTextInput from "components/FormComponents/UelTextInput/UelTextInput"
import UelDropdown from "components/FormComponents/UelDropdown/UelDropdown"
import UelCheckBox from "components/FormComponents/UelCheckBox/UelCheckBox"
import withTextInputController from "utils/hocs/withControllerInput"
import withDropdownController from "utils/hocs/withDropdownController"
import withCheckboxController from "utils/hocs/withCheckboxController"
import "./styles.scss"
import { FormattedMessage } from "react-intl"
import { SignUpCreditBuilderSteps } from "utils/helpers/signup.helpers"
import { setCreditBuilderStep } from "store/SignUp/signup.actions"
import { RootState } from "store/reducers"
import * as yup from "yup"
import LoadingScreen from "screens/LoadingScreen/LoadingScreen"


const ControllerTextInput = withTextInputController(UelTextInput)
const ControllerDropdown = withDropdownController(UelDropdown)
const ControllerCheckBox = withCheckboxController(UelCheckBox)

interface BillingInfoFormModel {
  clabe: string
  cuenta: string
  banco: string
}

const CreditBuilderBillingInfoContainer = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  console.log("Component render")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const url = process.env.REACT_APP_CB_LAMBDA_KUSPIT_URL
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)

  const validationSchema = yup.object().shape({
    clabe: yup
      .string()
      .required("CLABE is required")
      .length(18, "La CLABE debe ser de 18 dígitos")
      .matches(/^\d+$/, "La CLABE solo puede contener dígitos"),
    cuenta: yup.string().required("El número de cuenta es requerido"),
    banco: yup.string().required("El nombre del banco es requerido"),
  })

  const methods = useForm<BillingInfoFormModel>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  })

  const {
    handleSubmit,
    formState,
    control,
    resetField,
    watch,
    setValue,
    trigger,
  } = methods
  const { errors } = formState

  const bancoSource = [
    {
      label: "ABA",
      value: "90700",
    },
    {
      label: "ABC CAPITAL",
      value: "40138",
    },
    {
      label: "ACCENDO BANCO",
      value: "40102",
    },
    {
      label: "ACCIVAL",
      value: "90614",
    },
    {
      label: "ACTINVER",
      value: "40133",
    },
    {
      label: "ACTINVER-LLOYD",
      value: "90622",
    },
    {
      label: "AFIRME",
      value: "40062",
    },
    {
      label: "AKALA",
      value: "90638",
    },
    {
      label: "AMERICAN EXPRESS",
      value: "40103",
    },
    {
      label: "ASEA",
      value: "90652",
    },
    {
      label: "ASP-FINANCIERA",
      value: "90659",
    },
    {
      label: "AUTOFIN",
      value: "40128",
    },
    {
      label: "AXA",
      value: "90674",
    },
    {
      label: "AZTECA",
      value: "40127",
    },
    {
      label: "B&B",
      value: "90610",
    },
    {
      label: "BAJIO",
      value: "40030",
    },
    {
      label: "BANAMEX",
      value: "40002",
    },
    {
      label: "BANCO FINTERRA",
      value: "40154",
    },
    {
      label: "BANCOMEXT",
      value: "37006",
    },
    {
      label: "BANCOPPEL",
      value: "40137",
    },
    {
      label: "BANCREA",
      value: "40152",
    },
    {
      label: "BANJERCITO",
      value: "37019",
    },
    {
      label: "BANK OF AMERICA",
      value: "40106",
    },
    {
      label: "BANKAOOL",
      value: "40147",
    },
    {
      label: "BANOBRAS",
      value: "37009",
    },
    {
      label: "BANORTE",
      value: "40072",
    },
    {
      label: "BANREGIO",
      value: "40058",
    },
    {
      label: "BANSEFI",
      value: "37166",
    },
    {
      label: "BANSI",
      value: "40060",
    },
    {
      label: "BANXICO",
      value: "2001",
    },
    {
      label: "BARCLAYS",
      value: "40129",
    },
    {
      label: "BBASE",
      value: "40145",
    },
    {
      label: "BBVA BANCOMER",
      value: "40012",
    },
    {
      label: "BICENTENARIO",
      value: "40146",
    },
    {
      label: "BMONEX",
      value: "40112",
    },
    {
      label: "BTG PACTUAL",
      value: "90691",
    },
    {
      label: "CB ACTINVER",
      value: "90621",
    },
    {
      label: "CB JPMORGAN",
      value: "90640",
    },
    {
      label: "CBDEUTSCHE",
      value: "90626",
    },
    {
      label: "CBMULTIVA",
      value: "90613",
    },
    {
      label: "CIBANCO",
      value: "40143",
    },
    {
      label: "CLSBANK",
      value: "90901",
    },
    {
      label: "COMPARTAMOS",
      value: "40130",
    },
    {
      label: "CONSUBANCO",
      value: "40140",
    },
    {
      label: "CREDIT SUISSE",
      value: "40126",
    },
    {
      label: "DEUTSCHE",
      value: "40124",
    },
    {
      label: "EVERCORE",
      value: "90648",
    },
    {
      label: "FINAMEX",
      value: "90616",
    },
    {
      label: "FINCOMUN",
      value: "90634",
    },
    {
      label: "GBM",
      value: "90601",
    },
    {
      label: "GE MONEY",
      value: "40022",
    },
    {
      label: "GEMELO KUSPIT",
      value: "853",
    },
    {
      label: "HIPOTECARIA FED",
      value: "37168",
    },
    {
      label: "HSBC",
      value: "40021",
    },
    {
      label: "INBURSA",
      value: "40036",
    },
    {
      label: "ING",
      value: "40116",
    },
    {
      label: "INMOBILIARIO",
      value: "40150",
    },
    {
      label: "INTERACCIONES",
      value: "40037",
    },
    {
      label: "INTERCAM BANCO",
      value: "40136",
    },
    {
      label: "INVEX",
      value: "40059",
    },
    {
      label: "IXE",
      value: "40032",
    },
    {
      label: "JP MORGAN",
      value: "40110",
    },
    {
      label: "KUSPIT",
      value: "90653",
    },
    {
      label: "LIBERTAD",
      value: "90670",
    },
    {
      label: "MASARI",
      value: "90602",
    },
    {
      label: "MERRILL LYNCH",
      value: "90615",
    },
    {
      label: "MIFEL",
      value: "40042",
    },
    {
      label: "MONEXCB",
      value: "90600",
    },
    {
      label: "MUFG",
      value: "40108",
    },
    {
      label: "MULTIVA BANCO",
      value: "40132",
    },
    {
      label: "NAFIN",
      value: "37135",
    },
    {
      label: "PAGATODO",
      value: "40148",
    },
    {
      label: "SANTANDER",
      value: "40014",
    },
    {
      label: "SCOTIABANK",
      value: "40044",
    },
    {
      label: "SKANDIA",
      value: "90623",
    },
    {
      label: "STERLING",
      value: "90633",
    },
    {
      label: "STP",
      value: "90646",
    },
    {
      label: "SU CASITA",
      value: "90629",
    },
    {
      label: "TIBER",
      value: "90607",
    },
    {
      label: "UBS BANK",
      value: "40139",
    },
    {
      label: "UNICA",
      value: "90618",
    },
    {
      label: "VALMEX",
      value: "90617",
    },
    {
      label: "VALUE",
      value: "90605",
    },
    {
      label: "VE POR MAS",
      value: "40113",
    },
    {
      label: "VECTOR",
      value: "90608",
    },
    {
      label: "VOLKSWAGEN",
      value: "40141",
    },
    {
      label: "WAL-MART",
      value: "40134",
    },
    {
      label: "ZURICH",
      value: "90627",
    },
  ]

  const bankDict: { [key: string]: string } = {
    "002": "BANAMEX",
    "006": "BANCOMEXT",
    "009": "BANOBRAS",
    "012": "BBVA BANCOMER",
    "014": "SANTANDER",
    "019": "BANJERCITO",
    "021": "HSBC",
    "030": "BAJÍO",
    "036": "INBURSA",
    "042": "MIFEL",
    "044": "SCOTIABANK",
    "058": "BANREGIO",
    "059": "INVEX",
    "060": "BANSI",
    "062": "AFIRME",
    "072": "BANORTE",
    "106": "BANK OF AMERICA",
    "108": "MUFG",
    "110": "JP MORGAN",
    "112": "BMONEX",
    "113": "VE POR MAS",
    "126": "CREDIT SUISSE",
    "127": "AZTECA",
    "128": "AUTOFIN",
    "129": "BARCLAYS",
    "130": "COMPARTAMOS",
    "132": "MULTIVA BANCO",
    "133": "ACTINVER",
    "135": "NAFIN",
    "136": "INTERCAM BANCO",
    "137": "BANCOPPEL",
    "138": "ABC CAPITAL",
    "140": "CONSUBANCO",
    "141": "VOLKSWAGEN",
    "143": "CIBanco",
    "145": "BBASE",
    "147": "BANKAOOL",
    "148": "PagaTodo",
    "150": "INMOBILIARIO",
    "151": "Donde",
    "152": "BANCREA",
    "154": "BANCO COVALTO",
    "155": "ICBC",
    "156": "SABADELL",
    "157": "SHINHAN",
    "158": "MIZUHO BANK",
    "159": "BANK OF CHINA",
    "160": "BANCO S3",
    "166": "Banco del Bienestar",
    "168": "HIPOTECARIA FEDERAL",
    "600": "MONEXCB",
    "601": "GBM",
    "602": "MASARI CB",
    "605": "VALUÉ",
    "608": "VECTOR",
    "610": "B&B",
    "613": "MULTIVA CBOLSA",
    "616": "FINAMEX",
    "617": "VALMEX",
    "618": "ÚNICA",
    "619": "MAPFRE",
    "620": "PROFUTURO",
    "621": "CB ACTINBER",
    "622": "OACTIN",
    "623": "SKANDIA",
    "626": "CBDEUTSCHE",
    "627": "ZURICH",
    "628": "ZURICHVI",
    "629": "SU CASITA",
    "630": "C.B. INTERCAM",
    "631": "C.I. BOLSA",
    "632": "BULLTICK C.B.",
    "633": "STERLING",
    "634": "FINCOMUN",
    "636": "HDI SEGUROS",
    "637": "ORDER",
    "638": "AKALA",
    "640": "C.B. JP MORGAN",
    "642": "REFORMA",
    "646": "STP",
    "647": "TELECOMM",
    "648": "EVERCORE",
    "649": "SKANDIA",
    "651": "SEGMTY",
    "652": "ASEA",
    "653": "KUSPIT",
    "655": "SOFIEXPRESS",
    "656": "UNAGRA",
    "659": "OPCIONES EMPRESARIALES DEL NOROESTE",
    "670": "LIBERTAD",
    "674": "AXA",
    "677": "CAJA POP MEXICA",
    "679": "FND",
    "684": "TRANSFER",
    "901": "CLS",
    "902": "INDEVAL",
    "999": "N/A",
  }

  const { idExterno } = useSelector((state: RootState) => ({
    idExterno:
      state.auth.authentication?.data?.id || state.signup.registerUser.data?.id,
  }))

  const submitInfo = (data: BillingInfoFormModel) => {
    setIsLoading(true)
    console.log(data, errors)
    const updatedData = {
      ...data,
      idExterno: idExterno,
    }
    postToLambda(updatedData)
  }

  const postToLambda = async (data: BillingInfoFormModel) => {
    console.log("Calling postToLambda")
    if (!url) {
      throw new Error("REACT_APP_CB_LAMBDA_KUSPIT_URL is not set in .env file")
    }

    const response = await axios.post(
      url,
      { ...data },
      {
        headers: {
          "Content-Type": "application/json",
          password: process.env.REACT_APP_LAMBDAS_PASS || '',
        },
      }
    )
    dispatch(setCreditBuilderStep(SignUpCreditBuilderSteps.kyc))

    return response
  }

  const goBackWithLinkButton = () => {
    // Dispatch action to update credit builder step
    dispatch(setCreditBuilderStep(SignUpCreditBuilderSteps.info))

    // Navigate to /ahorro
    navigate("/ahorro")
  }

  const clabeValue = watch("clabe")

  const [isCuentaDisabled, setIsCuentaDisabled] = useState(false)

  useEffect(() => {
    if (clabeValue && clabeValue.length === 18) {
      
      const bankKey = clabeValue.slice(0, 3) // get the first three digits
      const bankName = bankDict[bankKey]
      if (bankName) {
        const bankObj = bancoSource.find((bank) => bank.label === bankName)
        if (bankObj) {
          setValue("banco", bankObj.value)
          trigger("banco")
        }
      }
      
      const auxCuenta = clabeValue.slice(6, 17)
      
      const cuentaValue = auxCuenta // Extract 11 digits from the 7th digit
      
      setValue("cuenta", auxCuenta)
      setIsCuentaDisabled(true)
    }
  }, [clabeValue, setValue, trigger])

  const cancelCB = () => {
    navigate("/")
  }

  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    setIsFormValid(formState.isValid)
  }, [formState])

  if (isLoading) {
    return <LoadingScreen />;  // You need to create or import this LoadingScreen component
  }

  return (
    <div className="form-kuspit-info">
      <div className="link-button-personal">
        <UelBackButton onClick={goBackWithLinkButton} />
      </div>
      <div>
        <h2 className="datos-personales-cb-head">Domiciliación de pagos</h2>
        <h3 className="datos-personales-cb-head3">
          Por favor autoriza la cuenta bancaria con la que realizaremos el pago
          de tu crédito
        </h3>
      </div>
      <FormProvider {...methods}>
        <form className="form-container" onSubmit={handleSubmit(submitInfo)}>
          <ControllerTextInput
            control={control}
            name={"clabe"}
            isRequired
            label="CLABE"
            placeholder="Ingresa la CLABE"
            errorText={errors.clabe?.message && errors.clabe?.message}
          />
          <ControllerTextInput
            control={control}
            name={"cuenta"}
            isRequired
            label="No. de cuenta"
            placeholder="Confirma el número de cuenta"
            errorText={errors.cuenta?.message && errors.cuenta?.message}
            isDisabled={isCuentaDisabled}
          />
          <ControllerDropdown
            control={control}
            name="banco"
            data={bancoSource}
            label="Banco"
            isRequired
            error={errors.banco?.message}
            placeholder="Confirma el nombre del banco"
          />
          <div className="btn-container">
            <UelButton
              submit
              title="btn.continue.save"
              disabled={!isFormValid}
            />
          </div>
        </form>
      </FormProvider>
      <div className="link-button-personal-quit">
        <UelLinkButton onClick={cancelCB} color="secondary">
          {"No quiero mejorar mi buró"}
        </UelLinkButton>
      </div>
    </div>
  )
}

export default CreditBuilderBillingInfoContainer;