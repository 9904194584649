import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { setMyGpsWithFInerioId } from "store/MyGps/mygps.actions"
import { RootState } from "../../store/reducers"
import { 
  setFinerioRequestested, 
  setFinerioSignUpError, 
  setFinerioSignUpNoAccountModal, 
  setFinerioSignUpSuccess, 
  setSignUpFinerioIsTimeout,
  setPersonalDataStep,
  setFinerioIdAction
} from "../../store/SignUp/signup.actions"
import { ROUTES } from "../helpers/app.helpers"
import { SignUpPersonalDataSteps, SignUpSteps } from "../helpers/signup.helpers"

const FinerioListener = () => {
  const signupState = useSelector((state: RootState) => state.signup.globalSignUpStep);
  const userDataState = useSelector((state: RootState) => state.signup.personalDataStep);
  const isFinerioWelcomeScreen = useSelector((state: RootState) => state.signup.finerio.isFinerioWelcomeScreen);
  const isFinerioRequested = useSelector((state: RootState) => state.signup.finerio.isFinerioRequested);
  const isFinerioSuccess = useSelector((state: RootState) => state.signup.finerio.isFinerioSuccess);
  const isFinerioErrorModal = useSelector((state: RootState) => state.signup.finerio.isFinerioErrorModal);
  const finerioId = useSelector((state: RootState) => state.signup.finerio.finerioId);
  
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const handleFinerioCallback = useCallback((event: any) => {
    console.log("EVENT: ", event)
    if (event.detail.status === "CREDENTIAL_CREATED"){
      setTimeout(() => {
        if(isFinerioSuccess === false && isFinerioErrorModal === false){
          dispatch(setSignUpFinerioIsTimeout(true))
        }        
      }, process.env.REACT_APP_FINERIO_TIMEOUT ? parseInt(process.env.REACT_APP_FINERIO_TIMEOUT) : 360000)
    }

    if (event.detail.status === "SUCCESS") {
      dispatch(setSignUpFinerioIsTimeout(false))
      dispatch(setFinerioSignUpSuccess(event.detail.customerId.toString()))
    }
    
    if(event.detail.status === "FAILURE"){
      dispatch(setSignUpFinerioIsTimeout(false))
      dispatch(setFinerioSignUpError())
    }
  },[dispatch, isFinerioErrorModal, isFinerioSuccess])

  const handleSuccessCloseButton = useCallback(() => {
    if(isFinerioSuccess){
      dispatch(setPersonalDataStep(SignUpPersonalDataSteps.done));
      dispatch(setMyGpsWithFInerioId(true))
      dispatch(setFinerioIdAction(finerioId));
    }else {
      dispatch(setFinerioSignUpNoAccountModal(true))
    }        
  }, [dispatch, isFinerioSuccess, finerioId])

  /* Found finerio listener */
  useEffect(() => {
    if (
      pathname === ROUTES.signUp &&
      signupState === SignUpSteps.userData &&
      userDataState === SignUpPersonalDataSteps.bankData &&
      isFinerioWelcomeScreen === false
    ) {
      setTimeout(() => {
        const component = document.querySelector("fc-bank-aggregation")
        if (component !== null) {
          console.log("CF")
          dispatch(setFinerioRequestested(false))
          dispatch(setSignUpFinerioIsTimeout(false))
          component.addEventListener("onCallback", handleFinerioCallback)

          /* Remove listener */
          if (pathname !== ROUTES.signUp) {
            component.removeEventListener("onCallback", handleFinerioCallback)
          }
        }
      }, 500)
    }
  }, [handleFinerioCallback, pathname, signupState, userDataState, dispatch, isFinerioWelcomeScreen])

  /* Close button with success flow */
  useEffect(() => {
    if (isFinerioRequested === true && pathname !== ROUTES.newBankAccount) {
      const closeButton = document.querySelector("fc-bank-aggregation");
      console.log("CBF")
      if (closeButton !== null) {
        closeButton.addEventListener("onClickExitButton", handleSuccessCloseButton)

        /* Remove listener */
        if (pathname !== ROUTES.signUp) {
          closeButton.removeEventListener(
            "onClickExitButton",
            handleSuccessCloseButton
          )
        }
      }
    }
  }, [isFinerioRequested, navigate, handleSuccessCloseButton, pathname])

  return null
}

export default FinerioListener
