import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import { RootState } from "store/reducers";
import CreditBuilderDescriptionContainer from "../CreditBuilderDescription/CreditBuilderDescriptionContainer";
import CreditBuilderPlanContainer from "../CreditBuilderPlan/CreditBuilderPlanContainer";
import CreditBuilderKYCContainer from "../CreditBuilderKYC/CreditBuilderKYCContainer";
import CreditBuilderKuspitContainer from "../CreditBuilderKuspit/CreditBuilderKuspitContainer";
import CreditBuilderSuccessContainer from "../CreditBuilderSuccess/CreditBuilderSuccessContainer";
import CreditBuilderBillingInfoContainer from "../CreditBuilderBillingInfo/CreditBuilderBillingInfoContainer";
import CreditBuilderPersonalInfoContainer from "../CreditBuilderPersonalInfo/CreditBuilderPersonalInfoContainer";
import { SignUpCreditBuilderSteps } from "utils/helpers/signup.helpers";
// import UserStateBContainer from "../UserStateBContainer/UserStateBContainer";
import { setCreditBuilderStep } from 'store/SignUp/signup.actions';
import CreditBuilderKuspitInfoContainer from "../CreditBuilderKuspitInfo/CreditBuilderKuspitInfoContainer";
import CreditBuilderZapSignContainer from "../CreditBuilderZapSign/CreditBuilderZapSignContainer";

const CreditBuilderContainer = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const creditBuilderStep = useSelector((state: RootState) => state.signup.creditBuilderStep);
  const dispatch = useDispatch()
  const url = process.env.REACT_APP_CB_LAMBDA_KUSPIT_URL || 'default_url';
  // dispatch(setCreditBuilderStep(SignUpCreditBuilderSteps.zapsign));

  const { idExterno} = useSelector((state: RootState) => ({
    idExterno: state.auth.authentication?.data?.id || state.signup.registerUser.data?.id,
  }));

  useEffect(() => {
    // Fetch data from the DB through your lambda function
    console.log(creditBuilderStep)
    if (creditBuilderStep === SignUpCreditBuilderSteps.initial) {
      const retrieveStep = true
      axios.post(url, 
        {
          idExterno: idExterno,
          retrieveStep: retrieveStep
        },{
          headers: {
            'Content-Type': 'application/json',
            'password': process.env.REACT_APP_LAMBDAS_PASS || ''
          }
        }
      )
      .then((response) => {
        // Check the status of the response
        if (response.status === 200) {
          console.log("step response",response.data)
          const stepFromDB = response.data.cb_step; // get cb_step from response data
          // Dispatch action to update the step in the store
          dispatch(setCreditBuilderStep(stepFromDB));
        } else if (response.status === 404) {
          // If status is 404, set creditBuilderStep to "description"
          dispatch(setCreditBuilderStep(SignUpCreditBuilderSteps.description));
        }
      })
      .catch((error) => {
        console.error('Error fetching step from DB:', error);
      });
    }
  }, [dispatch, idExterno, creditBuilderStep]);


  // dispatch(setCreditBuilderStep(SignUpCreditBuilderSteps.description))
  // Print the current step whenever the component is loaded or creditBuilderStep changes
  useEffect(() => {
    console.log('Current credit builder step:', creditBuilderStep);
  }, [creditBuilderStep]);

  switch (creditBuilderStep) {
    case SignUpCreditBuilderSteps.description:
      return <CreditBuilderDescriptionContainer />
      {/*return <CreditBuilderDescriptionContainer />*/}
    case SignUpCreditBuilderSteps.plan:
      return <CreditBuilderPlanContainer />
    case SignUpCreditBuilderSteps.info:
      return <CreditBuilderKuspitInfoContainer />
    case SignUpCreditBuilderSteps.personal:
      return <CreditBuilderPersonalInfoContainer />
    case SignUpCreditBuilderSteps.kuspit:
      return <CreditBuilderKuspitContainer />
    case SignUpCreditBuilderSteps.billing:
      return <CreditBuilderBillingInfoContainer/>
    case SignUpCreditBuilderSteps.kyc:
      return <CreditBuilderKYCContainer />
    case SignUpCreditBuilderSteps.zapsign:
      return <CreditBuilderZapSignContainer />
    case SignUpCreditBuilderSteps.completed:
      return <CreditBuilderSuccessContainer />
    default:
      return <CreditBuilderDescriptionContainer />
  }
}

export default CreditBuilderContainer;



