import ProfileContainer from 'containers/ProfileContainer/ProfileContainer'
import { AppStates } from 'utils/helpers/app.helpers'
import withProtectedRoute from 'utils/hocs/withProtectedRoute'

const ProfileScreen = () => {
  return (
    <ProfileContainer/>
  )
}

export default withProtectedRoute(ProfileScreen, AppStates.homeState)