import React from "react"
import { UnstyledButton, Text } from "@mantine/core"
import "./styles.scss"
import { FormattedMessage, useIntl } from "react-intl"

export interface UelBackButtonProps{
  onClick: () => void
}

const UelBackButton = ({onClick}:UelBackButtonProps) => {
  const {formatMessage} = useIntl();
  return (
    <UnstyledButton onClick={onClick} className="backbutton-container">
      <img className="icon-button" src="/icons/left_arrow.svg" alt={formatMessage({id: "alt.backbutton.icon"})} />
      <Text className="backbutton-text"><FormattedMessage id="btn.back"/></Text>
    </UnstyledButton>
  )
}

export default UelBackButton
