import { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FormattedMessage } from "react-intl"
import useGetAccountIds from "hooks/useGetAccountIds"
import useTransactionDates from "hooks/useTransactionDates"
import { MyGpsTimeframes } from "utils/helpers/mygps.helpers"
import { RootState } from "store/reducers"
import {
  getMyGpsTransactions,
  setTransactionsInformation,
} from "store/MyGps/mygps.actions"
import UelBalanceCard from "components/Shared/UelBalanceCard/UelBalanceCard"
import UelLoader from "components/Shared/UelLoader/UelLoader"
import UelCategoryTip from "components/Shared/UelCategoryTip/UelCategoryTip"
import UelDropdown from "components/FormComponents/UelDropdown/UelDropdown"
import GpsTransactionsList from "components/MyGps/GpsTransactionsList/GpsTransactionsList"
import "./style.scss"

const TransactionDetailsContainer = () => {
  const dispatch = useDispatch()

  const { loading, error, accountIdsData } = useGetAccountIds()

  const timeframe = useSelector(
    (state: RootState) => state.mygps.globalMyGpsTimeframe
  )

  const transactionRangeState = useSelector(
    (state: RootState) => state.mygps.transactionsRange
  )

  const activeCategory = useSelector(
    (state: RootState) => state.mygps.activeExpenseCategory
  )

  const accountIndex = useSelector(
    (state: RootState) => state.mygps.accountIndex
  )

  const transactionsState = useSelector(
    (state: RootState) => state.mygps.transactionsByCategory
  )

  const totalIncome = useSelector(
    (state: RootState) => state.mygps.expenses.expenses?.totalIncome!
  )

  const totalExpenses = useSelector(
    (state: RootState) => state.mygps.expenses.expenses?.totalExpenses!
  )

  const monthlyTimeFilters = useSelector(
    (state: RootState) => state.mygps.monthlyTimeFilters
  )

  const quarterlyTimeFilters = useSelector(
    (state: RootState) => state.mygps.quarterlyTimeFilters
  )

  const {
    timeframeFilterOptions,
    mainTimeRangeIndex,
    changeActiveTimeframeFilter,
  } = useTransactionDates(
    timeframe,
    transactionRangeState.range?.recentTransactionDate!
  )

  const [isExpensesPercentage, setIsExpensesPercentage] = useState(false)

  const tipPorcentage = useMemo(() => {
    let percentage = (
      (Math.abs(transactionsState?.transactions?.data?.totalExpenses!) * 100) /
      totalIncome
    ).toFixed(2)

    if (percentage === "NaN" || percentage === "Infinity") {
      setIsExpensesPercentage(true)
      return (
        (transactionsState?.transactions?.data?.totalExpenses! * 100) /
        totalExpenses
      ).toFixed(2)
    } else {
      setIsExpensesPercentage(false)
      return percentage
    }
  }, [
    totalIncome,
    totalExpenses,
    transactionsState?.transactions?.data?.totalExpenses,
  ])

  const activeAccountLabel = useMemo(
    () =>
      accountIdsData !== null && accountIdsData.length > 0
        ? `Cuenta ****${accountIdsData[accountIndex!].number.toString()}`
        : null,
    [accountIdsData, accountIndex]
  )

  const transactionsLoading = transactionsState.isLoading

  useEffect(() => {
    const { startDate, endDate } =
      timeframe === MyGpsTimeframes.monthly
        ? monthlyTimeFilters
        : quarterlyTimeFilters

    accountIdsData !== null &&
      accountIdsData.length > 0 &&
      dispatch(
        getMyGpsTransactions({
          startDate,
          endDate,
          accountId: accountIdsData![accountIndex!].accountId,
          categoryId: activeCategory?.id!,
        })
      )
  }, [
    dispatch,
    accountIdsData,
    accountIndex,
    activeCategory,
    monthlyTimeFilters,
    quarterlyTimeFilters,
    timeframe,
  ])

  useLayoutEffect(() => {
    dispatch(setTransactionsInformation(null))
  }, [dispatch])

  return (
    <div className="container-transaction-details">
      {loading ||
      accountIdsData === null ||
      transactionRangeState.range?.recentTransactionDate === "" ? (
        <UelLoader size="lg" />
      ) : error || transactionRangeState.error ? (
        <h2>
          <FormattedMessage id="screens.mygps.data.error"></FormattedMessage>
        </h2>
      ) : transactionsState?.transactions === null ? (
        <UelLoader size="lg" />
      ) : (
        <>
          <UelDropdown
            label="screens.mygps.filters.timeframe.label"
            data={timeframeFilterOptions!}
            defaultValue={timeframeFilterOptions![mainTimeRangeIndex!].value!}
            onChange={changeActiveTimeframeFilter}
            alterStyle
          />
          <>
            <div className="balance-wrapper">
              {transactionsLoading ? (
                <UelLoader size="sm" />
              ) : (
                <>
                  <UelBalanceCard
                    figure={
                      transactionsState?.transactions?.data?.totalExpenses!
                    }
                    alternateLabel="screens.mygps.transactions.total.expenses.label"
                  />
                  <UelCategoryTip
                    text="screens.mygps.filters.transaction.details.n.tip"
                    percentage={tipPorcentage}
                    isExpense={isExpensesPercentage}
                    tip={`screens.mygps.filters.transaction.details.tip.${activeCategory?.name}`}
                  />
                </>
              )}
            </div>
            <div
              className={`transactions-wrapper ${
                transactionsLoading ? "tall" : ""
              }`}>
              {transactionsLoading ? (
                <UelLoader size="lg" />
              ) : transactionsState?.transactions?.data?.transactions.length ===
                0 ? (
                <h3>
                  <FormattedMessage id="screens.mygps.transactions.error.title" />
                </h3>
              ) : (
                <GpsTransactionsList
                  accountLabel={activeAccountLabel!}
                  transactionsList={
                    transactionsState?.transactions?.data?.transactions!
                  }
                  activeIcon={activeCategory?.src!}
                />
              )}
            </div>
          </>
        </>
      )}
    </div>
  )
}

export default TransactionDetailsContainer

/*{
   <>
<article className="balance-transactions">
  {transactionsState?.isLoading ? (
    <UelLoader size="lg" />
  ) : (
    <article className="transactions-data">
      <UelBalanceCard
        figure={
          transactionsState?.transactions?.data?.totalExpenses!
        }
        alternateLabel="screens.mygps.transactions.total.expenses.label"
      />
      <UelCategoryTip
        text="screens.mygps.filters.transaction.details.n.tip"
        percentage={tipPorcentage}
        isExpense={isExpensesPercentage}
        tip={`screens.mygps.filters.transaction.details.tip.${activeCategory?.name}`}
      />
      {transactionsState?.transactions?.data?.transactions
        .length === 0 ? (
        <h3>
          <FormattedMessage id="screens.mygps.transactions.error.title" />
        </h3>
      ) : (
        <GpsTransactionsList
          accountLabel={activeAccountLabel!}
          transactionsList={
            transactionsState?.transactions?.data?.transactions!
          }
          activeIcon={activeCategory?.src!}
        />
      )}
    </article>
  )}
</article>
</> 
}*/
