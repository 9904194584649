import { Controller } from "react-hook-form"
import { UelDropdownProps } from '../../components/FormComponents/UelDropdown/UelDropdown';

interface UelDropdownPropsHOC extends UelDropdownProps {
  name: string;
  control: any;
  defaultValue?: string;
}
const withDropdownController = (WrappedComponent: (props: UelDropdownProps) => JSX.Element ) => {
  const WithDropdownController = (props: UelDropdownPropsHOC) => {
    const { name, control, defaultValue = undefined } = props;
    return (
      <Controller 
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={ ({ field }) => {
          
          return (
            <WrappedComponent 
              {...props}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
            />
          )
        }}
      />
    )
  }
  return WithDropdownController;
}
export default withDropdownController;