import React from "react"
import { useIntl, FormattedMessage } from "react-intl"
import { Box, CardSection, Text } from "@mantine/core"
import "./styles.scss"
export interface UelCardProps {
  className?: string
  onClick?: () => void
  iconName?: string
  idText?: string
  withComingSoonText?: boolean
  alt?: string
  children?: React.ReactNode
}
const UelCard = ({
  className,
  onClick,
  iconName,
  idText,
  withComingSoonText,
  alt,
  children,
}: UelCardProps) => {
  const intl = useIntl()
  return (
    <CardSection
      onClick={onClick}
      className={`card-container ${className ? className : ""}`}>
      {idText && (
        <Box className="text-container">
          {withComingSoonText ? (
            <Text className="comingSoon-text">
              <FormattedMessage id="coming.soon" />
            </Text>
          ) : null}

          <Text className="text-card">
            <FormattedMessage id={`${idText}`} />
          </Text>
        </Box>
      )}
      {iconName && (
        <div className="img-wrapper">
          <img
            className={
              iconName === "gps" ? "small" : iconName === "ahorro" ? "big" : ""
            }
            src={`/icons/${iconName}.svg`}
            alt={intl.formatMessage({ id: alt })}
          />
        </div>
      )}
      {children}
    </CardSection>
  )
}

export default UelCard
