import { ExpensesIconsI, ExpensesColorsI, ExpensesNamesI } from "models/mygps.models"
import { useIntl } from "react-intl"
import "./style.scss"

export interface GpsCategoryLabelProps {
  categoryName?: ExpensesNamesI
  iconSrc: ExpensesIconsI
  iconBgColor: ExpensesColorsI
  onClick?: (name:ExpensesNamesI) => void
}

const GpsCategoryLabel = ({
  categoryName,
  iconSrc,
  iconBgColor,
  onClick
}: GpsCategoryLabelProps) => {

  const intl = useIntl()

  return <div className="component-gps-category-label"
    style={{ backgroundColor: iconBgColor }}
    onClick={() => onClick && onClick(categoryName!)}>
      <img src={`/icons/${iconSrc}`} alt={intl.formatMessage({id: "alt.gpscategory.icon"})} />
  </div>
}

export default GpsCategoryLabel