import axios from 'axios';
import UelButton from "components/Shared/UelButton/UelButton";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setCreditBuilderStep } from 'store/SignUp/signup.actions';
import { SignUpCreditBuilderSteps } from "utils/helpers/signup.helpers";
import { RootState } from "store/reducers";
import { useEffect, useState } from 'react';
import "./styles.scss"
import UelLinkButton from "components/Shared/UelLinkButton/UelLinkButton";
import LoadingScreen from "screens/LoadingScreen/LoadingScreen";

const CreditBuilderZapSignContainer = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("Component render");
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const url = process.env.REACT_APP_ZS_LAMBDA_URL;
  const [isLoading, setLoading] = useState(false);

//   console.log(`Process ID: ${process_id}`);

  const email = useSelector(
    (state: RootState) =>
      state.auth.authentication?.data?.email ||
      state.user.email
  )

  console.log(`Email: ${email}`);

  const user_id = useSelector(
    (state: RootState) =>
      state.auth.authentication.data.id
  )

  console.log(`User ID: ${user_id}`);


  useEffect(() => {
    const checkTokenAndNavigate = async () => {
      setLoading(true);
      let token = localStorage.getItem('token');
      if (token) {
        if (!url) throw new Error('REACT_APP_ZS_LAMBDA_URL is not set in .env file');
        let postData = { token, user_id };
        const response = await axios.post(url, postData, {
          headers: {
            'Content-Type': 'application/json',
            'password': process.env.REACT_APP_LAMBDAS_PASS || ''
          },
        });
        console.log(response.data)
        if (response.status === 200 && response.data.status === "signed") {
          dispatch(setCreditBuilderStep(SignUpCreditBuilderSteps.completed));
          localStorage.removeItem('token');
          setLoading(false);
          navigate('/ahorro');
        } else if (response.data.status === "pending") {
          alert('Por favor refresca la página cuando el contrato esté firmado');
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    checkTokenAndNavigate();
  }, []);

  const postToLambda = async () => {
    console.log("Calling postToLambda");
  
    if (!url) throw new Error('REACT_APP_ZS_LAMBDA_URL is not set in .env file');
  
    // Check if token exists
    let token = localStorage.getItem('token');
  
    // If token exists, use it as postData, else use email and gps_id
    let postData = token ? { token } : { "email": email, "gps_id": user_id };
  
    const response = await axios.post(url, postData, {
      headers: {
        'Content-Type': 'application/json',
        'password': process.env.REACT_APP_LAMBDAS_PASS || ''
      },
    });
  
    console.log(`Response from postToLambda: ${JSON.stringify(response.data)}`);
    return response;
  };



  const goForward = async () => {
    setLoading(true);
    console.log("Calling goForward");
    localStorage.removeItem('token');
    const response = await postToLambda();

    console.log("lambda status: ", response.status);

    if (response.status === 200) {

      const token = response.data.response.token;

      if (token) {
          console.log(`Token: ${token}`); // Log the token to ensure it's correct
          localStorage.setItem('token', token);

          // redirect user to the new url
          window.location.href = response.data.sign_url;
          setLoading(false);
      } else {
          console.log("Token not found in response data");
          setLoading(false);
      }
    } else if (response.status === 400) {
      // Display error message
      console.log("Response status 400 from goForward");
      setLoading(false);
      alert('An error occurred'); // Add your own error message
      navigate('/ahorro');

    }
  };

  const cancelCB = () => {
    navigate('/')
  }
  if (isLoading) {
    return <LoadingScreen />;  // You need to create or import this LoadingScreen component
  }

  return (
    <div className="home-container-cb">
    <div className="message-part-zs">
      <h2 className='header-zs'>Firma de contrato</h2>
      <h3 className='header-description-zs'>¡Ya casi terminas! Sólo falta firmar tu contrato.</h3>
      <ul className='list-zs-details list-container-zs'>
        <li>Asegura que tus datos estén correctos</li>
        <li>Revisa las condiciones de tu contrato </li>
        <li>Firma el contrato con tu dedo</li>
      </ul>
    </div>
    <div className="button-container-zs">
      <UelButton
        title={"cb.zs.btn"}
        onClick={goForward}
        variant="primary"
        disabled={false}
      />
    </div>
    <div className="link-button-kyc">
                <UelLinkButton
                    onClick={cancelCB}
                    color="secondary"
                >
                    {"No quiero mejorar mi buró"}
                </UelLinkButton>
          </div>
  </div>
);
};

export default CreditBuilderZapSignContainer;
