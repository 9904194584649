import { useIntl } from "react-intl"
import "./style.scss"

export interface GpsAddButtonProps {
  onClick: () => void
}

const GpsAddbutton = ({ onClick }: GpsAddButtonProps) => {
  const intl = useIntl()

  return (
    <button className="component-gps-add-button" onClick={onClick}>
      <img
        src="/icons/plus.svg"
        alt={intl.formatMessage({
          id: "alt.gps.add.button",
        })}
      />
    </button>
  )
}

export default GpsAddbutton
