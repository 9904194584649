import { FormattedMessage } from "react-intl";
import { UelColorsModel } from "models/app.models";
import "./style.scss";
export interface UelLinkButtonProps {
  children: string;
  color?: UelColorsModel;
  onClick: () => void;
}

const UelLinkButton = (props: UelLinkButtonProps) => {
  const { children, color = "primary", onClick } = props;
  return (
    <button type="button" className={`uel-underline-text-button ${color} `} onClick={onClick}>
      <FormattedMessage id={children} />
    </button>
  );
}

export default UelLinkButton;