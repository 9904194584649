import SemiDonutChart from "components/MyGps/SemiDonutChart/SemiDonutChart";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { UserScoreExample } from "utils/dataExamples/MyGpsExamples";
import "./style.scss";

const CreditScoreCard = () => {
  const { formatMessage } = useIntl();
  const userScore = useSelector((state: RootState) => state.mygps.userScore?.score);

  return (
    <div className="credit-score-card">
      <p>{formatMessage({id: "screens.mygps.scorecard.title"})}</p>
      <img src="/icons/getInfo.svg" alt="alt.getinfo.icon" />
      <SemiDonutChart
        creditScore={userScore?.creditScore! ?? UserScoreExample.creditScore}
        riskLevel={userScore?.riskLevel! ?? UserScoreExample.riskLevel}
      />
    </div>
  );
}

export default CreditScoreCard;
 