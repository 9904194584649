import React from 'react';

interface UrlParamsContextType {
  urlParams: URLSearchParams;
  setUrlParams: (params: URLSearchParams) => void;
}

const UrlParamsContext = React.createContext<UrlParamsContextType>({
  urlParams: new URLSearchParams(),
  setUrlParams: () => {},
});

export default UrlParamsContext;
