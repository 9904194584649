import { useCallback, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { useDispatch } from "react-redux"
import { AppStates } from "utils/helpers/app.helpers"
import { contentTutorial } from "utils/helpers/signup.helpers"
import { setAppState } from "store/Auth/auth.actions"
import CarouselTutorial from "components/Shared/UelCarouselTutorial/UelCarouselTutorial"
import Header from "components/Shared/UelHeader/UelHeader"
import UelWebFooter from "components/Shared/UelWebFooter/UelWebFooter"
import "./style.scss"
interface RegisterLayoutProps {
  children?: React.ReactNode
}

const RegisterLayout = (props: RegisterLayoutProps) => {
  const dispatch = useDispatch()

  const { children } = props
  const tutorial = localStorage.getItem("firstTimeTutorial")
  const [firstTime, setFirstTime] = useState<boolean>(false)

  const skipOrContinue = useCallback(
    (value: number) => {
      value === 1 && dispatch(setAppState(AppStates.onboardingState))
      localStorage.setItem("firstTimeTutorial", "check")
      setFirstTime(false)
    },
    [dispatch]
  )

  useEffect(() => {
    if (!tutorial) {
      setFirstTime(true)
    }
  }, [tutorial])

  return firstTime ? (
    <CarouselTutorial
      onActionPrimaryBtn={skipOrContinue}
      contentTutorial={contentTutorial}
    />
  ) : (
    <div className="register-layout">
      <Header withHelpIcon />
      <div className="register-layout-content">
        <div className="content">
          <Outlet />
          {children}
        </div>
      </div>
      <UelWebFooter />
    </div>
  )
}

export default RegisterLayout
