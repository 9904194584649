import UelLoader from "components/Shared/UelLoader/UelLoader"

interface LoadingContainerProps {
  children?: React.ReactNode
}

const LoadingContainer = (props: LoadingContainerProps) => {
  const { children } = props
  return (
    <div className="loading-container">
      <div className="loading-wrapper">
        <UelLoader size="lg" />
        {children}
      </div>
    </div>
  )
}

export default LoadingContainer
