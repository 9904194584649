import { useMemo } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { FormattedMessage } from "react-intl"
import { ROUTES, tabletBreakpoint } from "utils/helpers/app.helpers"
import { MyGpsScreens, MyGpsStartingScreens } from "utils/helpers/mygps.helpers"
import { RootState } from "store/reducers"
import UelBackButton from "components/Shared/UelBackButton/UelBackButton"
import BottomNavigation from "components/Shared/UelBottomNavigation/UelBottomNavigation"
import Header from "components/Shared/UelHeader/UelHeader"
import UelWebFooter from "components/Shared/UelWebFooter/UelWebFooter"
import "./style.scss"

const HomeLayout = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const currentPathname = location.pathname.toLowerCase()
  const isSpecialScreen = currentPathname === ROUTES.home
  const containerStyle = `home-layout ${isSpecialScreen ? "custom-bg" : ""}`

  const name = useSelector(
    (state: RootState) =>
      state.auth.authentication?.data?.firstName ||
      state.signup.personalData.name
  )
  const headerProps = { values: { name: "" }, id: "", customClass: "" }
  if (isSpecialScreen) {
    headerProps.values = { name }
    headerProps.id = "dashboard.salute"
  } else if (currentPathname === ROUTES.ahorro) {
    headerProps.values = { name }
    headerProps.id = "screens.ahorro.header.header.label"
  } else {
    headerProps.id = "screens.selecttimeframe.header"
  }
  const dontHaveBackButton =
    MyGpsStartingScreens.filter((screen) => screen === currentPathname).length >
    0
  const isProfileScreen = ROUTES.profile === location.pathname.toLowerCase()

  const headerLabelId = useMemo(() => {
    let headerL = null
    switch (currentPathname) {
      case ROUTES.home:
        headerL = "screens.home.header.header.label"
        break
      case ROUTES.mygps:
        headerL = "screens.mygps.header.header.label"
        break
      case ROUTES.ahorro:
        headerL = "screens.ahorro.header.header.label" // Update 
        break
    }
    return headerL
  }, [currentPathname])

  return (
    <div className={containerStyle}>
      <div className="home-layout-content">
        <Header withHelpIcon />
        {!dontHaveBackButton && !isProfileScreen && currentPathname !== ROUTES.ahorro && (
          <UelBackButton onClick={() => navigate(ROUTES.home)} />
        )}
        <div className="content">
          {currentPathname !== ROUTES.newBankAccount && currentPathname !== ROUTES.ahorro && !isProfileScreen && (
            <h1 className={!dontHaveBackButton ? "custom-margin-bottom" : ""}>
                <FormattedMessage {...headerProps} />
            </h1>
          )}
          {headerLabelId && currentPathname !== ROUTES.ahorro && (
            <p className="header-label">
              <FormattedMessage id={headerLabelId} />
            </p>
          )}
          {/*currentPathname === ROUTES.ahorro && (
            <h1>
              <FormattedMessage {...headerProps} />
            </h1>
          )*/}
          {MyGpsScreens.filter((screen) => screen === currentPathname).length >
          0 ? (
            <h2>
              <FormattedMessage
                id={
                  tabletBreakpoint.matches
                    ? "screens.mygps.header.subtitle.tablet"
                    : "screens.mygps.header.subtitle.phone"
                }
              />
            </h2>
          ) : null}
          <Outlet />
          </div>
          </div>
          <UelWebFooter />
          <div className="home-layout-navbar">
            <BottomNavigation />
          </div>
          </div>
          )
          }
          
          export default HomeLayout
          