import React from "react"
import { Text } from "@mantine/core"
import "./styles.scss"
import { useIntl } from "react-intl"
import { BankAccountI } from "models/mygps.models"

export interface UelCardBankAccountProps {
  onClick: (bankAccount: BankAccountI) => void
  bankAccount: BankAccountI
}

const UelCardBankAccount = ({
  onClick,
  bankAccount,
}: UelCardBankAccountProps) => {
  const intl = useIntl()
  return (
    <div
      className="card-bank-account-container"
      onClick={() => onClick(bankAccount)}>
      <img
        className="img-bank-account"
        src={bankAccount.bankImageUrl}
        alt={intl.formatMessage({
          id: "alt.bank.logo",
        })}
      />
      <Text className="text-bank-account">{bankAccount.number}</Text>
    </div>
  )
}

export default UelCardBankAccount
