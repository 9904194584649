import UelFancyModal, { UelFancyModalProps } from '../../Shared/UelFancyModal/UelFancyModal';

export interface AccountAddedModalProps extends
  Omit<UelFancyModalProps, 'title' | 'description' | 'linkButtonTitle'> {
}

const AccountAddedModal = (props: AccountAddedModalProps) => {
  return (
    <UelFancyModal
      {...props}
      title="global.modal.addaccount.title"
      description="global.modal.addaccount.description"
      linkButtonTitle="global.modal.addaccount.link"
      withCustomBackground
    />
  );
}

export default AccountAddedModal;
