import React from "react"
import { FormattedMessage } from "react-intl"
import { UnstyledButton } from "@mantine/core"
import { IntlString } from "models/app.models"
import { UelColorsModel } from "models/app.models"
import UelLoader from "../UelLoader/UelLoader"
import "./style.scss"
export interface UelButtonProps {
  title: IntlString
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  submit?: boolean
  loading?: boolean
  disabled?: boolean
  variant?: UelColorsModel
  children?: React.ReactNode
  height?: number
  className?: string
}

const UelButton = ({
  title,
  onClick,
  submit,
  loading,
  disabled,
  variant = "primary",
  children,
  height,
  className,
}: UelButtonProps) => {
  const isDisabled = disabled || loading
  const containerStyle = `uel-button ${className} ${
    isDisabled ? "disabled" : ""
  } ${variant}
    `
  const customStyle = height ? { height } : {}
  return (
    <UnstyledButton
      type={submit ? "submit" : "button"}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
        onClick && onClick(e)
      }
      disabled={isDisabled}
      className={containerStyle}
      style={customStyle}>
      {loading ? <UelLoader size="sm" /> : <FormattedMessage id={title} />}
      {children}
    </UnstyledButton>
  )
}

export default UelButton
