import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { FormattedMessage } from "react-intl"
import moment from "moment"
import useGetAccountIds from "hooks/useGetAccountIds"
import { ROUTES, months } from "utils/helpers/app.helpers"
import { MyGpsTimeframes } from "utils/helpers/mygps.helpers"
import useTransactionDates from "hooks/useTransactionDates"
import {
  getMyGpsExpenses,
  getTransactionsRange,
  setAccountIndex,
  setExpensesInformation,
} from "store/MyGps/mygps.actions"
import { RootState } from "store/reducers"
import BalanceCards from "components/MyGps/BalanceCards/BalanceCards"
import ExpensesCard from "components/MyGps/ExpensesCard/ExpensesCard"
import GpsAddbutton from "components/MyGps/GpsAddButton/GpsAddButton"
import GpsFilters from "components/MyGps/GpsFilters/GpsFilters"
import UelLoader from "components/Shared/UelLoader/UelLoader"
import "./style.scss"

moment.updateLocale("es-MX", { months: months })

const TimeframeStatsContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { loading, error, accountIdsData } = useGetAccountIds()

  const accountIndex = useSelector(
    (state: RootState) => state.mygps.accountIndex
  )

  const timeframe = useSelector(
    (state: RootState) => state.mygps.globalMyGpsTimeframe
  )
  const transactionRangeState = useSelector(
    (state: RootState) => state.mygps.transactionsRange
  )

  const { timeframeFilterOptions, changeActiveTimeframeFilter } =
    useTransactionDates(
      timeframe,
      transactionRangeState.range?.recentTransactionDate!
    )

  const expensesState = useSelector((state: RootState) => state.mygps.expenses)

  const monthlyTimeFilters = useSelector(
    (state: RootState) => state.mygps.monthlyTimeFilters
  )

  const quarterlyTimeFilters = useSelector(
    (state: RootState) => state.mygps.quarterlyTimeFilters
  )

  const [accountId, setAccountId] = useState("")

  const accountFilterOptions = useMemo(
    () =>
      accountIdsData?.map((account, index) => ({
        value: account.accountId,
        label: [`${account.bankName} *`]
          .concat(account.number.toString())
          .join(""),
      })),
    [accountIdsData]
  )

  const { startDate, endDate } =
    timeframe === MyGpsTimeframes.monthly
      ? monthlyTimeFilters
      : quarterlyTimeFilters

  const expensesLoading = expensesState.isLoading

  const changeActiveAccountFilter = useCallback(
    (value: string) => {
      setAccountId(value)
      dispatch(
        setAccountIndex(
          accountIdsData?.map((item) => item.accountId).indexOf(value)!
        )
      )
    },
    [dispatch, accountIdsData]
  )

  useEffect(() => {
    transactionRangeState.range?.recentTransactionDate === "" &&
      dispatch(getTransactionsRange())
  }, [dispatch, transactionRangeState.range?.recentTransactionDate])

  useEffect(() => {
    accountIdsData !== null &&
      accountIdsData.length > 0 &&
      setAccountId(accountIdsData[accountIndex].accountId)
  }, [accountIdsData, accountIndex])

  useEffect(() => {
    if (
      accountId !== "" &&
      startDate !== "" &&
      endDate !== "" &&
      localStorage.getItem("renderCount") === null
    ) {
      dispatch(
        getMyGpsExpenses({
          startDate: startDate,
          endDate: endDate,
          accountId: accountId!,
        })
      )
      localStorage.setItem("renderCount", JSON.stringify(1))
    }
  }, [
    dispatch,
    accountId,
    monthlyTimeFilters,
    quarterlyTimeFilters,
    timeframe,
    startDate,
    endDate,
  ])

  useLayoutEffect(() => {
    accountId === "" && dispatch(setExpensesInformation(null))
    localStorage.removeItem("renderCount")
  }, [dispatch, accountId])

  return (
    <section className={`container-timeframe-stats`}>
      {loading ||
      accountIdsData === null ||
      transactionRangeState.range?.recentTransactionDate === "" ? (
        <UelLoader size="lg" color="primary" />
      ) : error || transactionRangeState.error ? (
        <h2>
          <FormattedMessage id="screens.mygps.data.error"></FormattedMessage>
        </h2>
      ) : expensesState.expenses === null ? (
        <UelLoader size="lg" color="primary" />
      ) : (
        <>
          <GpsFilters
            dropdownOptionsFirst={timeframeFilterOptions!}
            dropdownOptionsSecond={accountFilterOptions!}
            changeActiveTimeframeFilter={changeActiveTimeframeFilter}
            changeActiveAccountFilter={changeActiveAccountFilter}
          />
          <div className="balance-wrapper">
            {expensesLoading ? <UelLoader size="sm" /> : <BalanceCards />}
          </div>
          <div
            className={`expenses-card-wrapper ${
              expensesLoading ? "tall" : ""
            }`}>
            {expensesLoading ? (
              <UelLoader size="lg" />
            ) : expensesState.expenses?.expenseCategoryDistribution?.length ===
              0 ? (
              <h3>
                <FormattedMessage id="screens.mygps.transactions.error.title" />
              </h3>
            ) : (
              <ExpensesCard accountIndex={accountIndex!} />
            )}
          </div>
          <div className="button-copy">
            <GpsAddbutton
              onClick={() => {
                navigate(ROUTES.newBankAccount)
              }}
            />
            <h3>
              <FormattedMessage id="screens.mygps.add.button.title" />
            </h3>
          </div>
        </>
      )}
    </section>
  )
}

export default TimeframeStatsContainer
