import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import UelButton from "components/Shared/UelButton/UelButton"
import UelTextInput from "components/FormComponents/UelTextInput/UelTextInput"
import UelDropdown from "components/FormComponents/UelDropdown/UelDropdown"
import UelBackButton from "components/Shared/UelBackButton/UelBackButton"
import UelCheckBox from "components/FormComponents/UelCheckBox/UelCheckBox"
import withTextInputController from "utils/hocs/withControllerInput"
import withDropdownController from "utils/hocs/withDropdownController"
import withCheckboxController from "utils/hocs/withCheckboxController"
import "./styles.scss"
import { FormattedMessage } from 'react-intl';
import { SignUpCreditBuilderSteps } from "utils/helpers/signup.helpers";
import { setCreditBuilderStep } from 'store/SignUp/signup.actions';
import { RootState } from "store/reducers";
import * as yup from 'yup';
import React from 'react';
import LoadingScreen from "screens/LoadingScreen/LoadingScreen";
import UelLinkButton from "components/Shared/UelLinkButton/UelLinkButton";

const ControllerTextInput = withTextInputController(UelTextInput)
const ControllerDropdown = withDropdownController(UelDropdown)
const ControllerCheckBox = withCheckboxController(UelCheckBox)

interface KuspitInfoFormModel {
    curp: string;
    ingreso: string;
    fuenteIngreso: string;
    procedenciaIngreso: string;
    actividadEmpresarial: string;
    ocupacion: string;
    estadoCivil: string;
    giro: string;
    actividad: string;
    nacionalidad: string;
    latitud: number;
    longitud: number;
  }

const CreditBuilderKuspitInfoContainer = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const procedenciaIngresoSource = [
    { label: "Ahorros", value: "2" },
    { label: "Ventas", value: "4" },
    { label: "Préstamo", value: "5" },
    { label: "Otros", value: "0" },
  ]

  const fuenteIngresoSource = [
    { label: "Sueldos", value: "1" },
    { label: "Honorarios", value: "0" },
    { label: "Ventas", value: "4" },
    { label: "Comisiones", value: "3" },
    { label: "Inversiones", value: "2" },
    { label: "Otros", value: "7" },
  ]

  const ingresoSource = [
    { label: "Menos de $2,0000", value: "0" },
    { label: "De $2,000 a $4,000", value: "1" },
    { label: "De $4,000 a $10,000", value: "2" },
    { label: "De $10,000 a $35,000", value: "3" },
    { label: "De $35,000 a $50,000", value: "4" },
    { label: "Más de $50,000", value: "5" },
  ]

  const actividadEmpresarialSource = [
    { label: "No", value: "0" },
    { label: "Sí", value: "1" },
  ]

  const estadoCivilSource = [
    { label: "Solter@", value: "1" },
    { label: "Casad@", value: "2" },
  ]

  const giroSource = [
    { label: "Manufactura", value: "7" },
    { label: "Alimentos y bebidas", value: "8" },
    { label: "Construcción", value: "11" },
    { label: "Servicios", value: "12" },
    { label: "Transporte", value: "13" },
    { label: "Comercio", value: "16" },
    { label: "Gobierno", value: "17" },
    { label: "Servicios financieros", value: "19" },
    { label: "Otro", value: "20" },
  ]

  // const actividadSource = [
  //   {label: "CRIA Y EXPLOTACION DE GANADO VACUNO PARA CARNE", value:"72"},
  //   {label: "CRIA Y EXPLOTACION DE GANADO VACUNO PARA LECHE", value:"73"}
  // ];

  const giroAndActividadSource = [
    {
      label: "Manufactura",
      value: "7",
      actividades: [
        {
          label: "Fabricación de telas",
          value: "236",
        },
        {
          label: "Fabricación de autos",
          value: "568",
        },
        {
          label: "Otro",
          value: "629",
        },
      ],
    },
    {
      label: "Alimentos y bebidas",
      value: "8",
      actividades: [
        {
          label: "Fabricación de alimentos",
          value: "174",
        },
        {
          label: "Fabricación de bebidas",
          value: "224",
        },
        {
          label: "Otro",
          value: "212",
        },
      ],
    },
    {
      label: "Construcción",
      value: "11",
      actividades: [
        {
          label: "Fabricación de cemento",
          value: "454",
        },
        {
          label: "Construcción de vivienda",
          value: "643",
        },
        {
          label: "Otro",
          value: "660",
        },
      ],
    },
    {
      label: "Servicios",
      value: "12",
      actividades: [
        {
          label: "Comisionista",
          value: "955",
        },
        {
          label: "Servicios profesionales",
          value: "948",
        },
        {
          label: "Otro",
          value: "671",
        },
      ],
    },
    {
      label: "Transporte",
      value: "13",
      actividades: [
        {
          label: "Transporte urbano",
          value: "856",
        },
        {
          label: "Autotransporte de carga",
          value: "864",
        },
        {
          label: "Otro",
          value: "866",
        },
      ],
    },
    {
      label: "Comercio",
      value: "16",
      actividades: [
        {
          label: "Abarrotes y miscelánea",
          value: "694",
        },
        {
          label: "Venta de artículos",
          value: "716",
        },
        {
          label: "Otro",
          value: "853",
        },
      ],
    },
    {
      label: "Gobierno",
      value: "17",
      actividades: [
        {
          label: "Gobierno federal",
          value: "1079",
        },
        {
          label: "Otro",
          value: "1090",
        },
      ],
    },
    {
      label: "Servicios financieros",
      value: "19",
      actividades: [
        {
          label: "Servicios bancarios",
          value: "895",
        },
        {
          label: "Agente de seguros",
          value: "926",
        },
        {
          label: "Otro",
          value: "934",
        },
      ],
    },
    {
      label: "Otro",
      value: "20",
      actividades: [
        {
          label: "Otro",
          value: "999",
        },
      ],
    },
  ]

  const nacionalidadSource = [
    { label: "Mexicana", value: "M" },
    { label: "Extranjera", value: "E" },
  ]

  console.log("Component render");
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const url = process.env.REACT_APP_CB_LAMBDA_KUSPIT_URL;
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    curp: yup.string()
      // .matches(/^[A-Z]{4}\d{6}[A-HJ-NP-Z]{6}$/, "Invalid CURP format")
      .required('La CURP es requerida')
      .length(18, 'La CURP debe ser de exactamente 18 caracteres'),
      ingreso: yup.string().required('El ingreso es requerido'),
      fuenteIngreso: yup.string().required('La fuente de ingresos es requerida'),
      procedenciaIngreso: yup.string().required('La procedencia de ingresos es requerida'),
      actividadEmpresarial: yup.string().required('La actividad empresarial es requerida'),
      ocupacion: yup.string().required('La ocupación es requerida'),
      estadoCivil: yup.string().required('El estado civil es requerido'),
      giro: yup.string().required('El giro es requerido'),
      actividad: yup.string().required('La actividad es requerida'),
      nacionalidad: yup.string().required('La nacionalidad es requerida')
  });

  const methods = useForm<KuspitInfoFormModel>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  

  const { handleSubmit, formState, control, resetField, watch, setValue } =
    methods
  const { errors } = formState

  const { idExterno} = useSelector((state: RootState) => ({
    idExterno: state.auth.authentication?.data?.id || state.signup.registerUser.data?.id,
  }));

  console.log("Auth ID: " + idExterno)

  const postToLambda = async (data: KuspitInfoFormModel) => {

    if (!url) {
      console.error('REACT_APP_CB_LAMBDA_KUSPIT_URL is not set in .env file');
      throw new Error('REACT_APP_CB_LAMBDA_KUSPIT_URL is not set in .env file');
    }
    try {
      console.log("Making axios post request");
      const response = await axios.post(url, {...data}, {
        headers: {
          'Content-Type': 'application/json',
          'password': process.env.REACT_APP_LAMBDAS_PASS || ''
        },
      });
      console.log("Axios post request succeeded", response.data);
      dispatch(setCreditBuilderStep(SignUpCreditBuilderSteps.billing)); 
  
      console.log(`Response from postToLambda: ${JSON.stringify(response.data)}`);
      setIsLoading(false);
      return response;
    } catch (error) {
      console.error("axios post request failed", error);
      setIsLoading(false);
    }
  };


  const submitInfo = (data: KuspitInfoFormModel) => {
    console.log("submitInfo function called", data, errors);
    setIsLoading(true);
    const updatedData = {
      ...data,
      idExterno: idExterno,
    };
    if(window.location.protocol !== 'https:' && window.location.hostname !== 'localhost') {
      console.log("Protocol is not HTTPS, skipping geolocation retrieval.");
      postToLambda(updatedData);
    } else if(navigator.geolocation) {
        console.log("Navigator geolocation available");
        navigator.geolocation.getCurrentPosition((position) => {
            console.log("Geolocation data retrieved");
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            // Update the lat/long values in the data object
            updatedData.latitud = latitude;
            updatedData.longitud = longitude;

            postToLambda(updatedData);
        }, (error) => {
            console.error("Could not get geolocation", error);
            askForLocationAgain(data);
        });
    } else {
        console.log("Geolocation is not supported in the browser");
        askForLocationAgain(data);
    }
  }

  const askForLocationAgain = (data: KuspitInfoFormModel) => {

      alert('Requerimos obtener tu geolocalización. Por favor habilita esta funcionalidad e intenta de nuevo.');
      submitInfo(data);
  }

  const watchedGiro = watch("giro", "");

// Use the watched 'giro' to set the corresponding 'actividad' options.
  let actividadSource = giroAndActividadSource.find(item => item.value === watchedGiro)?.actividades || [];

  // update the 'actividad' options whenever 'giro' changes
  useEffect(() => {
    setValue("actividad", "");  // reset the value of 'actividad' whenever 'giro' changes
  }, [watchedGiro]);

  const goBackWithLinkButton = () => {
    // Dispatch action to update credit builder step
    dispatch(setCreditBuilderStep(SignUpCreditBuilderSteps.personal));

    // Navigate to /ahorro
    navigate('/ahorro');
  };

  const cancelCB = () => {
    navigate('/')
  }

  // const areFieldsValid = (): boolean => {
  //   const fields = methods.getValues();
  //   const fieldNames = Object.keys(fields) as Array<keyof KuspitInfoFormModel>;
  
  //   // If all fields are filled and no errors.
  //   return fieldNames.length > 0 && fieldNames.every((field) => fields[field] !== undefined && fields[field] !== '' && !errors[field]);
  // };

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState]);


  return (
    <div className="form-kuspit-info">
      {isLoading ? (
      <LoadingScreen />
    ) : (
      <div>
        <div className="link-button-personal">
                <UelBackButton
                    onClick={goBackWithLinkButton}
                />
          </div>
        <div>
        <h2 className="datos-personales-cb-head">Datos personales</h2>
        <h3 className="datos-personales-cb-head3">Por favor completa los siguientes datos personales</h3>
        </div>
          <FormProvider {...methods}>
            <form className="form-container" onSubmit={handleSubmit(submitInfo)}>
              <ControllerTextInput
                control={control}
                name={"curp"}
                isRequired
                label="CURP"
                placeholder="Ingresa tu CURP"
                errorText={errors.curp?.message && errors.curp?.message}
              />
              <ControllerDropdown
                control={control}
                name="ingreso"
                data={ingresoSource}
                label="¿Cuánto es tu ingreso mensual?"
                isRequired
                error={errors.ingreso?.message}
                placeholder="Selecciona tu ingreso mensual"
              />
              <ControllerDropdown
                control={control}
                name="fuenteIngreso"
                data={fuenteIngresoSource}
                label="¿Cuál es la fuente de tus ingresos?"
                isRequired
                error={errors.fuenteIngreso?.message}
                placeholder="Selecciona tu fuente de ingresos"
              />
              <ControllerDropdown
                control={control}
                name="procedenciaIngreso"
                data={procedenciaIngresoSource}
                label="¿Cuál es la procedencia de tus ingresos?"
                isRequired
                error={errors.procedenciaIngreso?.message}
                placeholder="Selecciona la procedencia de tus ingresos"
              />
              <ControllerDropdown
                control={control}
                name="actividadEmpresarial"
                data={actividadEmpresarialSource}
                label="¿Realizas actividades empresariales?"
                isRequired
                error={errors.actividadEmpresarial?.message}
                placeholder="Selecciona si realizas actividades empresariales"
              />
              <ControllerTextInput
                control={control}
                name={"ocupacion"}
                isRequired
                label="¿Cuál es tu ocupación actualmente?"
                placeholder="Describe tu ocupación"
                errorText={errors.ocupacion?.message && errors.ocupacion?.message}
              />
              <ControllerDropdown
                control={control}
                name="estadoCivil"
                data={estadoCivilSource}
                label="¿Cuál es tu estado civil?"
                isRequired
                error={errors.estadoCivil?.message}
                placeholder="Selecciona tu estado civil"
              />
              <ControllerDropdown
                control={control}
                name="giro"
                data={giroSource}
                label="¿Cuál es el giro de tu trabajo?"
                isRequired
                error={errors.giro?.message}
                placeholder="Selecciona el giro de tu trabajo"
              />
              <ControllerDropdown
                control={control}
                name="actividad"
                data={actividadSource}
                label="¿Cuál es la actividad de tu trabajo?"
                isRequired
                error={errors.actividad?.message}
                placeholder="Selecciona la actividad de tu trabajo"
              />
              <ControllerDropdown
                control={control}
                name="nacionalidad"
                data={nacionalidadSource}
                label="¿Cuál es tu nacionalidad?"
                isRequired
                error={errors.nacionalidad?.message}
                placeholder="Selecciona tu nacionalidad"
              />
              <div className="btn-container">
                <UelButton 
                submit title="btn.continue.save"
                disabled={!isFormValid}
                />
              </div>
            </form>
          </FormProvider>
          <div className="link-button-kuspit-quit">
                <UelLinkButton
                    onClick={cancelCB}
                    color="secondary"
                >
                    {"No quiero mejorar mi buró"}
                </UelLinkButton>
          </div>
          </div>
    )}
      </div>
  );
};

export default CreditBuilderKuspitInfoContainer;