import { useIntl } from "react-intl";
import { comafy } from "utils/helpers/app.helpers";
import { ExpensesColorsI, ExpensesIconsI } from "models/mygps.models";
import GpsCategoryLabel from "../GpsCategoryLabel/GpsCategoryLabel";
import "./style.scss"
export interface GpsTransactionProps {
  iconSrc: ExpensesIconsI;
  iconBgColor: ExpensesColorsI;
  amount: string;
  transactionDate: string;
  account: string;
  description: string;
}

const GpsTransaction = ({
  iconSrc,
  iconBgColor,
  amount,
  transactionDate,
  account,
  description
}: GpsTransactionProps) => {

  const intl = useIntl()
  
  return <div className="component-gps-transaction">
    <article className="label-info">
      <GpsCategoryLabel iconBgColor={iconBgColor} iconSrc={iconSrc} />
      <div className="time-address-account">
        <div className="time">
          {intl.formatDate(new Date(transactionDate), {
            year: "numeric",
            month: "short",
            day: "2-digit", 
          })}
        </div>
        <div className="address-account">
          <p>{description}</p>
          <p className="account">{account}</p>
        </div>
      </div>
    </article>
    <article className="amount">
    {`${amount.slice(0,1)}$${comafy(Number(amount))}`}
    </article>
  </div>
}

export default GpsTransaction