import _ from "lodash"
import moment from "moment"
import { ApexOptions } from "apexcharts"
import { createIntl, createIntlCache } from "react-intl"
import Spanish from "i18n/reactIntlMessages-es-MX.json"
import { ROUTES, months } from "./app.helpers"
import { DropdownItem } from "models/app.models"
import { RiskLevelsCollectionI, TimeFiltersI } from "models/mygps.models"

moment.updateLocale("es-MX", { months: months })

const cache = createIntlCache()
const intl = createIntl({ locale: "es-MX", messages: Spanish }, cache)

export const timeRangeLength = _.range(6) // Number of months
export const timeRangeByQuarters = _.range(6).filter((item) => item % 3 === 0) // Number of months  in range() function. Add 1 per year extra if more than 1 year
export const quartersRange = _.range(11, -1, -1).filter(
  (item) => item % 3 === 0
)

export enum ExpensesCategoriesNames {
  hogar = "hogar",
  transporte = "transporte",
  alimentos = "alimentos",
  educacion = "educacion",
  entretenimiento = "entretenimiento",
  viajes = "viajes",
  ropa = "ropa",
  finanzas = "finanzas",
  salud = "salud",
  otros = "otros",
}

export enum ExpensesCategoriesDescriptions {
  hogar = "Hogar",
  transporte = "Transporte",
  alimentos = "Alimentos",
  educacion = "Educación y trabajo",
  entretenimiento = "Entretenimiento",
  viajes = "Viajes",
  ropa = "Ropa",
  finanzas = "Finanzas e impuestos",
  salud = "Salud y cuidado personal",
  otros = "Otros",
}

export enum ExpensesCategoriesIcons {
  hogar = "rent.svg",
  transporte = "car.svg",
  alimentos = "cart.svg",
  educacion = "light-bulb.svg",
  entretenimiento = "restaurant.svg",
  viajes = "airplane.svg",
  ropa = "clothes-hanger.svg",
  finanzas = "credit-card.svg",
  salud = "shopping-bag.svg",
  otros = "paint.svg",
}

export const expensesCategoriesIcons = [
  { name: "hogar", icon: ExpensesCategoriesIcons.hogar },
  { name: "transporte", icon: ExpensesCategoriesIcons.transporte },
  { name: "alimentos", icon: ExpensesCategoriesIcons.alimentos },
  { name: "educacion", icon: ExpensesCategoriesIcons.educacion },
  { name: "entretenimiento", icon: ExpensesCategoriesIcons.entretenimiento },
  { name: "viajes", icon: ExpensesCategoriesIcons.viajes },
  { name: "ropa", icon: ExpensesCategoriesIcons.ropa },
  { name: "finanzas", icon: ExpensesCategoriesIcons.finanzas },
  { name: "salud", icon: ExpensesCategoriesIcons.salud },
  { name: "otros", icon: ExpensesCategoriesIcons.otros },
]

export enum ExpensesCategoriesColorsI {
  hogar = "#454FF5", // Hogar
  transporte = "#7648E8", // Transporte
  alimentos = "#ECE3FF", // Alimentos
  educacion = "#000BC3", // Educación y trabajo
  entretenimiento = "#A88DE8", // Entretenimiento
  viajes = "#68EDE9", // Viajes
  ropa = "#472EA3", // Ropa
  finanzas = "#FCCB5C", // Finanzas e impuestos
  salud = "#1D335C", // Salud y cuidado personal
  otros = "#FFE4A7", // Otros
}

export const ExpensesCategoriesColors = [
  ExpensesCategoriesColorsI.hogar,
  ExpensesCategoriesColorsI.transporte,
  ExpensesCategoriesColorsI.alimentos,
  ExpensesCategoriesColorsI.educacion,
  ExpensesCategoriesColorsI.entretenimiento,
  ExpensesCategoriesColorsI.viajes,
  ExpensesCategoriesColorsI.ropa,
  ExpensesCategoriesColorsI.finanzas,
  ExpensesCategoriesColorsI.salud,
  ExpensesCategoriesColorsI.otros,
]

export const MyGpsStartingScreens = [ROUTES.home, ROUTES.mygps]

export const MyGpsScreens = [
  ROUTES.mygpsTimeframeStats,
  ROUTES.mygpsTransactionDetails,
]

export enum MyGpsSteps {
  main = "S0",
  selectTimeframe = "S1",
  timeframe = "S2",
  transactions = "S3",
}

export enum MyGpsTimeframes {
  monthly = "monthly",
  quarterly = "quarterly",
}

export enum MyGpsMonthlyTimeframe {
  s0 = "S0",
  s1 = "S1",
  s2 = "S2",
  s3 = "S3",
}

export const riskLevels: RiskLevelsCollectionI[] = [
  {
    result: "screens.mygps.chart.result.r-1",
    customClass: "r-1",
  },
  {
    result: "screens.mygps.chart.result.r0",
    customClass: "r0",
  },
  {
    result: "screens.mygps.chart.result.r1",
    customClass: "r1",
  },
  {
    result: "screens.mygps.chart.result.r2",
    customClass: "r2",
  },
  {
    result: "screens.mygps.chart.result.r3",
    customClass: "r3",
  },
  {
    result: "screens.mygps.chart.result.r4",
    customClass: "r4",
  },
]

export const SemiDonutChartoptions: ApexOptions = {
  tooltip: {
    enabled: false
  },
  colors: ["#D76A66", "#E1A64D", "#F2D163", "#6CCB87"],
  plotOptions: {
    pie: {
      startAngle: -90,
      endAngle: 90,
      donut: {
        size: "85%",
      },
    },
  },
  stroke: {
    show: true,
    width: 4,
    colors: ["#ffffff"],
  },
  grid: {
    padding: {
      bottom: -120,
      right: 0,
      left: 0,
      top: 0,
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
}

export const pieChartStaticOptions: ApexOptions = {
  plotOptions: {
    pie: {
      dataLabels: {
        offset: 0,
      },
    },
  },
  dataLabels: {
    style: {
      fontSize: "10px",
      fontFamily: "Work-regular, sans-serif",
    },
    formatter: (val: number) => {
      return Math.trunc(val).toString() + "%"
    },
  },
  legend: {
    show: false,
  },
}

export const renderMonthlyLabelObject = (numberMonths: number) => ({
  month: moment().subtract(numberMonths, "month").format("MMMM"),
  year: moment().subtract(numberMonths, "month").format("YYYY"),
})

export const renderQuarterlyLabelObject = (
  start: number,
  end: number,
  year: number
) => ({
  startMonth: moment().month(start).format("MMM"),
  endMonth: moment().month(end).format("MMM"),
  year,
})

export const renderMonthlyFilterOptions = (index: number): DropdownItem => ({
  value: index.toString(),
  label: intl.formatMessage(
    { id: `screens.mygps.filters.timeframe.n.options.monthly` },
    renderMonthlyLabelObject(index)
  ),
})

export const renderQuarterlyFilterOptions = (
  index: number,
  start: number,
  end: number,
  year: number
): DropdownItem => ({
  value: index.toString(),
  label: intl.formatMessage(
    { id: `screens.mygps.filters.timeframe.n.options.quarterly` },
    renderQuarterlyLabelObject(start, end, year)
  ),
})

export const renderMonthRange = (startMonth: number): TimeFiltersI => ({
  startDate: moment()
    .subtract(startMonth, "month")
    .startOf("month")
    .format("YYYY-MM-DD"),
  endDate:
    startMonth === 0
      ? moment().format("YYYY-MM-DD")
      : moment()
          .subtract(startMonth, "month")
          .endOf("month")
          .format("YYYY-MM-DD"),
})

export const renderQuarterRange = (
  startMonth: number,
  endMonth: number,
  year: number
): TimeFiltersI => ({
  startDate: moment()
    .year(year)
    .month(startMonth)
    .startOf("month")
    .format("YYYY-MM-DD"),
  endDate:
    moment().isBetween(
      moment().month(startMonth).startOf("month"),
      moment().month(endMonth).endOf("month")
    ) && moment().year() === year
      ? moment().format("YYYY-MM-DD")
      : moment().year(year).month(endMonth).endOf("month").format("YYYY-MM-DD"),
})

export const monthlyTimeframeFilterOptions: DropdownItem[] =
  timeRangeLength.map((item) => renderMonthlyFilterOptions(item))

export const monthlyDatesCollection: TimeFiltersI[] = timeRangeLength.map(
  (item) => renderMonthRange(item)
)

export const quarterFilterOptions: DropdownItem[] = quartersRange.map(
  (item: number, key: number) =>
    renderQuarterlyFilterOptions(key, item, item + 2, moment().year())
)

export const quarterDatesCollection: TimeFiltersI[] = quartersRange.map(
  (item) => renderQuarterRange(item, item + 2, moment().year())
)

export const diffSinceBeginning = moment().diff(
  moment().startOf("year"),
  "months",
  true
)

export const findIndexByMonthName = (
  array: DropdownItem[],
  momentTarget?: string,
  increment?: number
) =>
  quarterFilterOptions.indexOf(
    quarterFilterOptions.filter((item) =>
      item.label.includes(
        moment(momentTarget ?? undefined)
          .startOf("quarter")
          .format("MMM")
      )
    )[0]
  ) + (increment ?? 0)
