import { Controller } from "react-hook-form"
import {
  justCharsAndNumbersString,
  justCharsAndNumbersStringWithHyphen,
  justCharsAndNumbersStringWithSpaces,
  justNumbersString,
  noRepeatSpaces,
  noSpecialCharsString,
} from "utils/helpers/app.helpers"
import { UelPasswordInputProps } from "../../components/FormComponents/UelPasswordInput/UelPasswordInput"
import { UelTextInputProps } from "../../components/FormComponents/UelTextInput/UelTextInput"

interface TextInputComponentPropsHOC
  extends UelTextInputProps,
    UelPasswordInputProps {
  name: string
  control: any
  blockSpecialChars?: boolean
  justNumbers?: boolean
  justCharsAndNumbers?: boolean
  justCharsAndNumbersWithSpaces?: boolean
  justCharsAndNumbersWithHyphen?: boolean
}

const withTextInputController = (
  WrappedComponent: (props: UelTextInputProps) => JSX.Element
) => {
  const WithTextInputController = (props: TextInputComponentPropsHOC) => {
    const { 
      name, 
      control, 
      blockSpecialChars, 
      justNumbers, 
      justCharsAndNumbers, 
      justCharsAndNumbersWithSpaces, 
      justCharsAndNumbersWithHyphen 
    } = props
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          const fieldValue = noRepeatSpaces(field.value);
          return (
            <WrappedComponent
              {...props}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={
                blockSpecialChars
                  ? noSpecialCharsString(fieldValue)
                  : justNumbers
                  ? justNumbersString(fieldValue)
                  : justCharsAndNumbers
                  ? justCharsAndNumbersString(fieldValue)
                  : justCharsAndNumbersWithSpaces 
                  ? justCharsAndNumbersStringWithSpaces(fieldValue) 
                  : justCharsAndNumbersWithHyphen
                  ? justCharsAndNumbersStringWithHyphen(fieldValue)
                  : fieldValue
              }
            />
          )
        }}
      />
    )
  }

  return WithTextInputController
}

export default withTextInputController
