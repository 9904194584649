import { StrapiResponse } from "models/app.models"
import { LoginRequestModel, LoginResponseModel} from "models/login.models"
import UelClient from "../utils/helpers/UelClient"

export const loginService = async (data: LoginRequestModel): Promise<StrapiResponse<LoginResponseModel>> => {
  return UelClient.post(`/auth/local`, data)
}

export const getPopUpMessage = async() => {
  return UelClient.get('/uellbee-user/get-home-tip')
}