import { FormattedMessage, useIntl } from "react-intl"
import { UnstyledButton, Text } from "@mantine/core"
import UelModal from "../UelModal/UelModal"
import "./styles.scss"
export interface UelHelpModalProps {
  show: boolean
  onClick: () => void
}

const UelHelpModal = ({ onClick, show }: UelHelpModalProps) => {
  const intl = useIntl()

  const sendWhatsApp = () => {
    window.open(
      `https://wa.me/${process.env.REACT_APP_NUMBER}`,
      "_self",
      "noopener,noreferrer"
    )
  }

  return (
    <UelModal
      className="full-screen"
      opened={show}
      onClose={onClick}
      withCloseButton={false}>
      <img
        src="/icons/whatsapp-icon.svg"
        alt={intl.formatMessage({ id: "alt.whatsapp.icon" })}
      />
      <Text className="help-title">
        <FormattedMessage id="screens.help.title" />
      </Text>
      <section className="description-section">
        <Text className="help-description">
          <FormattedMessage id="screens.help.description" />
        </Text>

        <Text className="help-number" onClick={sendWhatsApp}>
          <FormattedMessage id="screens.help.number" />
        </Text>
      </section>
      <UnstyledButton className="help-button" onClick={onClick}>
        <FormattedMessage id={"screens.help.goback"} />
      </UnstyledButton>
    </UelModal>
  )
}

export default UelHelpModal
