import "./styles.scss"
import { useEffect } from "react";

const SuccessScreenContainer = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="success-screen-container">
      <h2 className="cb-success-header">¡Has finalizado el proceso con éxito!</h2>
      <br />
      <h2 className="cb-success-header">Haremos el primer pago de tu membresía, cargando a la cuenta que nos proporcionaste y nos pondremos en contacto a la brevedad.</h2>
      <div className="icon-wrapper">
        <img src="/images/correct.svg" alt="¡Todo salió perfecto!" />
      </div>
    </div>
  )
}

export default SuccessScreenContainer
