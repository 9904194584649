import { Modal } from "@mantine/core"
import "./style.scss";

export interface UelModalProps {
  opened: boolean;
  onClose: () => void;
  children: React.ReactNode;
  withCloseButton?: boolean;
  className?: string;
}

const UelModal = (props: UelModalProps) => {
  const { opened, onClose, children, withCloseButton = true, className } = props;
  return (
    <div className="uel-modal">
      <Modal
        className={`uel-modal ${className}`}
        withCloseButton={withCloseButton}
        centered
        opened={opened}
        onClose={onClose}
      >
        {children}
      </Modal>
    </div>
  );
}

export default UelModal;