import { ApexOptions } from "apexcharts"
import Chart from "react-apexcharts"
import "./style.scss"

/**Width and height props are set to the vector parent, check inspector to obtain
 * desired dimensions for the wheel. Look for ".apexcharts-slices" selector.
 */

export interface ExpensesPieChartProps {
  width: number
  height: number
  options: ApexOptions
  series: ApexNonAxisChartSeries
}

const ExpensesPieChart = ({
  width,
  height,
  options,
  series,
}: ExpensesPieChartProps) => {
  return (
    <div className="component-expenses-pie-chart">
      <article className="chart-wrapper">
        <Chart
          style={{
            width,
            height,
          }}
          options={options}
          series={series}
          type="pie"
          height="100%"
        />
      </article>
    </div>
  )
}

export default ExpensesPieChart
