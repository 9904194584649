import { Dispatch } from "redux";
import { AppStates } from "utils/helpers/app.helpers";
import { BackendOnboardingSteps } from "utils/helpers/signup.helpers";
import { LoginFormModel } from "models/login.models";
import { LoginRequestModel } from "models/login.models";
import { loginService } from "services/login.services";
import { AuthenticationPayloadModel } from "./auth.model";
import { SET_ALREADY_CREATED_MODAL, SET_APP_STATE, SET_LOGIN_ERROR, SET_LOGIN_LOADING, SET_LOGIN_SUCCESS, SET_USER_LOGOUT, SET_WRONG_CREDENTIALS_MODAL } from "./auth.reducer";
import { setMyGpsWithFInerioId } from "store/MyGps/mygps.actions";
import { AxiosError } from "axios";

export const setAppState = (state: AppStates) => {
  return {
    type: SET_APP_STATE,
    payload: state
  }
}

export const setLoginLoading = () => {
  return {
    type: SET_LOGIN_LOADING,
  }
}

export const setWrongCredentialsModal = (show: boolean) => {
  return {
    type: SET_WRONG_CREDENTIALS_MODAL,
    payload: show
  }
}

export const setLoginSuccess = (data: AuthenticationPayloadModel) => {
  return {
    type: SET_LOGIN_SUCCESS,
    payload: data
  }
}

export const setLoginError = () => {
  return {
    type: SET_LOGIN_ERROR,
  }
}

export const setUserLogout = () => {
  return {
    type: SET_USER_LOGOUT
  }
}

export const setAccountAlreadyCreatedModalAction = (data: boolean) => {
  return {
    type: SET_ALREADY_CREATED_MODAL,
    payload: data
  }
}

export const logIn = (
  data: LoginFormModel,
  handleSuccess: (isComplete: boolean, step: BackendOnboardingSteps) => void
) => async (dispatch: Dispatch) => {
  dispatch(setLoginLoading())
  try {
    const sendData: LoginRequestModel = {
      identifier: data.email,
      password: data.password
    }
    const response = await loginService(sendData);

    dispatch(setLoginSuccess({
      jwt: response.data.jwt,
      id: response.data.user.id,
      userName: response.data.user.username,
      firstName: response.data.user.name,
      email: response.data.user.email,
      finerioUUID: response.data.user.finerioUUID !== null ? response.data.user.finerioUUID : undefined
    }))

    if (response.data.user.finerioUUID === null) {
      dispatch(setMyGpsWithFInerioId(false))
    } else {
      dispatch(setMyGpsWithFInerioId(true))
    }
    handleSuccess(response.data.user.onboarding.isComplete, response.data.user.onboarding.step)
  } catch (e: any) {
    if (e instanceof AxiosError && e.response?.status === 401) {
      dispatch(setWrongCredentialsModal(true));
    }
    dispatch(setLoginError())
  }
}