import { UtilsActions, UtilsState } from "./utils.model"

export const SET_SHOW_HELP_MODAL = "SET_SHOW_MODAL"
export const SET_SHOW_FINERIO_MODAL = "SET_SHOW_FINERIO_MODAL"

const initialState: UtilsState = {
  showHelpModal: false,
  showFinerioModal: false
}

const UtilsReducer = (state: UtilsState = initialState, action: UtilsActions): UtilsState => {
  switch (action.type) {
    case SET_SHOW_HELP_MODAL:
      return {
        ...state,
        showHelpModal: action.payload
      }
    case SET_SHOW_FINERIO_MODAL:
      return {
        ...state,
        showFinerioModal: action.payload
      }
    default:
      return state
  }
}

export default UtilsReducer