import { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import moment from "moment"
import {
  diffSinceBeginning,
  findIndexByMonthName,
  MyGpsTimeframes,
  quarterDatesCollection,
  quarterFilterOptions,
  renderMonthlyFilterOptions,
  renderMonthRange,
  renderQuarterlyFilterOptions,
  renderQuarterRange,
} from "utils/helpers/mygps.helpers"
import { TimeFiltersI } from "models/mygps.models"
import {
  getTransactionsRange,
  setMonthlyTimeFilters,
  setQuarterlyTimeFilters,
} from "store/MyGps/mygps.actions"
import { RootState } from "store/reducers"

const useTransactionDates = (
  timeframe: MyGpsTimeframes,
  oldestTransaction: string
) => {
  const dispatch = useDispatch()

  const monthlyTimeFilters = useSelector(
    (state: RootState) => state.mygps.monthlyTimeFilters
  )

  const quarterlyTimeFilters = useSelector(
    (state: RootState) => state.mygps.quarterlyTimeFilters
  )

  const monthsDifference = useMemo(
    () => _.range(moment().diff(moment(oldestTransaction), "months", true)),
    [oldestTransaction]
  )

  const monthlyTimeRange = useMemo(
    () => (monthsDifference.length <= 12 ? monthsDifference : _.range(12)),
    [monthsDifference]
  )

  const monthsInPastYear = useMemo(
    () => monthlyTimeRange.length - diffSinceBeginning,
    [monthlyTimeRange]
  )

  const pastYear = useMemo(
    () => moment().startOf("year").subtract(monthsInPastYear, "month").year(),
    [monthsInPastYear]
  )

  const quartersInPastYear = useMemo(
    () =>
      _.range(11, -1, -1)
        .slice(0, _.range(monthsInPastYear).length)
        .filter((item) => item % 3 === 0),
    [monthsInPastYear]
  )

  const timeframeFilterOptions = useMemo(() => {
    if (timeframe === MyGpsTimeframes.monthly) {
      return monthlyTimeRange.map((item) => renderMonthlyFilterOptions(item))
    } else {
      let localQuarterFilterOptions

      if (monthsInPastYear > 0) {
        localQuarterFilterOptions = _.range(diffSinceBeginning)
          .filter((item) => item % 3 === 0)
          .reverse()
          .map((item, key: number) =>
            renderQuarterlyFilterOptions(key, item, item + 2, moment().year())
          )
          .concat(
            quartersInPastYear.map((item: number) =>
              renderQuarterlyFilterOptions(
                _.range(diffSinceBeginning).filter((item) => item % 3 === 0)
                  .length,
                item,
                item + 2,
                pastYear
              )
            )
          )
      } else {
        localQuarterFilterOptions = quarterFilterOptions.slice(
          findIndexByMonthName(quarterFilterOptions),
          findIndexByMonthName(quarterFilterOptions, oldestTransaction, 1)
        )
      }
      return localQuarterFilterOptions
    }
  }, [
    monthlyTimeRange,
    pastYear,
    monthsInPastYear,
    oldestTransaction,
    quartersInPastYear,
    timeframe,
  ])

  const monthlyDatesCollection = useMemo(
    () => monthlyTimeRange.map((item: number) => renderMonthRange(item)),
    [monthlyTimeRange]
  )

  const quarterlyDatesCollection = useMemo(() => {
    let quarterDates

    if (monthsInPastYear > 0) {
      quarterDates = _.range(diffSinceBeginning)
        .filter((item) => item % 3 === 0)
        .map((item) => renderQuarterRange(item, item + 2, moment().year()))
        .reverse()
        .concat(
          quartersInPastYear.map((item) =>
            renderQuarterRange(item, item + 2, pastYear)
          )
        )
    } else {
      quarterDates = quarterDatesCollection.slice(0, moment().quarter())
    }
    return quarterDates
  }, [monthsInPastYear, pastYear, quartersInPastYear])

  const mainDatesCollection = useMemo(
    () =>
      timeframe === MyGpsTimeframes.monthly
        ? monthlyDatesCollection
        : quarterlyDatesCollection,
    [timeframe, monthlyDatesCollection, quarterlyDatesCollection]
  )

  const changeActiveTimeframeFilter = useCallback(
    (value: string) => {
      localStorage.removeItem("renderCount")
      timeframe === MyGpsTimeframes.monthly
        ? dispatch(
            setMonthlyTimeFilters({
              startDate: mainDatesCollection[Number(value)]?.startDate!,
              endDate: mainDatesCollection[Number(value)]?.endDate!,
            })
          )
        : dispatch(
            setQuarterlyTimeFilters({
              startDate: mainDatesCollection[Number(value)].startDate,
              endDate: mainDatesCollection[Number(value)].endDate,
            })
        )
      
    },
    [dispatch, mainDatesCollection, timeframe]
  )

  const checkRange = useCallback(
    (item: string) =>
      timeframe === MyGpsTimeframes.monthly
        ? moment(item).month()
        : moment(item).quarter(),
    [timeframe]
  )

  const filterCollection = useCallback(
    (collection: TimeFiltersI[], targetDate: string) => {
      return collection.filter(
        (item: TimeFiltersI) =>
          checkRange(item.startDate) === checkRange(targetDate) &&
          moment(item.startDate).year() === moment(targetDate).year()
      )[0]
    },
    [checkRange]
  )

  const matchIndex = useCallback(
    (collection: TimeFiltersI[], filters: TimeFiltersI) =>
      collection.indexOf(filterCollection(collection, filters?.startDate!)),
    [filterCollection]
  )

  const matchTimeRange = useCallback(
    (collection: TimeFiltersI[], targetDate: string) =>
      filterCollection(collection, targetDate),
    [filterCollection]
  )

  const monthlyTimeRangeIndex = useMemo(
    () => matchIndex(monthlyDatesCollection, monthlyTimeFilters),
    [monthlyDatesCollection, monthlyTimeFilters, matchIndex]
  )

  const quarterlyTimeRangeIndex = useMemo(
    () => matchIndex(quarterlyDatesCollection, quarterlyTimeFilters),
    [quarterlyDatesCollection, quarterlyTimeFilters, matchIndex]
  )

  const mainTimeRangeIndex = useMemo(
    () =>
      timeframe === MyGpsTimeframes.monthly
        ? monthlyTimeRangeIndex
        : quarterlyTimeRangeIndex,
    [monthlyTimeRangeIndex, quarterlyTimeRangeIndex, timeframe]
  )

  useEffect(() => {
    if (
      timeframe === MyGpsTimeframes.monthly &&
      monthlyTimeFilters?.startDate !== "" &&
      monthlyTimeFilters?.endDate !== ""
    ) {
      const matchingRange = matchTimeRange(
        monthlyDatesCollection,
        monthlyTimeFilters?.startDate!
      )
      matchingRange !== undefined &&
        dispatch(
          setMonthlyTimeFilters({
            startDate: matchingRange.startDate,
            endDate: matchingRange.endDate,
          })
        )
    } else if (
      timeframe === MyGpsTimeframes.quarterly &&
      quarterlyTimeFilters.startDate !== "" &&
      quarterlyTimeFilters.endDate !== ""
    ) {
      const matchingRange = matchTimeRange(
        quarterlyDatesCollection,
        quarterlyTimeFilters?.endDate!
      )
      matchingRange !== undefined &&
        dispatch(
          setQuarterlyTimeFilters({
            startDate: matchingRange.startDate,
            endDate: matchingRange.endDate,
          })
        )
    }
  }, [
    dispatch,
    matchTimeRange,
    monthlyDatesCollection,
    quarterlyDatesCollection,
    monthlyTimeFilters?.startDate,
    monthlyTimeFilters?.endDate,
    quarterlyTimeFilters?.startDate,
    quarterlyTimeFilters?.endDate,
    timeframe,
  ])

  useEffect(() => {
    if (
      timeframe === MyGpsTimeframes.monthly &&
      monthlyTimeFilters?.startDate === "" &&
      monthlyTimeFilters?.endDate === ""
    ) {
      dispatch(
        setMonthlyTimeFilters({
          startDate: moment()
            .startOf("month")
            .startOf("day")
            .format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        })
      )
    } else if (
      timeframe === MyGpsTimeframes.quarterly &&
      quarterlyTimeFilters?.startDate === "" &&
      quarterlyTimeFilters?.endDate === ""
    ) {
      const currentQuarter = quarterDatesCollection.filter(
        (item) => item.endDate === moment().format("YYYY-MM-DD")
      )[0]

      dispatch(
        setQuarterlyTimeFilters({
          startDate: currentQuarter?.startDate!,
          endDate: moment().format("YYYY-MM-DD"),
        })
      )
    }
  }, [
    dispatch,
    monthlyTimeFilters.startDate,
    monthlyTimeFilters.endDate,
    monthsDifference.length,
    quarterlyTimeFilters.startDate,
    quarterlyTimeFilters.endDate,
    timeframe,
  ])

  useEffect(() => {
    oldestTransaction === "" && dispatch(getTransactionsRange())
  }, [dispatch, oldestTransaction])

  return {
    timeframeFilterOptions,
    mainTimeRangeIndex,
    changeActiveTimeframeFilter,
  }
}

export default useTransactionDates
