import { Navigate } from "react-router-dom";
import { store } from "../../store/store";
import { AppStates, ROUTES } from "../helpers/app.helpers";

const withProtectedRoute = (WrappedComponent: (props?: any) => JSX.Element, requiredState: AppStates) => {
  const WithProtectedRoute = () => {
    const appState = store.getState().auth.appState;

    if(appState === requiredState) {
      return <WrappedComponent />
    }else {
      switch (appState) {
        case AppStates.initialState:
          return <Navigate to={ROUTES.login}/>
        case AppStates.onboardingState:
          return <Navigate to={ROUTES.signUp}/>
        case AppStates.homeState:
          return <Navigate to={ROUTES.home}/>
        default:
          return <Navigate to={ROUTES.home}/>
      }
    } 
  }
  return WithProtectedRoute;
}

export default withProtectedRoute;