import { lazy, Suspense, useState} from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import FinerioListener from "./utils/hubs/FinerioListener"
import { ROUTES } from "./utils/helpers/app.helpers"
import RegisterLayout from "./components/Layouts/RegisterLayout/RegisterLayout"
import HomeLayout from "./components/Layouts/HomeLayout/HomeLayout"
import LoadingScreen from "screens/LoadingScreen/LoadingScreen"
import TimeframeStatsScreen from "screens/MyGps/TimeframeStatsScreen/TimeframeStatsScreen"
import SelectTimeframeScreen from "screens/MyGps/SelectTimeframeScreen/SelectTimeframeScreen"
import MyGpsScreen from "screens/MyGps/MyGpsScreen/MyGpsScreen"
import TransactionDetailsScreen from "screens/MyGps/TransactionDetailsScreen/TransactionDetailsScreen"
import AddBankAccountScreen from "screens/MyGps/AddBankAccountScreen/AddBankAccountScreen"
import FinerioGpsAddAcountListener from "utils/hubs/FinerioGpsAddAcountListener"
import UelInstallButton from "components/Shared/UelInstallButton/UelInstallButton"
import ModalListener from "utils/hubs/ModalListener"
import AddBankAccountSyncScreen from "screens/MyGps/AddBankAccountSyncScreen/AddBankAccountSyncScreen"
import ProfileScreen from "screens/ProfileScreen/ProfileScreen"
import useInstallDialogApp from "hooks/useInstallDialogApp"
import UrlParamsContext from 'tags/UrlParamsContext';
import CreditBuilderDescriptionScreen from "screens/CreditBuilder/CreditBuilderScreen"


//ALL SCREENS LAZY LOADING
const LogInScreen = lazy(() => import("./screens/LogInScreen/LogInScreen"))
const SignUpScreen = lazy(() => import("./screens/SignUpScreen/SignUpScreen"))
const SuccessScreen = lazy(
  () => import("./screens/SuccessScreen/SuccessScreen")
)
const RecoverPasswordScreen = lazy(
  () =>
    import(
      "./screens/RecoverPasswordScreen/RecoverPasswordScreen/RecoverPasswordScreen"
    )
)
const RecoverPasswordSuccessScreen = lazy(
  () =>
    import(
      "./screens/RecoverPasswordScreen/RecoverPasswordSuccessScreen/RecoverPasswordSuccessScreen"
    )
)
const HomeScreen = lazy(() => import("./screens/HomeScreen/HomeScreen"))

function App() {
 const {openDialogInstall,setOpenDialogInstall} = useInstallDialogApp();
 const [urlParams, setUrlParams] = useState(new URLSearchParams()); 

  return (
    <UrlParamsContext.Provider value={{ urlParams, setUrlParams }}> 
      <Router>
        <UelInstallButton
          open={openDialogInstall}
          onCloseInstall={() => setOpenDialogInstall(false)}
        />
        <FinerioListener />
        <FinerioGpsAddAcountListener />
        <ModalListener />
        <Suspense fallback={<LoadingScreen />}>
          <Routes>
            <Route element={<RegisterLayout />}>
              <Route path={ROUTES.login} element={<LogInScreen />} />
              <Route path={ROUTES.signUp} element={<SignUpScreen />} />
              <Route path={ROUTES.success} element={<SuccessScreen />} />
              <Route
                path={ROUTES.recoverPassword}
                element={<RecoverPasswordScreen />}
              />
              <Route
                path={ROUTES.recoverPasswordSuccess}
                element={<RecoverPasswordSuccessScreen />}
              />
            </Route>
            <Route element={<HomeLayout />}>
              <Route path={ROUTES.home} element={<HomeScreen />} />
              <Route path={ROUTES.mygps} element={<MyGpsScreen />} />
              <Route path={ROUTES.profile} element={<ProfileScreen />} />
              <Route path={ROUTES.ahorro} element={<CreditBuilderDescriptionScreen />} />
              <Route
                path={ROUTES.mygpsSelecttimeframe}
                element={<SelectTimeframeScreen />}
              />
              <Route
                path={ROUTES.mygpsTimeframeStats}
                element={<TimeframeStatsScreen />}
              />
              <Route
                path={ROUTES.mygpsTransactionDetails}
                element={<TransactionDetailsScreen />}
              />
              <Route
                path={ROUTES.newBankAccount}
                element={<AddBankAccountScreen />}
              />
              <Route
                path={ROUTES.syncAfterSkip}
                element={<AddBankAccountSyncScreen />}
              />
            </Route>
            <Route path="*" element={<Navigate to={ROUTES.login} />} />
          </Routes>
        </Suspense>
      </Router>
    </UrlParamsContext.Provider>
  )
}

export default App
