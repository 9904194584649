import { Checkbox } from "@mantine/core";
import { FormattedMessage } from "react-intl";
import './style.scss'

export interface UelCheckBoxProps {
  labelId: string
  defaultChecked?: boolean
  value?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onClick?: ()=>void;
  valueBoth?: boolean;
  quickFix?: boolean
}

const UelCheckBox = (props: UelCheckBoxProps) => {
  const { labelId, value, onChange, onBlur, onClick, valueBoth, quickFix } = props;

  return(
    <div className="uel-checkbox-component">
      <Checkbox 
        onClick={onClick}
        onChange={onChange}
        onBlur={onBlur}
        checked={valueBoth}
        defaultChecked={value}
      />
      <span className="uel-label">
        {quickFix ?
          <div className="quickfix">
            <span>
              Acepto los <a href={process.env.REACT_APP_UEL_TERMS} target="_blank" className="link" rel="noreferrer">Términos y condiciones</a> y la 
              <a href={process.env.REACT_APP_UEL_PRIVACY} target="_blank" rel="noreferrer" className="link"> Política de Privacidad de Uellbee </a>
              S.A.P.I de C.V            
            </span>
          </div>
        :
          <FormattedMessage id={labelId} />
        }
        
      </span>
    </div>
  )
}

export default UelCheckBox;